import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux'
import {
    makeStyles,
    Container,
    Grid,
    Avatar,
    Typography,
    Box,
    Divider
} from '@material-ui/core';
import Page from '../../components/Page';
import ProfileStory from '../../components/ProfileStory'
import Loader from '../../components/Loader'
import { fetchProfileStories } from '../../redux/profile/profileAction'

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        ['@media (max-width:600px)']: {
            paddingBottom: theme.spacing(5),
            paddingTop: theme.spacing(5)
        }
    },
    title: {
        fontSize: '1.5rem',
        fontWeight: 'bold'
    }
}));

//Need to start from scratch
const AboutUserStories = ({ user, fetchProfileStories, profileStories }) => {
    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        if (location.state.userEmail !== "") {
            fetchProfileStories(location.state.userEmail)
        }
    }, [location])

    return (
        <Page
            className={classes.root}
            title="User's Profile | YourStories"
        >
            {profileStories.loading ? (<Loader />) :
                <Container fixed style={{ padding: '0 0 0 0' }}>
                    <Grid container spacing={3}>
                        {/* Profile Name */}
                        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '1%' }}>
                            <Box>
                                <Typography className={classes.title} color='textPrimary'>
                                    {location.state.userName}
                                </Typography>
                            </Box>
                        </Grid>

                        <Divider style={{ width: '100%', marginBottom: '2%' }} />

                        {/* About Section */}
                        <Grid
                            container
                            item
                            xl={3}
                            lg={3}
                            md={2}
                            sm={12}
                            xs={12}
                            style={{ height: 'fit-content' }}
                        >
                            {/* Profile Image */}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} justify="center" style={{ marginBottom: '5%' }}>
                                <Avatar
                                    variant="square"
                                    style={{ width: '100px', height: '100px' }}
                                    alt={location.state.userName}
                                    src={location.state.profilePicRef}
                                />
                            </Grid>

                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '1%' }}>
                                <Typography color='textSecondary' style={{ fontSize: '0.8rem' }}>
                                    ABOUT
                                </Typography>
                            </Grid>

                            {/* Profile's Name */}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '1%' }}>
                                <Typography color='textPrimary' style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                    {location.state.userName}
                                </Typography>
                            </Grid>

                            {/* Profile's Details */}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Typography color='textSecondary' style={{ fontSize: '0.8rem' }}>
                                    {location.state.userDetails}
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* Profile Stories Section */}
                        <Grid
                            container
                            item
                            xl={6}
                            lg={6}
                            md={8}
                            sm={12}
                            xs={12}
                        >
                            {profileStories.profileStoriesState && profileStories.profileStoriesState.map(data => {
                                return (
                                    <ProfileStory storyDetails={data} />
                                )
                            })}
                        </Grid>

                        {/* Empty for Now (Ads) */}
                        <Grid
                            container
                            item
                            xl={3}
                            lg={3}
                            md={2}
                            sm={12}
                            xs={12}
                        >
                        </Grid>
                    </Grid>
                </Container>
            }
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.authState.user,
        profileStories: state.profileStories
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchProfileStories: (userEmail) => dispatch(fetchProfileStories(userEmail))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUserStories);