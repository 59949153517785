import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { ReactComponent as GraphUpArrow } from '../assets/GraphUpArrow.svg'
import { ReactComponent as GraphDownArrow } from '../assets/GraphDownArrow.svg'

const useStyles = makeStyles((theme) => ({
    box: {
        fontFamily: 'Montserrat', fontStyle: 'normal', height: '160px', background: '#FFFFFF', border: '1px solid #E0E0E0',
        boxSizing: 'border-box', borderRadius: '10px', padding: '15px 24px 15px 24px', textAlign: 'left'
    },
    innerBox: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center'
    },
    title: {
        fontWeight: 600, fontSize: '12px', lineHeight: '15px', color: '#78909C'
    },
    percent: {
        fontWeight: 600, fontSize: '12px', lineHeight: '15px', marginRight: '5px'
    },
    listHead: {
        fontWeight: 'normal', fontSize: '12px', lineHeight: '15px'
    },
    listCount: {
        color: '#546E7A', fontWeight: 600, fontSize: '18px', lineHeight: '22px'
    }
}));

const AdminUserSignedUpCard = ({ registeredUsers }) => {
    const classes = useStyles();

    const [todaysCount, setTodaysCount] = useState(0)
    const [todaysCountInPercent, setTodaysCountInPercent] = useState(0)
    const [todaysIncreased, setTodaysIncreased] = useState(false)

    const [thisWeeksCount, setThisWeeksCount] = useState(0)
    const [thisWeeksCountInPercent, setThisWeeksCountInPercent] = useState(0)
    const [thisWeeksIncreased, setThisWeeksIncreased] = useState(false)

    const [thisMonthsCount, setThisMonthsCount] = useState(0)
    const [thisMonthsCountInPercent, setThisMonthsCountInPercent] = useState(0)
    const [thisMonthsIncreased, setThisMonthsIncreased] = useState(false)

    useEffect(() => {
        if (registeredUsers) {
            // For Today
            let todaysUsersCount = registeredUsers.filter(x =>
                x.registeredAt >= moment().startOf('day').valueOf() &&
                x.registeredAt <= moment().endOf('day').valueOf()
            )
            setTodaysCount(todaysUsersCount.length)

            let yesterdaysUsersCount = registeredUsers.filter(x =>
                x.registeredAt >= moment().add(-1, 'days').startOf('day').valueOf() &&
                x.registeredAt <= moment().add(-1, 'days').endOf('day').valueOf()
            )

            let todaysDiff = 100 * Math.abs((todaysUsersCount.length - yesterdaysUsersCount.length) /
                ((todaysUsersCount.length + yesterdaysUsersCount.length) / 2))
            setTodaysCountInPercent(Math.round(todaysDiff * 10) / 10)

            if (todaysUsersCount.length > yesterdaysUsersCount.length) setTodaysIncreased(true)


            // For ThisWeek
            let thisWeekUsersCount = registeredUsers.filter(x =>
                x.registeredAt >= moment().startOf('week').valueOf() &&
                x.registeredAt <= moment().valueOf()
            )
            setThisWeeksCount(thisWeekUsersCount.length)

            let previousWeekUsersCount = registeredUsers.filter(x =>
                x.registeredAt >= moment().subtract(1, 'weeks').startOf('week').valueOf() &&
                x.registeredAt <= moment().subtract(1, 'weeks').endOf('week').valueOf()
            )

            let thisWeekDiff = 100 * Math.abs((thisWeekUsersCount.length - previousWeekUsersCount.length) /
                ((thisWeekUsersCount.length + previousWeekUsersCount.length) / 2))
            setThisWeeksCountInPercent(Math.round(thisWeekDiff * 10) / 10)

            if (thisWeekUsersCount.length > previousWeekUsersCount.length) setThisWeeksIncreased(true)


            // For This Month
            let thisMonthUsersCount = registeredUsers.filter(x =>
                x.registeredAt >= moment().startOf('month').valueOf() &&
                x.registeredAt <= moment().valueOf()
            )
            setThisMonthsCount(thisMonthUsersCount.length)

            let previousMonthUsersCount = registeredUsers.filter(x =>
                x.registeredAt >= moment().subtract(1, 'months').startOf('month').valueOf() &&
                x.registeredAt <= moment().subtract(1, 'months').endOf('month').valueOf()
            )

            let thisMonthDiff = 100 * Math.abs((thisMonthUsersCount.length - previousMonthUsersCount.length) /
                ((thisMonthUsersCount.length + previousMonthUsersCount.length) / 2))
            setThisMonthsCountInPercent(Math.round(thisMonthDiff * 10) / 10)

            if (thisMonthUsersCount.length > previousMonthUsersCount.length) setThisMonthsIncreased(true)
        }
    }, [registeredUsers])

    return (
        <Box className={classes.box}>
            {/* The Headings */}
            <Box className={classes.innerBox} style={{ marginBottom: '18px' }}>
                <Typography className={classes.title} >New Users Signed Up</Typography>
            </Box>

            {/* Today */}
            <Box className={classes.innerBox} style={{ marginBottom: '18px' }}>
                <Box style={{ width: '33.33%' }}>
                    <Typography className={classes.listHead} style={{ color: '#42A5F5' }}>Today</Typography>
                </Box>
                <Box style={{ width: '33.33%' }}>
                    <Typography className={classes.listCount}>{todaysCount}</Typography>
                </Box>
                <Box className={classes.innerBox}>
                    <Typography
                        className={classes.percent}
                        style={{ color: todaysIncreased === true ? '#27AE60' : '#EF5350' }}
                    >
                        {`${todaysCountInPercent}%`}
                    </Typography>
                    {
                        todaysIncreased === true ? <GraphUpArrow /> : <GraphDownArrow />
                    }
                </Box>
            </Box>

            {/* This Week */}
            <Box className={classes.innerBox} style={{ marginBottom: '18px' }}>
                <Box style={{ width: '33.33%' }}>
                    <Typography className={classes.listHead} style={{ color: '#FFA726' }}>This Week</Typography>
                </Box>
                <Box style={{ width: '33.33%' }}>
                    <Typography className={classes.listCount}>{thisWeeksCount}</Typography>
                </Box>
                <Box className={classes.innerBox}>
                    <Typography
                        className={classes.percent}
                        style={{ color: thisWeeksIncreased === true ? '#27AE60' : '#EF5350' }}
                    >
                        {`${thisWeeksCountInPercent}%`}
                    </Typography>
                    {
                        thisWeeksIncreased === true ? <GraphUpArrow /> : <GraphDownArrow />
                    }
                </Box>
            </Box>

            {/* This Month */}
            <Box className={classes.innerBox}>
                <Box style={{ width: '33.33%' }}>
                    <Typography className={classes.listHead} style={{ color: '#66BB6A' }}>This Month</Typography>
                </Box>
                <Box style={{ width: '33.33%' }}>
                    <Typography className={classes.listCount}>{thisMonthsCount}</Typography>
                </Box>
                <Box className={classes.innerBox}>
                    <Typography
                        className={classes.percent}
                        style={{ color: thisMonthsIncreased === true ? '#27AE60' : '#EF5350' }}
                    >
                        {`${thisMonthsCountInPercent}%`}
                    </Typography>
                    {
                        thisMonthsIncreased === true ? <GraphUpArrow /> : <GraphDownArrow />
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default AdminUserSignedUpCard