import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    makeStyles,
    Box,
    Button,
    Popover
} from '@material-ui/core';
import TopBarOption from '../components/TopBarOption'
import Logo from '../components/Logo'
import SearchIcon from '@material-ui/icons/Search';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import NotificationsIcon from '@material-ui/icons/Notifications';
import LoginDialog from '../components/LoginDialog';
import { ReactComponent as DownArrow } from '../assets/DownArrowProfile.svg';
import { adminAuthSignOut } from '../redux/adminAuth/adminAuthAction'

const useStyles = makeStyles(({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 100,
        display: 'flex',
        backgroundColor: 'white',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '10px 10% 10px 10%',
        ['@media (max-width:600px)']: {
            display: 'block'
        }
    },
    option: {
        display: 'flex',
        alignItems: 'center',
        ['@media (max-width:600px)']: {
            margin: '5% 0 1% 0'
        }
    }
}));

const TopBar = ({ user, admin, adminAuthSignOut }) => {
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();

    // For Logout PopOver
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;

    // For Login Dialog
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const [writePublish, setWritePublish] = useState(false)

    useEffect(() => {
        if (location.pathname.includes('write')) {
            setWritePublish(true)
        } else {
            setWritePublish(false)
        }
    }, [user, location])

    return (
        <Box className={classes.root}>
            {/* Logo Section */}
            <RouterLink to="/" style={{ display: 'flex' }}>
                <Logo />
            </RouterLink>

            {/* Actions Section */}
            {
                admin.length || admin.email
                    ?
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <TopBarOption avatar={true} alt={admin.displayName} src={admin.photoURL} />
                        <DownArrow onClick={handleClick} style={{ cursor: 'pointer' }} />
                        <Popover
                            id={id}
                            open={openPopover}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Button onClick={() => { adminAuthSignOut(navigate) }} style={{ textTransform: 'none', color: '#546E7A' }} >Logout</Button>
                        </Popover>
                    </Box>
                    :
                    <Box className={classes.option}>
                        {/* Search Icon */}
                        <RouterLink to="/search">
                            <TopBarOption Icon={SearchIcon} />
                        </RouterLink>

                        {/* Notification Icon
                        <TopBarOption Icon={NotificationsIcon} onClick={handleClickOpen} /> */}

                        {/* Bookmarked Section */}
                        {
                            user.length || user.user ?
                                <RouterLink to="/bookmarked">
                                    <TopBarOption Icon={BookmarkIcon} />
                                </RouterLink>
                                :
                                <TopBarOption Icon={BookmarkIcon} onClick={handleClickOpen} />
                        }

                        {/* Write/Publish Button */}
                        {
                            user.length || user.user ?
                                writePublish === true ?
                                    // <TopBarOption button={true} buttonVariant='contained' buttonValue='Publish' />
                                    null
                                    :
                                    <RouterLink to="/write">
                                        <TopBarOption button={true} buttonVariant='outlined' buttonValue='Write' />
                                    </RouterLink>
                                :
                                <TopBarOption button={true} buttonVariant='outlined' buttonValue='Write' onClick={handleClickOpen} />
                        }

                        {/* Profile Avatar */}
                        {
                            user.length || user.user ?
                                <RouterLink to="/profile">
                                    <TopBarOption avatar={true} alt={user.user.displayName} src={user.user.photoURL} />
                                </RouterLink>
                                :
                                <TopBarOption avatar={true} onClick={handleClickOpen} />
                        }

                    </Box>
            }

            {open ? <LoginDialog open={open} onClose={handleClose} /> : null}
        </Box>
    );
};

TopBar.propTypes = {
    className: PropTypes.string
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.authState,
        admin: state.adminAuth.adminAuthState
    };
};

const mapDispatchToProps = dispatch => {
    return {
        adminAuthSignOut: (navigate) => dispatch(adminAuthSignOut(navigate))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
