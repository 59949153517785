import React from 'react';
import ReactApexChart from "react-apexcharts";

const AdminStoriesLineGraph = ({ graphType, height, label, dataList, xaxisList }) => {

    const series = [{ name: label, data: dataList }]

    const options = {
        chart: {
            height: height,
            type: graphType,
            zoom: { enabled: false },
            toolbar: { show: false }
        },
        dataLabels: { enabled: false },
        stroke: { curve: "smooth", width: 2 },
        grid: { show: false },
        xaxis: { categories: xaxisList },
        yaxis: { tickAmount: 3, show: false }
    }

    return (
        <ReactApexChart
            options={options}
            series={series}
            type={graphType}
            height={height}
        />
    )
}

export default AdminStoriesLineGraph