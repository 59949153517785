import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default function DraftConfirmationDialog({ open, onClose, handleDraft }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullScreen={fullScreen}
            onClose={onClose}
            open={open}
            style={{ textAlign: 'left' }}
        >
            <DialogTitle color='textPrimary'>Save as Draft</DialogTitle>
            <DialogContent>
                <DialogContentText color='textPrimary'>
                    Do you want to save your progress as draft. If so it will be saved on your local storage,
                    If you clear your local storage your will be gone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose} style={{ textTransform: 'none' }}>
                    Cancle
                </Button>
                <Button onClick={() => { handleDraft(); onClose(); }} autoFocus style={{ textTransform: 'none' }}>
                    Save as Draft
                </Button>
            </DialogActions>
        </Dialog>
    );
}