import React from 'react';
import {
    makeStyles,
    Popover
} from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const useStyles = makeStyles((theme) => ({
    popover: { pointerEvents: 'none' },

    paper: { borderRadius: '7px', boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.15)' }
}));

const AdminImagePopover = ({ id, open, onClose, anchorEl, imageUrl }) => {
    const classes = useStyles();

    return (
        <Popover
            id={id}
            className={classes.popover}
            classes={{ paper: classes.paper }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={onClose}
            disableRestoreFocus
        >
            <LazyLoadImage
                height='126px'
                width='225px'
                effect="blur"
                src={imageUrl}
                placeholderSrc={imageUrl}
            />
        </Popover>
    )
}

export default AdminImagePopover;