import {
    SIGNIN_REQUEST,
    SIGNIN_SUCCESS,
    SIGNIN_FAILURE,
    SIGNOUT_REQUEST,
    SIGNOUT_SUCCESS,
    SIGNOUT_FAILURE,
    USER_UPDATE_READLIST_SUCCESS,
    REMOVE_BOOKMARKED_STORIES_USER_SUCCESS,
    ADD_BOOKMARKED_STORIES_USER_SUCCESS,
    ADD_UPVOTE_STORIES_USER_SUCCESS,
    REMOVE_UPVOTE_STORIES_USER_SUCCESS
} from '../actionTypes'

const initialState = {
    loading: false,
    authState: [],
    error: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGNIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SIGNIN_SUCCESS:
            return {
                loading: false,
                authState: action.payload,
                error: null
            }
        case SIGNIN_FAILURE:
            return {
                loading: false,
                authState: [],
                error: action.payload
            }
        case SIGNOUT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SIGNOUT_SUCCESS:
            return {
                loading: false,
                authState: [],
                error: null
            }
        case SIGNOUT_FAILURE:
            return {
                loading: false,
                authState: [],
                error: action.payload
            }
        case USER_UPDATE_READLIST_SUCCESS:
            let storyId = action.payload
            let userState = state.authState
            if (!userState.user.readStories.includes(storyId)) userState.user.readStories.push(storyId)
            return {
                loading: false,
                authState: userState,
                error: null
            }
        case ADD_BOOKMARKED_STORIES_USER_SUCCESS:
            let newBookmarkedUserState = state.authState
            if (!newBookmarkedUserState.user.bookmarkedStories.includes(action.payload)) newBookmarkedUserState.user.bookmarkedStories.push(action.payload)
            return {
                loading: false,
                authState: newBookmarkedUserState,
                error: null
            }
        case REMOVE_BOOKMARKED_STORIES_USER_SUCCESS:
            let bookmarkUserState = state.authState
            let newbookmarkedStories = bookmarkUserState.user.bookmarkedStories.filter((x) => x !== action.payload)
            bookmarkUserState.user.bookmarkedStories = newbookmarkedStories
            return {
                loading: false,
                authState: bookmarkUserState,
                error: null
            }
        case ADD_UPVOTE_STORIES_USER_SUCCESS:
            let newUpVoteUserState = state.authState
            if (!newUpVoteUserState.user.upVoteStories.includes(action.payload)) newUpVoteUserState.user.upVoteStories.push(action.payload)
            return {
                loading: false,
                authState: newUpVoteUserState,
                error: null
            }
        case REMOVE_UPVOTE_STORIES_USER_SUCCESS:
            let upVoteUserState = state.authState
            let newUpVoteStories = upVoteUserState.user.upVoteStories.filter((x) => x !== action.payload)
            upVoteUserState.user.upVoteStories = newUpVoteStories
            return {
                loading: false,
                authState: upVoteUserState,
                error: null
            }
        default: return state
    }
}

export default reducer
