import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import {
    makeStyles,
    Container,
    Grid,
    Typography,
    Box,
    IconButton
} from '@material-ui/core';
import Page from '../../components/Page';
import StoryDetail from '../../components/StoryDetail'
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import { ReactComponent as MessageIcon } from '../../assets/messageIcon.svg'
import LoginDialog from '../../components/LoginDialog';
import { bookMarkStory, removeBookmarkedStory } from '../../utils/bookMark'
import { upVoteStory, downVoteStory } from '../../utils/upVote'
import { updateMoreStoriesDownvoteCount, updateMoreStoriesUpvoteCount } from '../../redux/dashboard/moreStories/moreStoriesAction';
import { updateHandPickedDownvoteCount, updateHandPickedUpvoteCount } from '../../redux/dashboard/handPicked/handPickedAction';
import { updateSearchDownvoteCount, updateSearchUpvoteCount } from '../../redux/search/searchAction';
import { updateBookmarkDownvoteCount, updateBookmarkUpvoteCount } from '../../redux/bookmarked/bookmarkedAction';
import PopOverShareLinks from '../../components/PopOverShareLinks';
import { removeBookMarkedStoryFromRedux } from '../../redux/bookmarked/bookmarkedAction'
import { addUsersUpVoteStory, removeUsersUpVoteStory, addUsersBookmarkedStory, removeUsersBookmarkedStory } from '../../redux/auth/authAction'


const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        ['@media (max-width:600px)']: {
            paddingBottom: theme.spacing(5),
            paddingTop: theme.spacing(5)
        }
    },
    icons: {
        color: 'black'
    },
    grid: {
        ['@media (max-width:600px)']: {
            display: 'none'
        }
    },
    sideActionGrid: {
        display: 'block',
        ['@media (max-width:720px)']: {
            display: 'flex'
        }
    }
}));

const ReadStoryPage = ({ user, handPicked, moreStories, searchStories, bookmarkedStories,
    updateMoreStoriesUpvoteCount,
    updateMoreStoriesDownvoteCount,
    updateHandPickedUpvoteCount,
    updateHandPickedDownvoteCount,
    updateSearchUpvoteCount,
    updateSearchDownvoteCount,
    updateBookmarkUpvoteCount,
    updateBookmarkDownvoteCount,
    removeBookMarkedStoryFromRedux,
    addUsersBookmarkedStory,
    removeUsersBookmarkedStory,
    addUsersUpVoteStory,
    removeUsersUpVoteStory
}) => {
    const classes = useStyles();

    // Login Dialog Open/Close Handling
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // Local state for loading data and using further
    const [storyDetails, setStoryDetails] = useState();
    const [bookmarked, setBookmarked] = useState(false)
    const [upVote, setUpVote] = useState(false)

    // For loading data in to local useState on Page load
    useEffect(() => {
        let reditected = localStorage.getItem('from')
        let storyId = window.location.href.split('/read/')[1]
        if (reditected === 'dashboard') {
            let handPickedStory = handPicked.find(x => x._id === storyId)
            let storyFromMoreStories = moreStories.find(x => x._id === storyId)
            if (handPickedStory !== undefined) setStoryDetails(handPickedStory)
            if (storyFromMoreStories !== undefined) setStoryDetails(storyFromMoreStories)
        } else if (reditected === 'search') {
            let searchStory = searchStories.find(x => x._id === storyId)
            if (searchStory !== undefined) setStoryDetails(searchStory)
        } else {
            let bookmarkedStory = bookmarkedStories.find(x => x._id === storyId)
            if (bookmarkedStory !== undefined) setStoryDetails(bookmarkedStory)
        }
    }, [])

    // Handle UpVote and Bookmarked State on Page load
    useEffect(() => {
        if (user && user) {
            setBookmarked(user.bookmarkedStories.includes(window.location.href.split('/read/')[1]))
            setUpVote(user.upVoteStories.includes(window.location.href.split('/read/')[1]))
        }
    }, [user])

    // Handle Scroll, its forced to top on load
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Update UpVote in local useState
    const updateUpvote = () => {
        setStoryDetails({ ...storyDetails, upvoteCount: storyDetails.upvoteCount + 1 })
    }

    // Update DownVote in local useState
    const updateDownvote = () => {
        setStoryDetails({ ...storyDetails, upvoteCount: storyDetails.upvoteCount - 1 })
    }

    // Handle UpVote Count
    const handleStateUpvoteCount = (storyId) => {
        let reditected = localStorage.getItem('from')
        if (reditected === 'dashboard') {
            updateMoreStoriesUpvoteCount(storyId)
            updateHandPickedUpvoteCount(storyId)
        } else if (reditected === 'search') {
            updateSearchUpvoteCount(storyId)
        } else {
            updateBookmarkUpvoteCount(storyId)
        }
        updateUpvote()
    }

    // Handle DownVote Count
    const handleStateDownvoteCount = (storyId) => {
        let reditected = localStorage.getItem('from')
        if (reditected === 'dashboard') {
            updateMoreStoriesDownvoteCount(storyId)
            updateHandPickedDownvoteCount(storyId)
        } else if (reditected === 'search') {
            updateSearchDownvoteCount(storyId)
        } else {
            updateBookmarkDownvoteCount(storyId)
        }
        updateDownvote()
    }

    // Share Links Open/Close Handling
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleShareLinkClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleShareLinkClose = () => {
        setAnchorEl(null);
    };
    const shareLinkOpen = Boolean(anchorEl)

    return (
        <Page
            className={classes.root}
            title="Read Story | YourStories"
        >
            <Container fixed>
                <Grid container spacing={3}>
                    {/* Left Action Section */}
                    <Grid container item xl={3} lg={3} md={2} sm={1} className={classes.grid} style={{ height: 'fit-content' }}>
                        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.sideActionGrid}>
                            <Box style={{ float: 'right', paddingTop: '45%' }}>

                                <Box style={{ display: 'flex', alignItems: 'center', margin: '0 20% 0 0' }}>
                                    {
                                        user ?
                                            <IconButton onClick={upVote === true
                                                ?
                                                () => {
                                                    downVoteStory(user._id, storyDetails._id)
                                                    handleStateDownvoteCount(storyDetails._id)
                                                    removeUsersUpVoteStory(storyDetails._id)
                                                    setUpVote(false)
                                                }
                                                :
                                                () => {
                                                    upVoteStory(user._id, storyDetails._id)
                                                    handleStateUpvoteCount(storyDetails._id)
                                                    addUsersUpVoteStory(storyDetails._id)
                                                    setUpVote(true)
                                                }
                                            }>
                                                {
                                                    upVote === true
                                                        ?
                                                        <StarIcon className={classes.icons} />
                                                        :
                                                        <StarOutlineIcon className={classes.icons} />
                                                }
                                            </IconButton>
                                            :
                                            <IconButton onClick={handleClickOpen}>
                                                <StarOutlineIcon className={classes.icons} />
                                            </IconButton>
                                    }
                                    <Typography color='textPrimary' style={{ fontSize: '0.7rem', fontWeight: 'bold' }}>
                                        {`${storyDetails && storyDetails.upvoteCount !== undefined ? storyDetails.upvoteCount : 0}`}
                                    </Typography>
                                </Box>

                                {/* <Box style={{ display: 'flex', alignItems: 'center', margin: '0 20% 0 0' }}>
                                        <IconButton>
                                            <MessageIcon className={classes.icons} />
                                        </IconButton>
                                        <Typography color='textPrimary' style={{ fontSize: '0.7rem', fontWeight: 'bold' }}>
                                            {0}
                                        </Typography>
                                    </Box> */}

                                <Box style={{ margin: '0 20% 0 0' }}>
                                    {
                                        user ?
                                            <IconButton onClick={bookmarked === true
                                                ?
                                                () => {
                                                    removeBookmarkedStory(user._id, storyDetails._id)
                                                    if (localStorage.getItem('from') === 'bookmarked') removeBookMarkedStoryFromRedux(storyDetails._id)
                                                    removeUsersBookmarkedStory(storyDetails._id)
                                                    setBookmarked(false)
                                                }
                                                :
                                                () => {
                                                    bookMarkStory(user._id, storyDetails._id)
                                                    addUsersBookmarkedStory(storyDetails._id)
                                                    setBookmarked(true)
                                                }
                                            }>
                                                {
                                                    bookmarked === true
                                                        ?
                                                        <BookmarkIcon className={classes.icons} />
                                                        :
                                                        <BookmarkBorderIcon className={classes.icons} />
                                                }
                                            </IconButton>
                                            :
                                            <IconButton onClick={handleClickOpen}>
                                                <BookmarkBorderIcon className={classes.icons} />
                                            </IconButton>
                                    }
                                </Box>

                                <Box style={{ margin: '0 20% 0 0' }}>
                                    <IconButton onClick={handleShareLinkClick}>
                                        <ShareOutlinedIcon className={classes.icons} />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Story Section */}
                    <Grid container item xl={6} lg={6} md={8} sm={10} xs={12}>
                        <StoryDetail
                            storyDetails={storyDetails}
                            bookmarked={bookmarked}
                            setBookMarked={setBookmarked}
                            upVote={upVote}
                            setUpVote={setUpVote}
                            updateStateUpvote={updateUpvote}
                            updateStateDownvote={updateDownvote}
                        />
                    </Grid>

                    {/* Empty for now (Ads) */}
                    <Grid className={classes.grid} container item xl={3} lg={3} md={2} sm={1}></Grid>
                </Grid>
            </Container>
            {open ? <LoginDialog open={open} onClose={handleClose} /> : null}
            {shareLinkOpen ?
                <PopOverShareLinks
                    open={shareLinkOpen}
                    anchorEl={anchorEl}
                    onClose={handleShareLinkClose}
                    storyId={storyDetails._id}
                    storyTitle={storyDetails.storyTitle}
                    storyBody={storyDetails.storyContent}
                />
                : null}
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.authState.user,
        handPicked: state.handPicked.handPickedState,
        moreStories: state.moreStories.moreStoriesState,
        searchStories: state.searchStories.searchStoriesState,
        bookmarkedStories: state.bookmarkedStories.bookmarkedStoriesState
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateMoreStoriesUpvoteCount: (storyId) => dispatch(updateMoreStoriesUpvoteCount(storyId)),
        updateMoreStoriesDownvoteCount: (storyId) => dispatch(updateMoreStoriesDownvoteCount(storyId)),
        updateHandPickedUpvoteCount: (storyId) => dispatch(updateHandPickedUpvoteCount(storyId)),
        updateHandPickedDownvoteCount: (storyId) => dispatch(updateHandPickedDownvoteCount(storyId)),
        updateSearchUpvoteCount: (storyId) => dispatch(updateSearchUpvoteCount(storyId)),
        updateSearchDownvoteCount: (storyId) => dispatch(updateSearchDownvoteCount(storyId)),
        updateBookmarkUpvoteCount: (storyId) => dispatch(updateBookmarkUpvoteCount(storyId)),
        updateBookmarkDownvoteCount: (storyId) => dispatch(updateBookmarkDownvoteCount(storyId)),
        removeBookMarkedStoryFromRedux: (storyId) => dispatch(removeBookMarkedStoryFromRedux(storyId)),
        addUsersBookmarkedStory: (storyId) => dispatch(addUsersBookmarkedStory(storyId)),
        removeUsersBookmarkedStory: (storyId) => dispatch(removeUsersBookmarkedStory(storyId)),
        addUsersUpVoteStory: (storyId) => dispatch(addUsersUpVoteStory(storyId)),
        removeUsersUpVoteStory: (storyId) => dispatch(removeUsersUpVoteStory(storyId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadStoryPage);