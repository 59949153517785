import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { makeStyles, Grid, Box, Typography, Button } from '@material-ui/core';
import Page from '../../components/Page';
import StoriesTable from '../../components/StoriesTable.js'
import { ReactComponent as DoneIcon } from '../../assets/DoneIcon.svg'
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg'
import { approveMultipleStory, approveMultipleStoryFromRejected } from '../../utils/approveStories'
import { rejectMultipleStory, rejectMultipleStoryFromApproved } from '../../utils/rejectStories';
import AdminLiveStoriesCard from '../../components/AdminLiveStoriesCard';
import AdminUserSignedUpCard from '../../components/AdminUserSignedUpCard';
import AdminStoriesCountCard from '../../components/AdminStoriesCountCard';
import AdminPopularTagsCard from '../../components/AdminPopularTagsCard';
import {
    fetchPendingStories, removeStoryFromPending, removeMultipleStoriesFromPending
} from '../../redux/pendingStories/pendingStoriesAction'
import {
    fetchApprovedStories, addApprovedStory, addMultipleApprovedStories, removeStoryFromApproved, removeMultipleStoriesFromApproved
} from '../../redux/approvedStories/approvedStoriesAction'
import {
    fetchRejectedStories, addRejectedStory, addMultipleRejectedStories, removeStoryFromRejected, removeMultipleStoriesFromRejected
} from '../../redux/rejectedStories/rejectedStoriesAction'
import { fetchRegisteredUsers } from '../../redux/registeredUser/registeredUserAction'
import Loader from '../../components/Loader'
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(5), paddingTop: theme.spacing(5), gridTemplateColumns: "max-content max-content",
        gridAutoFlow: "row dense",
        ['@media (max-width:600px)']: {
            paddingBottom: theme.spacing(5),
            paddingTop: theme.spacing(5)
        }
    },
    box: {
        fontFamily: 'Montserrat', fontStyle: 'normal', height: '160px', background: '#FFFFFF', border: '1px solid #E0E0E0',
        boxSizing: 'border-box', borderRadius: '10px', padding: '15px 24px 15px 24px', textAlign: 'left'
    },
    innerBox: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center'
    },
    welcomeText: {
        fontSize: '24px', lineHeight: '29px', fontWeight: 'normal', letterSpacing: '1px', color: '#37474F'
    },
    title: {
        fontWeight: 600, fontSize: '12px', lineHeight: '15px', color: '#78909C'
    },
    tabButtons: {
        fontSize: '12px', lineHeight: '20px', color: '#546E7A', borderRadius: '0px'
    },
    selectedCount: {
        fontWeight: 300, fontSize: '10px', lineHeight: '12px', color: '#546E7A', textTransform: 'none', letterSpacing: '0.25px',
        marginRight: '15px'
    },
    actionButton: {
        fontSize: '12px', lineHeight: '20px', letterSpacing: '0.25px', marginRight: '15px', borderRadius: '4px',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.15)'
    }
}));

const AdminDashboard = ({
    fetchPendingStories, pendingStories, removeStoryFromPending, removeMultipleStoriesFromPending,
    fetchApprovedStories, approvedStories, addApprovedStory, addMultipleApprovedStories, removeStoryFromApproved, removeMultipleStoriesFromApproved,
    fetchRejectedStories, rejectedStories, addRejectedStory, addMultipleRejectedStories, removeStoryFromRejected, removeMultipleStoriesFromRejected,
    fetchRegisteredUsers, registeredUsers
}) => {
    const classes = useStyles();

    const [selectedTab, setSelectedTab] = React.useState('Pending')

    const [stories, setStories] = React.useState()

    const [storiesIds, setStoriesIds] = React.useState([])

    const [multipleActionDone, setMultipleActionDone] = React.useState(false)

    useEffect(() => {
        fetchPendingStories()
        fetchApprovedStories()
        fetchRejectedStories()
        fetchRegisteredUsers()
    }, [])

    useEffect(() => {
        switch (selectedTab) {
            case 'Pending':
                if (pendingStories.pendingStoriesState && pendingStories.pendingStoriesState) {
                    setStories(pendingStories.pendingStoriesState)
                }
                break;
            case 'Approved':
                if (approvedStories.approvedStoriesState && approvedStories.approvedStoriesState) {
                    setStories(approvedStories.approvedStoriesState)
                }
                break;
            case 'Rejected':
                if (rejectedStories.rejectedStoriesState && rejectedStories.rejectedStoriesState) {
                    setStories(rejectedStories.rejectedStoriesState)
                }
                break;
            default:
                if (pendingStories.pendingStoriesState && pendingStories.pendingStoriesState) {
                    setStories(pendingStories.pendingStoriesState)
                }
                break;
        }
    }, [pendingStories.pendingStoriesState, approvedStories.approvedStoriesState, rejectedStories.rejectedStoriesState])

    const handleTabChange = (tab) => {
        switch (tab) {
            case 'Pending':
                setSelectedTab('Pending')
                setStories(pendingStories.pendingStoriesState)
                break;
            case 'Approved':
                setSelectedTab('Approved')
                setStories(approvedStories.approvedStoriesState)
                break;
            case 'Rejected':
                setSelectedTab('Rejected')
                setStories(rejectedStories.rejectedStoriesState)
                break;
            default:
                break;
        }
    }

    // Move Single Story from One Collection to Other Collection in Redux
    const removeSingleStory = (record, status) => {
        switch (selectedTab) {
            case 'Pending':
                setStories(stories.filter(x => x.id !== record.id))
                if (status === 'Approved') {
                    removeStoryFromPending(record)
                    addApprovedStory(record)
                } else {
                    removeStoryFromPending(record)
                    addRejectedStory(record)
                }
                break;
            case 'Approved':
                setStories(stories.filter(x => x.id !== record.id))
                removeStoryFromApproved(record)
                addRejectedStory(record)
                break;
            case 'Rejected':
                setStories(stories.filter(x => x.id !== record.id))
                removeStoryFromRejected(record)
                addApprovedStory(record)
                break;
            default: break;
        }
    }

    const handleMultipleApprove = () => {
        let approvedStoriesList = []
        switch (selectedTab) {
            case 'Pending':
                approvedStoriesList = []
                approvedStoriesList = stories.filter(({ id }) => storiesIds.includes(id))
                if (approvedStoriesList.filter(x => x.coverPicRef !== "").length > 0) {
                    approveMultipleStory(approvedStoriesList)
                    removeMultipleStoriesFromPending(storiesIds)
                    addMultipleApprovedStories(approvedStoriesList)
                    setStories(stories.filter(({ id }) => !storiesIds.includes(id)))
                    setMultipleActionDone(true)
                } else {
                    toast.warn("There is no Image choosen for the Selected Stories.")
                }
                break;
            case 'Approved': break;
            case 'Rejected':
                approvedStoriesList = []
                approvedStoriesList = stories.filter(({ id }) => storiesIds.includes(id))
                if (approvedStoriesList.filter(x => x.coverPicRef !== "").length > 0) {
                    approveMultipleStoryFromRejected(approvedStoriesList)
                    removeMultipleStoriesFromRejected(storiesIds)
                    addMultipleApprovedStories(approvedStoriesList)
                    setStories(stories.filter(({ id }) => !storiesIds.includes(id)))
                    setMultipleActionDone(true)
                } else {
                    toast.warn("There is no Image choosen for the Selected Stories.")
                }
                break;
            default: break;
        }
    }

    const handleMultipleReject = () => {
        let rejectedStoriesList = []
        switch (selectedTab) {
            case 'Pending':
                rejectedStoriesList = []
                rejectedStoriesList = stories.filter(({ id }) => storiesIds.includes(id))
                rejectMultipleStory(rejectedStoriesList)
                removeMultipleStoriesFromPending(storiesIds)
                addMultipleRejectedStories(rejectedStoriesList)
                setStories(stories.filter(({ id }) => !storiesIds.includes(id)))
                setMultipleActionDone(true)
                break;
            case 'Approved':
                rejectedStoriesList = []
                rejectedStoriesList = stories.filter(({ id }) => storiesIds.includes(id))
                rejectMultipleStoryFromApproved(rejectedStoriesList)
                removeMultipleStoriesFromApproved(storiesIds)
                addMultipleRejectedStories(rejectedStoriesList)
                setStories(stories.filter(({ id }) => !storiesIds.includes(id)))
                setMultipleActionDone(true)
                break;
            case 'Rejected': break;
            default: break;
        }
    }

    return (
        <Page
            className={classes.root}
            title="YourStories - Where Stories Find You!"
        >
            <Grid container spacing={4}>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography className={classes.welcomeText}>{`Welcome Admin`}</Typography>
                </Grid>

                {/* Card Section */}
                <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} spacing={2}>
                    {/* Total Live Stories */}
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <AdminLiveStoriesCard approvedStories={approvedStories.approvedStoriesState} />
                    </Grid>

                    {/* New User Signed up */}
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <AdminUserSignedUpCard registeredUsers={registeredUsers.registeredUsersState} />
                    </Grid>

                    {/* Stories count */}
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <AdminStoriesCountCard
                            approvedStories={approvedStories.approvedStoriesState}
                            pendingStories={pendingStories.pendingStoriesState}
                            rejectedStories={rejectedStories.rejectedStoriesState}
                        />
                    </Grid>

                    {/* Popular Tags */}
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <AdminPopularTagsCard approvedStories={approvedStories.approvedStoriesState} />
                    </Grid>
                </Grid>

                {/* Action Button Section */}
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} spacing={2} className={classes.innerBox}>
                    {/* Collection Tabs Buttons */}
                    <Box>
                        <Button
                            className={classes.tabButtons}
                            style={{ borderBottom: selectedTab === 'Pending' ? '2px solid #00B0FF' : null }}
                            onClick={() => handleTabChange('Pending')}
                        >
                            Pending
                        </Button>
                        <Button
                            className={classes.tabButtons}
                            style={{ borderBottom: selectedTab === 'Approved' ? '2px solid #00B0FF' : null }}
                            onClick={() => handleTabChange('Approved')}
                        >
                            Approved
                        </Button>
                        <Button
                            className={classes.tabButtons}
                            style={{ borderBottom: selectedTab === 'Rejected' ? '2px solid #00B0FF' : null }}
                            onClick={() => handleTabChange('Rejected')}
                        >
                            Rejected
                        </Button>
                    </Box>

                    {/* Actions */}
                    {
                        storiesIds.length ?
                            <Box className={classes.innerBox}>
                                <Typography className={classes.selectedCount}>{`${storiesIds.length} selected`}</Typography>
                                {selectedTab === 'Pending' || selectedTab === 'Rejected' ?
                                    <Button startIcon={<DoneIcon />} className={classes.actionButton}
                                        style={{ color: '#4CAF50' }} onClick={handleMultipleApprove}
                                    >
                                        APPROVE
                                    </Button>
                                    : null
                                }
                                {selectedTab === 'Pending' || selectedTab === 'Approved' ?
                                    <Button startIcon={<CloseIcon />} className={classes.actionButton}
                                        style={{ color: '#E53935' }} onClick={handleMultipleReject}
                                    >
                                        REJECT
                                    </Button>
                                    : null
                                }
                            </Box>
                            :
                            <Box></Box>
                    }
                </Grid>

                {/* StoryTable Section */}
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} spacing={2}>
                    <StoriesTable
                        stories={stories}
                        removeSingleStory={removeSingleStory}
                        selectedTab={selectedTab}
                        setStoriesIds={setStoriesIds}
                        multipleActionDone={multipleActionDone}
                        setMultipleActionDone={setMultipleActionDone}
                    />
                </Grid>
            </Grid>
            {
                pendingStories.loading ||
                    approvedStories.loading ||
                    rejectedStories.loading ||
                    registeredUsers.loading ? (<Loader />) : null
            }
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        pendingStories: state.pendingStories,
        approvedStories: state.approvedStories,
        rejectedStories: state.rejectedStories,
        registeredUsers: state.registeredUsers
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchPendingStories: () => dispatch(fetchPendingStories()),
        fetchApprovedStories: () => dispatch(fetchApprovedStories()),
        fetchRejectedStories: () => dispatch(fetchRejectedStories()),
        fetchRegisteredUsers: () => dispatch(fetchRegisteredUsers()),
        removeStoryFromPending: (data) => dispatch(removeStoryFromPending(data)),
        addApprovedStory: (data) => dispatch(addApprovedStory(data)),
        addRejectedStory: (data) => dispatch(addRejectedStory(data)),
        removeMultipleStoriesFromPending: (ids) => dispatch(removeMultipleStoriesFromPending(ids)),
        addMultipleApprovedStories: (approvedStories) => dispatch(addMultipleApprovedStories(approvedStories)),
        addMultipleRejectedStories: (rejectedStories) => dispatch(addMultipleRejectedStories(rejectedStories)),
        removeStoryFromApproved: (data) => dispatch(removeStoryFromApproved(data)),
        removeStoryFromRejected: (data) => dispatch(removeStoryFromRejected(data)),
        removeMultipleStoriesFromRejected: (ids) => dispatch(removeMultipleStoriesFromRejected(ids)),
        removeMultipleStoriesFromApproved: (ids) => dispatch(removeMultipleStoriesFromApproved(ids))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard)