import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {
    makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel,
    Paper, Checkbox, Box, Typography, IconButton, Button
} from '@material-ui/core';
import moment from 'moment'
import AdminReadMoreOrLess from './AdminReadMoreOrLess'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ShimmerEffect from '../assets/ShimmerEffect.gif'
import ImageIcon from '@material-ui/icons/Image';
import { ReactComponent as DoneIcon } from '../assets/DoneIcon.svg'
import { ReactComponent as EditIcon } from '../assets/EditIcon.svg'
import { ReactComponent as CloseIcon } from '../assets/CloseIcon.svg'
import { approveSingleStory, approveSingleStoryFromRejected } from '../utils/approveStories';
import { rejectSingleStory, rejectSingleStoryFromApproved } from '../utils/rejectStories';
import EditStoryDialog from './EditStoryDialog';
import AdminImagePopover from './AdminImagePopover'
import ImageGalleryDialog from './ImageGalleryDialog';
import { updateImageForPendingStory } from '../redux/pendingStories/pendingStoriesAction'
import { toast } from 'react-toastify';
import { updateImageForApprovedStory } from '../redux/approvedStories/approvedStoriesAction';
import { updateImageForRejectedStory } from '../redux/rejectedStories/rejectedStoriesAction';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'submissionDate', label: 'Posted on' },
    { id: 'StoryThumbnail', label: 'Story Thumbnail' },
    { id: 'Story', label: 'Story' },
    { id: 'Postedby', label: 'Posted by' },
    { id: 'Action', label: 'Action' },

];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" colSpan={1}>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.id !== 'Story' && headCell.id !== 'Postedby' ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            fontSize: '12px', lineHeight: '18px', color: '#263238',
                            width: headCell.id === 'Story' ? '60%' : null
                        }}
                        colSpan={headCell.id === 'Story' ? 6 : 1}
                    >
                        {
                            headCell.id === 'submissionDate' ?
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'desc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                                :
                                headCell.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 600,
    },
    postedDetail: {
        fontWeight: 300, fontSize: '12px', lineHeight: '150%', color: '#000000'
    }
}));

const StoriesTable = ({
    stories, removeSingleStory, selectedTab, updateImageForPendingStory, setStoriesIds, multipleActionDone, setMultipleActionDone,
    updateImageForApprovedStory, updateImageForRejectedStory
}) => {
    const classes = useStyles();
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('submissionDate');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // State to pass the story which needs to edit
    const [storyToEdit, setStoryToEdit] = React.useState()

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = stories.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // To pass the selected stories Ids to Parent
    useEffect(() => {
        setStoriesIds(selected)
    }, [selected])

    // To Empty the state when multiple action is performed 
    useEffect(() => {
        if (multipleActionDone === true) {
            setSelected([])
            setMultipleActionDone(false)
        }
    }, [multipleActionDone])

    // To Empty the SelectedIds with Tabs are changed
    useEffect(() => {
        setSelected([])
    }, [selectedTab])

    // Image Gallery Dialog Open and close
    const [imageGalleryOpen, setImageGalleryOpen] = React.useState(false)

    const handleImageChange = (file) => {
        var obj = {}
        switch (selectedTab) {
            case 'Pending':
                obj = {}
                obj = {
                    storyId: storyToEdit.id,
                    coverPicRef: file.urls.regular,
                    coverPicOwnerName: file.user.name,
                    coverPicOwnerLink: file.user.links.html
                }
                updateImageForPendingStory(obj)
                break;
            case 'Approved':
                obj = {}
                obj = {
                    storyId: storyToEdit.id,
                    coverPicRef: file.urls.regular,
                    coverPicOwnerName: file.user.name,
                    coverPicOwnerLink: file.user.links.html
                }
                updateImageForApprovedStory(obj)
                break;
            case 'Rejected':
                obj = {}
                obj = {
                    storyId: storyToEdit.id,
                    coverPicRef: file.urls.regular,
                    coverPicOwnerName: file.user.name,
                    coverPicOwnerLink: file.user.links.html
                }
                updateImageForRejectedStory(obj)
                break;
            default: break;
        }
    }

    // Edit Dialog Open and close
    const [editOpen, setEditOpen] = React.useState(false)
    const handleClose = () => { setEditOpen(false); setStoryToEdit(); setImageGalleryOpen(false); }

    // Handle Multiple PopOvers
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openedPopoverId, setOpenedPopoverId] = React.useState(null);
    const handlePopoverOpen = (event, id) => {
        setAnchorEl(event.currentTarget);
        setOpenedPopoverId(id)
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
        setOpenedPopoverId(null)
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                <TableContainer>
                    <Table className={classes.table} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">

                        {/* Table Head */}
                        <EnhancedTableHead classes={classes} numSelected={selected.length} order={order} orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={stories && stories.length}
                        />

                        {/* Table Body */}
                        {
                            stories && <TableBody>
                                {stableSort(stories, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}
                                                aria-checked={isItemSelected}
                                                selected={isItemSelected}
                                            >
                                                {/* Checkbox Section */}
                                                <TableCell padding="checkbox" colSpan={1}>
                                                    <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }}
                                                        onClick={(event) => handleClick(event, row.id)}
                                                    />
                                                </TableCell>

                                                {/* Posted On Secction */}
                                                <TableCell align="center" colSpan={1} className={classes.postedDetail}>
                                                    {moment(row.submissionDate).format('DD/MM/YYYY')}
                                                </TableCell>

                                                {/* Image Section */}
                                                <TableCell align="center" colSpan={1}>
                                                    <Box style={{ width: '75px', height: '75px' }}>
                                                        {
                                                            row.coverPicRef !== "" ?
                                                                // Image View, Edit and PopOver
                                                                <Box>
                                                                    <LazyLoadImage
                                                                        height='100%'
                                                                        width='100%'
                                                                        src={row.coverPicRef}
                                                                        placeholderSrc={ShimmerEffect}
                                                                        style={{ objectFit: 'cover' }}
                                                                        aria-owns={row.id}
                                                                        aria-haspopup="true"
                                                                        onMouseEnter={(event) => handlePopoverOpen(event, row.id)}
                                                                        onMouseLeave={handlePopoverClose}
                                                                    />
                                                                    <IconButton onClick={() => { setImageGalleryOpen(true); setStoryToEdit(row) }}>
                                                                        <EditIcon style={{ width: '13px', height: '12px' }} />
                                                                    </IconButton>
                                                                    <AdminImagePopover
                                                                        id={row.id}
                                                                        open={openedPopoverId === row.id}
                                                                        anchorEl={anchorEl}
                                                                        onClose={handlePopoverClose}
                                                                        imageUrl={row.coverPicRef}
                                                                    />
                                                                </Box>
                                                                :
                                                                // Image Selection
                                                                <Box>
                                                                    <IconButton onClick={() => {
                                                                        setImageGalleryOpen(true);
                                                                        setStoryToEdit(row)
                                                                    }}>
                                                                        <ImageIcon />
                                                                    </IconButton>
                                                                    <Typography style={{ fontSize: '12px' }}>Add Image</Typography>
                                                                </Box>
                                                        }
                                                    </Box>
                                                </TableCell>

                                                {/* Story Section */}
                                                <TableCell align="left" colSpan={6} >
                                                    <Box>
                                                        <Typography style={{
                                                            fontWeight: 600, fontSize: '14px', lineHeight: '150%', marginBottom: '10px'
                                                        }} >
                                                            {row.storyTitle}
                                                        </Typography>
                                                        <AdminReadMoreOrLess>
                                                            {row.storyContent}
                                                        </AdminReadMoreOrLess>
                                                    </Box>
                                                </TableCell>

                                                {/* Posted By Section */}
                                                <TableCell align="left" colSpan={1} className={classes.postedDetail}>
                                                    {row.userName}
                                                </TableCell>

                                                {/* Actions Section */}
                                                <TableCell align="center" colSpan={1}>
                                                    <Box style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                        {selectedTab === 'Pending' || selectedTab === 'Rejected' ?
                                                            <IconButton onClick={() => {
                                                                if (row.coverPicRef !== "") {
                                                                    if (selectedTab === 'Pending') {
                                                                        approveSingleStory(row)
                                                                        removeSingleStory(row, 'Approved')
                                                                    } else {
                                                                        approveSingleStoryFromRejected(row)
                                                                        removeSingleStory(row, 'Approved')
                                                                    }
                                                                } else {
                                                                    toast.warn('Please choose a Image for the Story.')
                                                                }
                                                            }}>
                                                                <DoneIcon />
                                                            </IconButton>
                                                            : null
                                                        }
                                                        {selectedTab === 'Pending' || selectedTab === 'Approved' ?
                                                            <IconButton onClick={() => {
                                                                if (selectedTab === 'Pending') {
                                                                    rejectSingleStory(row)
                                                                    removeSingleStory(row, 'Rejected')
                                                                } else {
                                                                    rejectSingleStoryFromApproved(row)
                                                                    removeSingleStory(row, 'Rejected')
                                                                }
                                                            }}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                            : null
                                                        }
                                                        {selectedTab === 'Pending' ?
                                                            <IconButton onClick={() => { setEditOpen(true); setStoryToEdit(row) }}>
                                                                <EditIcon />
                                                            </IconButton>
                                                            : null
                                                        }
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        }
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={stories && stories.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>

            {/* Edit Story Dialog */}
            {editOpen ?
                <EditStoryDialog
                    open={editOpen}
                    onClose={handleClose}
                    storyToEdit={storyToEdit}
                    removeSingleStory={removeSingleStory}
                />
                : null}

            {/* Image Gallery Dialog */}
            {imageGalleryOpen ?
                <ImageGalleryDialog
                    open={imageGalleryOpen}
                    onClose={handleClose}
                    setFile={handleImageChange}
                />
                : null}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateImageForPendingStory: (data) => dispatch(updateImageForPendingStory(data)),
        updateImageForApprovedStory: (data) => dispatch(updateImageForApprovedStory(data)),
        updateImageForRejectedStory: (data) => dispatch(updateImageForRejectedStory(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoriesTable)