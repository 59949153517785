import axios from 'axios'
import { BASE_URL } from '../../constants/constant'
import {
    FETCH_BOOKMARKED_REQUEST,
    FETCH_BOOKMARKED_SUCCESS,
    FETCH_BOOKMARKED_FAILURE,
    REMOVE_BOOKMARKED_STORY,
    UPDATE_BOOKMARKED_UPVOTE_COUNT,
    UPDATE_BOOKMARKED_DOWNVOTE_COUNT
} from '../actionTypes'

export const fetchBookMarkedStories = (data) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    };
    return (dispatch) => {
        dispatch({ type: FETCH_BOOKMARKED_REQUEST })
        axios.post(`${BASE_URL}bookmarkedStories/get`, data, config)
            .then(response => {
                dispatch({ type: FETCH_BOOKMARKED_SUCCESS, payload: response.data.Stories })
            })
            .catch(error => {
                dispatch({ type: FETCH_BOOKMARKED_FAILURE, payload: error })
            })
    }
}

export const removeBookMarkedStoryFromRedux = (storyId) => {
    return {
        type: REMOVE_BOOKMARKED_STORY,
        payload: storyId
    }
}

export const updateBookmarkUpvoteCount = (storyId) => {
    return { type: UPDATE_BOOKMARKED_UPVOTE_COUNT, payload: storyId }
}

export const updateBookmarkDownvoteCount = (storyId) => {
    return { type: UPDATE_BOOKMARKED_DOWNVOTE_COUNT, payload: storyId }
}