import React from 'react';
import { connect } from 'react-redux'
import {
    Box,
    makeStyles,
    Grid,
    Avatar,
    Typography
} from '@material-ui/core';
import TestImage from '../assets/TestImage.svg'
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment'
import readingTime from '../utils/readingTime'
import { removeBookmarkedStory } from '../utils/bookMark'
import { removeBookMarkedStoryFromRedux } from '../redux/bookmarked/bookmarkedAction'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ShimmerEffect from '../assets/ShimmerEffect.gif'
import { removeUsersBookmarkedStory } from '../redux/auth/authAction'

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        width: '100%',
        cursor: 'pointer'
    },
    box: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    innerBox: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        objectFit: 'contain',
        height: '25px !important',
        width: '25px !important',
        marginRight: '6px'
    },
    userName: {
        fontWeight: 700,
        fontSize: '0.7rem'
    },
    title: {
        height: '34px',
        fontSize: '0.85rem',
        fontWeight: 700,
        marginBottom: '10px',
        textAlign: 'left'
    },
    detail: {
        fontSize: '0.7rem',
        marginRight: '15px'
    }
}));

const StoryTitle = ({ storyData, user, onClick, removeBookMarkedStoryFromRedux, removeUsersBookmarkedStory }) => {
    const classes = useStyles();
    const currentUrl = window.location.href;

    return (
        <Box className={classes.root} onClick={onClick}>
            <Grid
                container
            >
                {/* Story Background Image */}
                <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xl={12}
                    xs={12}
                    style={{ marginBottom: '10px' }}>
                    <LazyLoadImage
                        height='150px'
                        width='100%'
                        // effect="blur"
                        // placeholder={<Skeleton animation="wave" variant="rect" width='100%' height='150px' />}
                        src={storyData.coverPicRef !== "" ? storyData.coverPicRef : TestImage}
                        // placeholderSrc={storyData.coverPicRef}
                        placeholderSrc={ShimmerEffect}
                        style={{ objectFit: 'cover' }}
                    />
                </Grid>

                {/* Story Details */}
                <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xl={12}
                    xs={12}
                >
                    <Box>
                        {/* Profile */}
                        <Box className={classes.innerBox} style={{ marginBottom: '9px' }}>
                            <Avatar className={classes.avatar} alt={storyData.userName} src={storyData.profilePicRef} />
                            <Typography className={classes.userName} color='textPrimary'>
                                {storyData.userName}
                            </Typography>
                        </Box>

                        {/* Title */}
                        <Typography className={classes.title} color='textPrimary'>
                            {storyData.storyTitle}
                        </Typography>

                        {/* Other Details */}
                        <Box className={classes.box}>
                            <Box className={classes.innerBox}>
                                <Typography className={classes.detail} color='textSecondary'>
                                    {moment(storyData.publishDate).format('MMM DD')}
                                </Typography>
                                <Typography className={classes.detail} color='textSecondary'>
                                    {readingTime(storyData.storyContent)}
                                </Typography>
                            </Box>
                            {currentUrl.toString().includes('bookmarked') ?
                                <Box className={classes.innerBox}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        removeBookmarkedStory(user._id, storyData._id)
                                        removeBookMarkedStoryFromRedux(storyData._id)
                                        removeUsersBookmarkedStory(storyData._id)
                                    }}
                                >
                                    <CloseIcon style={{ marginRight: '5px', color: '#EB5757' }} />
                                    <Typography className={classes.detail} style={{ color: '#FF647C' }}>
                                        Remove
                                    </Typography>
                                </Box>
                                :
                                null
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        removeBookMarkedStoryFromRedux: (storyId) => dispatch(removeBookMarkedStoryFromRedux(storyId)),
        removeUsersBookmarkedStory: (storyId) => dispatch(removeUsersBookmarkedStory(storyId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoryTitle);