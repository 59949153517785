import axios from 'axios'
import firebase from '../../config/firebaseConfig'
import { BASE_URL } from '../../constants/constant'
import {
    SIGNIN_REQUEST,
    SIGNIN_SUCCESS,
    SIGNIN_FAILURE,
    SIGNOUT_REQUEST,
    SIGNOUT_SUCCESS,
    SIGNOUT_FAILURE,
    USER_UPDATE_READLIST_SUCCESS,
    REMOVE_BOOKMARKED_STORIES_USER_SUCCESS,
    ADD_BOOKMARKED_STORIES_USER_SUCCESS,
    ADD_UPVOTE_STORIES_USER_SUCCESS,
    REMOVE_UPVOTE_STORIES_USER_SUCCESS
} from '../actionTypes'

export const signIn = (provider) => {
    return (dispatch) => {
        dispatch({ type: SIGNIN_REQUEST })
        firebase.auth()
            .signInWithPopup(provider)
            .then((result) => {
                /* @type {firebase.auth.OAuthCredential} */
                var credential = result.credential;
                var resObj = {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    token: credential.accessToken,
                    // The signed-in user info.
                    user: result.user
                }
                let dataObj = {
                    displayName: resObj.user.providerData[0].displayName,
                    email: resObj.user.providerData[0].email,
                    phoneNumber: resObj.user.providerData[0].phoneNumber,
                    photoURL: resObj.user.providerData[0].photoURL,
                    providerId: resObj.user.providerData[0].providerId + '-web'
                }
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                axios.post(`${BASE_URL}user/signIn`, dataObj, config)
                    .then(response => {
                        localStorage.setItem('token', response.data.accessToken);
                        dispatch({ type: SIGNIN_SUCCESS, payload: response.data })
                    })
                    .catch(error => {
                        dispatch({ type: SIGNIN_FAILURE, payload: error })
                    })
            })
            .catch((error) => {
                var errorObj = {
                    // Handle Errors here.
                    errorCode: error.code,
                    // The email of the user's account used.
                    errorMessage: error.message,
                    email: error.email,
                    // The firebase.auth.AuthCredential type that was used.
                    credential: error.credential
                }
                dispatch({ type: SIGNIN_FAILURE, payload: errorObj })
            })
    }
}


export const authSignOut = () => {
    return (dispatch) => {
        dispatch({ type: SIGNOUT_REQUEST })
        firebase.auth().signOut().then((result) => {
            localStorage.removeItem('token')
            dispatch({ type: SIGNOUT_SUCCESS })
        }).catch((error) => {
            dispatch({ type: SIGNOUT_FAILURE, payload: error })
        })
    }
}

export const updateUserReadList = (storyId) => {
    return { type: USER_UPDATE_READLIST_SUCCESS, payload: storyId }
}

export const removeUsersBookmarkedStory = (storyId) => {
    return { type: REMOVE_BOOKMARKED_STORIES_USER_SUCCESS, payload: storyId }
}

export const addUsersBookmarkedStory = (storyId) => {
    return { type: ADD_BOOKMARKED_STORIES_USER_SUCCESS, payload: storyId }
}

export const addUsersUpVoteStory = (storyId) => {
    return { type: ADD_UPVOTE_STORIES_USER_SUCCESS, payload: storyId }
}

export const removeUsersUpVoteStory = (storyId) => {
    return { type: REMOVE_UPVOTE_STORIES_USER_SUCCESS, payload: storyId }
}