// User SignIn's Action Types
export const SIGNIN_REQUEST = 'SIGNIN_REQUEST'
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS'
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE'
export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNOUT_FAILURE = 'SIGNOUT_FAILURE'
export const USER_UPDATE_READLIST_SUCCESS = 'USER_UPDATE_READLIST_SUCCESS'
export const ADD_BOOKMARKED_STORIES_USER_SUCCESS = 'ADD_BOOKMARKED_STORIES_USER_SUCCESS'
export const REMOVE_BOOKMARKED_STORIES_USER_SUCCESS = 'REMOVE_BOOKMARKED_STORIES_USER_SUCCESS'
export const ADD_UPVOTE_STORIES_USER_SUCCESS = 'ADD_UPVOTE_STORIES_USER_SUCCESS'
export const REMOVE_UPVOTE_STORIES_USER_SUCCESS = 'REMOVE_UPVOTE_STORIES_USER_SUCCESS'

// Admin SignIn's Action Types
export const ADMIN_SIGNIN_REQUEST = 'ADMIN_SIGNIN_REQUEST'
export const ADMIN_SIGNIN_SUCCESS = 'ADMIN_SIGNIN_SUCCESS'
export const ADMIN_SIGNIN_FAILURE = 'ADMIN_SIGNIN_FAILURE'
export const ADMIN_SIGNOUT_REQUEST = 'ADMIN_SIGNOUT_REQUEST'
export const ADMIN_SIGNOUT_SUCCESS = 'ADMIN_SIGNOUT_SUCCESS'
export const ADMIN_SIGNOUT_FAILURE = 'ADMIN_SIGNOUT_FAILURE'

// Hand Picked Stories's Action Types
export const FETCH_HANDPICKED_REQUEST = 'FETCH_HANDPICKED_REQUEST'
export const FETCH_HANDPICKED_SUCCESS = 'FETCH_HANDPICKED_SUCCESS'
export const FETCH_HANDPICKED_FAILURE = 'FETCH_HANDPICKED_FAILURE'
export const UPDATE_HANDPICKED_UPVOTE_COUNT = 'UPDATE_HANDPICKED_UPVOTE_COUNT'
export const UPDATE_HANDPICKED_DOWNVOTE_COUNT = 'UPDATE_HANDPICKED_DOWNVOTE_COUNT'

// Approved Stories's Action Types for User Dashboard
export const FETCH_MORE_STORIES_REQUEST = 'FETCH_MORE_STORIES_REQUEST'
export const FETCH_MORE_STORIES_SUCCESS = 'FETCH_MORE_STORIES_SUCCESS'
export const FETCH_MORE_STORIES_FAILURE = 'FETCH_MORE_STORIES_FAILURE'
export const UPDATE_MORE_STORIES_SUCCESS = 'UPDATE_MORE_STORIES_SUCCESS'
export const UPDATE_MORESTORIES_UPVOTE_COUNT = 'UPDATE_MORESTORIES_UPVOTE_COUNT'
export const UPDATE_MORESTORIES_DOWNVOTE_COUNT = 'UPDATE_MORESTORIES_DOWNVOTE_COUNT'

// Search Stories's Action Types
export const FETCH_SEARCH_REQUEST = 'FETCH_SEARCH_REQUEST'
export const FETCH_SEARCHBYTAG_SUCCESS = 'FETCH_SEARCHBYTAG_SUCCESS'
export const FETCH_SEARCH_FAILURE = 'FETCH_SBYTAG_FAILURE'
export const FETCH_SEARCHBYTAGLIST_SUCCESS = 'FETCH_SEARCHBYTAGLIST_SUCCESS'
export const FETCH_SEARCHBYTITLE_SUCCESS = 'FETCH_SEARCHBYTITLE_SUCCESS'
export const FETCH_SEARCHBYTITLEANDTAGLIST_SUCCESS = 'FETCH_SEARCHBYTITLEANDTAGLIST_SUCCESS'
export const UPDATE_SEARCH_UPVOTE_COUNT = 'UPDATE_SEARCH_UPVOTE_COUNT'
export const UPDATE_SEARCH_DOWNVOTE_COUNT = 'UPDATE_SEARCH_DOWNVOTE_COUNT'

// BookMarked Stories's Action Types
export const FETCH_BOOKMARKED_REQUEST = 'FETCH_BOOKMARKED_REQUEST'
export const FETCH_BOOKMARKED_SUCCESS = 'FETCH_BOOKMARKED_SUCCESS'
export const FETCH_BOOKMARKED_FAILURE = 'FETCH_BOOKMARKED_FAILURE'
export const REMOVE_BOOKMARKED_STORY = 'REMOVE_BOOKMARKED_STORY'
export const UPDATE_BOOKMARKED_UPVOTE_COUNT = 'UPDATE_BOOKMARKED_UPVOTE_COUNT'
export const UPDATE_BOOKMARKED_DOWNVOTE_COUNT = 'UPDATE_BOOKMARKED_DOWNVOTE_COUNT'

// Profile Stories's Action Types
export const FETCH_PROFILE_STORIES_REQUEST = 'FETCH_PROFILE_STORIES_REQUEST'
export const FETCH_PROFILE_STORIES_SUCCESS = 'FETCH_PROFILE_STORIES_SUCCESS'
export const FETCH_PROFILE_STORIES_FAILURE = 'FETCH_PROFILE_STORIES_FAILURE'

// Pending Stories's Action Types
export const FETCH_PENDING_STORIES_REQUEST = 'FETCH_PENDING_STORIES_REQUEST'
export const FETCH_PENDING_STORIES_SUCCESS = 'FETCH_PENDING_STORIES_SUCCESS'
export const FETCH_PENDING_STORIES_FAILURE = 'FETCH_PENDING_STORIES_FAILURE'
export const STORY_REMOVED_FROM_PENDING = 'STORY_REMOVED_FROM_PENDING'
export const UPDATE_IMAGE_FOR_PENDING_STORY = 'UPDATE_IMAGE_FOR_PENDING_STORY'
export const REMOVE_MULTIPLE_STORIES_FROM_PENDING = 'REMOVE_MULTIPLE_STORIES_FROM_PENDING'

// Approved Stories's Action Types
export const FETCH_APPROVED_STORIES_REQUEST = 'FETCH_APPROVED_STORIES_REQUEST'
export const FETCH_APPROVED_STORIES_SUCCESS = 'FETCH_APPROVED_STORIES_SUCCESS'
export const FETCH_APPROVED_STORIES_FAILURE = 'FETCH_APPROVED_STORIES_FAILURE'
export const ADD_APPROVED_STORY = 'ADD_APPROVED_STORY'
export const UPDATE_IMAGE_FOR_APPROVED_STORY = 'UPDATE_IMAGE_FOR_APPROVED_STORY'
export const ADD_MULTIPLE_APPROVED_STORIES = 'ADD_MULTIPLE_APPROVED_STORIES'
export const REMOVE_SINGLE_STORY_FROM_APPROVED = 'REMOVE_SINGLE_STORY_FROM_APPROVED'
export const REMOVE_MULTIPLE_STORY_FROM_APPROVED = 'REMOVE_MULTIPLE_STORY_FROM_APPROVED'

// Rejected Stories's Action Types
export const FETCH_REJECTED_STORIES_REQUEST = 'FETCH_REJECTED_STORIES_REQUEST'
export const FETCH_REJECTED_STORIES_SUCCESS = 'FETCH_REJECTED_STORIES_SUCCESS'
export const FETCH_REJECTED_STORIES_FAILURE = 'FETCH_REJECTED_STORIES_FAILURE'
export const ADD_REJECTED_STORY = 'ADD_REJECTED_STORY'
export const UPDATE_IMAGE_FOR_REJECTED_STORY = 'UPDATE_IMAGE_FOR_REJECTED_STORY'
export const ADD_MULTIPLE_REJECTED_STORIES = 'ADD_MULTIPLE_REJECTED_STORIES'
export const REMOVE_SINGLE_STORY_FROM_REJECTED = 'REMOVE_SINGLE_STORY_FROM_REJECTED'
export const REMOVE_MULTIPLE_STORY_FROM_REJECTED = 'REMOVE_MULTIPLE_STORY_FROM_REJECTED'

// Registered User's Action Types
export const FETCH_REGISTERED_USERS_REQUEST = 'FETCH_REGISTERED_USERS_REQUEST'
export const FETCH_REGISTERED_USERS_SUCCESS = 'FETCH_REGISTERED_USERS_SUCCESS'
export const FETCH_REGISTERED_USERS_FAILURE = 'FETCH_REGISTERED_USERS_FAILURE'