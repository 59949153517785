import React from 'react';
import {
    makeStyles,
    Avatar,
    Button
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: '20px',
        cursor: 'pointer'
    },
    icon: {
        color: '#263238'
    },
    avatar: {
        objectFit: 'contain',
        height: '35px !important',
        width: '35px !important'
    },
    button: {
        textTransform: 'none'
    }
}));

const TopBarOption = ({ avatar, Icon, onClick, button, buttonVariant, buttonValue, alt, src }) => {
    const classes = useStyles();

    return (
        <div className={classes.root} onClick={onClick}>
            {Icon && <Icon className={classes.icon} />}
            {avatar && <Avatar className={classes.avatar} alt={alt} src={src} />}
            {button &&
                <Button
                    className={classes.button}
                    variant={buttonVariant}
                    color='primary'
                    style={{ color: buttonVariant === 'contained' ? 'white' : null }}
                >
                    {buttonValue}
                </Button>
            }
        </div>
    );
};

export default TopBarOption;