import React from 'react'
import Lottie from 'react-lottie';
import * as loading from '../assets/Loader.json'
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

/**
* @author
* @function Loader
**/

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const Loader = (props) => {
    const classes = useStyles();
    return (
        <Backdrop open={true} className={classes.backdrop}>
            <Lottie options={defaultOptions} height={150} width={150} />
        </Backdrop>
    )
}

export default Loader