import {
    FETCH_APPROVED_STORIES_REQUEST,
    FETCH_APPROVED_STORIES_SUCCESS,
    FETCH_APPROVED_STORIES_FAILURE,
    ADD_APPROVED_STORY,
    UPDATE_IMAGE_FOR_APPROVED_STORY,
    ADD_MULTIPLE_APPROVED_STORIES,
    REMOVE_SINGLE_STORY_FROM_APPROVED,
    REMOVE_MULTIPLE_STORY_FROM_APPROVED
} from '../actionTypes'

const initialState = {
    loading: false,
    approvedStoriesState: [],
    error: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_APPROVED_STORIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_APPROVED_STORIES_SUCCESS:
            return {
                loading: false,
                approvedStoriesState: action.payload,
                error: null
            }
        case FETCH_APPROVED_STORIES_FAILURE:
            return {
                loading: false,
                approvedStoriesState: [],
                error: action.payload
            }
        case ADD_APPROVED_STORY:
            let approvedStoriesList = []
            approvedStoriesList.push(action.payload)
            return {
                loading: false,
                approvedStoriesState: [...state.approvedStoriesState, ...approvedStoriesList],
                error: null
            }
        case UPDATE_IMAGE_FOR_APPROVED_STORY:
            let updatedList =
                state.approvedStoriesState.map(x => (x.id === action.payload.storyId) ? {
                    ...x,
                    coverPicRef: action.payload.coverPicRef,
                    coverPicOwnerName: action.payload.coverPicOwnerName,
                    coverPicOwnerLink: action.payload.coverPicOwnerLink
                } : x)
            return {
                ...state,
                approvedStoriesState: updatedList
            }
        case ADD_MULTIPLE_APPROVED_STORIES:
            return {
                loading: false,
                approvedStoriesState: [...state.approvedStoriesState, ...action.payload],
                error: null
            }
        case REMOVE_SINGLE_STORY_FROM_APPROVED:
            let removedApprovedStoryList = state.approvedStoriesState.filter(x => x.id !== action.payload.id)
            return {
                ...state,
                approvedStoriesState: removedApprovedStoryList
            }
        case REMOVE_MULTIPLE_STORY_FROM_APPROVED:
            let newApprovedStoriesList = state.approvedStoriesState.filter(({ id }) => !action.payload.includes(id))
            return {
                ...state,
                approvedStoriesState: newApprovedStoriesList
            }
        default: return state
    }
}

export default reducer