import {
    FETCH_MORE_STORIES_REQUEST,
    FETCH_MORE_STORIES_SUCCESS,
    FETCH_MORE_STORIES_FAILURE,
    UPDATE_MORE_STORIES_SUCCESS,
    UPDATE_MORESTORIES_UPVOTE_COUNT,
    UPDATE_MORESTORIES_DOWNVOTE_COUNT
} from '../../actionTypes'

const initialState = {
    loading: false,
    moreStoriesState: [],
    error: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MORE_STORIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_MORE_STORIES_SUCCESS:
            return {
                loading: false,
                moreStoriesState: action.payload,
                error: null
            }
        case FETCH_MORE_STORIES_FAILURE:
            return {
                loading: false,
                moreStoriesState: [],
                error: action.payload
            }
        case UPDATE_MORE_STORIES_SUCCESS:
            return {
                loading: false,
                moreStoriesState: [...state.moreStoriesState, ...action.payload],
                error: null
            }
        case UPDATE_MORESTORIES_UPVOTE_COUNT:
            return {
                loading: false,
                moreStoriesState: state.moreStoriesState.map(x => x._id === action.payload ? { ...x, upvoteCount: x.upvoteCount + 1 } : x),
                error: null
            }
        case UPDATE_MORESTORIES_DOWNVOTE_COUNT:
            return {
                loading: false,
                moreStoriesState: state.moreStoriesState.map(x => x._id === action.payload ? { ...x, upvoteCount: x.upvoteCount - 1 } : x),
                error: null
            }
        default: return state
    }
}

export default reducer
