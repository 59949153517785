import React from 'react';
import {
    makeStyles,
    Popover,
    Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    popover: { pointerEvents: 'none' },

    paper: { background: '#90A4AE', borderRadius: '7px', padding: '5px 5px 5px 5px', boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.15)' },

    popOverText: { color: 'white', fontWeight: 600, fontSize: '12px', lineHeight: '15px' }
}));

const AdminPopoverDataCount = ({ id, open, onClose, anchorEl, aov, aoh, tov, toh, dataCount }) => {
    const classes = useStyles();

    return (
        <Popover
            id={id}
            className={classes.popover}
            classes={{ paper: classes.paper }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: aov, horizontal: aoh }}
            transformOrigin={{ vertical: tov, horizontal: toh }}
            onClose={onClose}
            disableRestoreFocus
        >
            <Typography className={classes.popOverText}>{dataCount}</Typography>
        </Popover>
    )
}

export default AdminPopoverDataCount;