import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux'
import {
    Box,
    makeStyles,
    Grid,
    Avatar,
    Typography,
    Divider,
    IconButton
} from '@material-ui/core';
import moment from 'moment'
import StyledChip from './StyledChip'
import LoginDialog from './LoginDialog';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';
import { ReactComponent as MessageIcon } from '../assets/messageIcon.svg'
import { ReactComponent as FacebookIcon } from '../assets/FacebookIcon.svg'
import TestImage from '../assets/TestImage2.svg'
import readingTime from '../utils/readingTime'
import { bookMarkStory, removeBookmarkedStory } from '../utils/bookMark'
import { upVoteStory, downVoteStory } from '../utils/upVote'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { updateMoreStoriesUpvoteCount, updateMoreStoriesDownvoteCount } from '../redux/dashboard/moreStories/moreStoriesAction';
import { updateHandPickedDownvoteCount, updateHandPickedUpvoteCount } from '../redux/dashboard/handPicked/handPickedAction';
import { updateSearchDownvoteCount, updateSearchUpvoteCount } from '../redux/search/searchAction';
import { updateBookmarkDownvoteCount, updateBookmarkUpvoteCount } from '../redux/bookmarked/bookmarkedAction';
import ShimmerEffect from '../assets/ShimmerEffect.gif';
import PopOverShareLinks from './PopOverShareLinks';
import { readStory } from '../utils/readStories'
import { updateUserReadList } from '../redux/auth/authAction'
import { removeBookMarkedStoryFromRedux } from '../redux/bookmarked/bookmarkedAction'
import { addUsersUpVoteStory, removeUsersUpVoteStory, addUsersBookmarkedStory, removeUsersBookmarkedStory } from '../redux/auth/authAction'

const useStyles = makeStyles((theme) => ({

    root: {
        alignItems: 'center', width: '100%'
    },
    title: {
        textAlign: 'left', fontSize: '2rem', fontWeight: 'bold'
    },
    box: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center',
        ['@media (max-width:600px)']: {
            display: 'block',
            alignItems: 'center'
        }
    },
    innerBox: {
        display: 'flex', alignItems: 'center',
        ['@media (max-width:600px)']: {
            marginBottom: '10px'
        }
    },
    avatar: {
        objectFit: 'contain', marginRight: '5px'
    },
    profileDetail: {
        fontSize: '0.8rem'
    },
    icon: {
        color: '#1E2833'
    },
    storyBody: {
        textAlign: 'left', fontSize: '1rem'
    },
    actionBox: {
        display: 'flex', alignItems: 'center'
    },
    actionText: {
        fontWeight: 500, fontSize: '0.8rem'
    }
}));

const StoryDetail = ({ user, storyDetails, bookmarked, setBookMarked, upVote, setUpVote, updateStateUpvote, updateStateDownvote,
    updateMoreStoriesUpvoteCount,
    updateMoreStoriesDownvoteCount,
    updateHandPickedUpvoteCount,
    updateHandPickedDownvoteCount,
    updateSearchUpvoteCount,
    updateSearchDownvoteCount,
    updateBookmarkUpvoteCount,
    updateBookmarkDownvoteCount,
    updateUserReadList,
    removeBookMarkedStoryFromRedux,
    addUsersBookmarkedStory,
    removeUsersBookmarkedStory,
    addUsersUpVoteStory,
    removeUsersUpVoteStory
}) => {
    const classes = useStyles();

    // Save StoryIds that are read by User.
    const [read, setRead] = useState(false);
    const [over, setOver] = useState(false);
    const [[h, m, s], setTime] = useState([0, 0, 15]);
    const tick = () => {
        if (over) return;
        if (h === 0 && m === 0 && s === 0) setOver(true);
        else if (m === 0 && s === 0) {
            setTime([h - 1, 59, 59]);
        } else if (s == 0) {
            setTime([h, m - 1, 59]);
        } else {
            setTime([h, m, s - 1]);
        }
    };
    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    });
    useEffect(() => {
        if (read === false && user && storyDetails) {
            if (!user.readStories.includes(storyDetails._id)) {
                readStory(user._id, storyDetails._id)
                updateUserReadList(storyDetails._id)
            }
            setRead(true)
        }
    }, [over]);

    // Login Dialog Open/Close Handling
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // Selected Tags from story
    const [tags, setTags] = useState([])
    useEffect(() => {
        if (storyDetails) {
            setTags(storyDetails.tags.split('####').filter(n => n))
        }
    }, [storyDetails, bookmarked, upVote])

    // Handle Scroll, its forced to top on load
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Handle UpVote Count
    const handleStateUpvoteCount = (storyId) => {
        let reditected = localStorage.getItem('from')
        if (reditected === 'dashboard') {
            updateMoreStoriesUpvoteCount(storyId)
            updateHandPickedUpvoteCount(storyId)
        } else if (reditected === 'search') {
            updateSearchUpvoteCount(storyId)
        } else {
            updateBookmarkUpvoteCount(storyId)
        }
        updateStateUpvote()
    }

    // Handle DownVote Count
    const handleStateDownvoteCount = (storyId) => {
        let reditected = localStorage.getItem('from')
        if (reditected === 'dashboard') {
            updateMoreStoriesDownvoteCount(storyId)
            updateHandPickedDownvoteCount(storyId)
        } else if (reditected === 'search') {
            updateSearchDownvoteCount(storyId)
        } else {
            updateBookmarkDownvoteCount(storyId)
        }
        updateStateDownvote()
    }

    // Share Links Open/Close Handling
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleShareLinkClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleShareLinkClose = () => {
        setAnchorEl(null);
    };
    const shareLinkOpen = Boolean(anchorEl)

    return (
        <Box className={classes.root}>
            {storyDetails ?
                <Grid
                    container
                >
                    {/* Story Title */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '5%' }}>
                        <Typography color='textPrimary' className={classes.title}>
                            {storyDetails.storyTitle}
                        </Typography>
                    </Grid>

                    {/* Profile and Links */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '5%' }}>
                        {/* Profile */}
                        <Box className={classes.box}>
                            {
                                storyDetails.userEmail !== "" ?
                                    <RouterLink to={`/aboutUser`} state={storyDetails}>
                                        <Box className={classes.innerBox}>
                                            <Avatar className={classes.avatar} alt={storyDetails.userName} src={storyDetails.profilePicRef} />
                                            <Box>
                                                <Typography color='textPrimary' className={classes.profileDetail} style={{ fontWeight: 'bold' }}>
                                                    {storyDetails.userName}
                                                </Typography>
                                                <Typography color='textPrimary' className={classes.profileDetail}>
                                                    {`${moment(storyDetails.publishDate).format('MMM DD')} . ${readingTime(storyDetails.storyContent)}`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </RouterLink>
                                    :
                                    <Box className={classes.innerBox}>
                                        <Avatar className={classes.avatar} alt={storyDetails.userName} src={storyDetails.profilePicRef} />
                                        <Box>
                                            <Typography color='textPrimary' className={classes.profileDetail} style={{ fontWeight: 'bold' }}>
                                                {storyDetails.userName}
                                            </Typography>
                                            <Typography color='textPrimary' className={classes.profileDetail}>
                                                {`${moment(storyDetails.publishDate).format('MMM DD')} . ${readingTime(storyDetails.storyContent)}`}
                                            </Typography>
                                        </Box>
                                    </Box>
                            }
                        </Box>
                    </Grid>

                    {/* Story Image */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '5%' }}>
                        <LazyLoadImage
                            height='350px'
                            width='100%'
                            // effect="blur"
                            src={storyDetails.coverPicRef !== "" ? storyDetails.coverPicRef : TestImage}
                            // placeholderSrc={storyDetails.coverPicRef !== "" ? storyDetails.coverPicRef : TestImage}
                            placeholderSrc={ShimmerEffect}
                        />
                    </Grid>

                    {/* Cover Pic Credits */}
                    {
                        storyDetails.coverPicOwnerLink !== "" &&
                            storyDetails.coverPicOwnerLink !== undefined &&
                            storyDetails.coverPicOwnerName !== "" &&
                            storyDetails.coverPicOwnerName !== undefined ?
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '5%' }}>
                                <Typography color='textSecondary' style={{ textAlign: 'center', fontSize: '0.8rem' }}>
                                    Photo by <a href={storyDetails.coverPicOwnerLink} target="_blank" rel="noopener noreferrer">{storyDetails.coverPicOwnerName}</a> on <a href='https://unsplash.com/' target="_blank" rel="noopener noreferrer">Unsplash</a>
                                </Typography>
                            </Grid>
                            :
                            null
                    }


                    {/* Story Body */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '5%' }}>
                        <Typography color='textPrimary' className={classes.storyBody}>
                            {storyDetails.storyContent}
                        </Typography>
                    </Grid>

                    {/* Tags Section */}
                    {tags.map((data, index) => {
                        return (
                            <Grid item direction="row" style={{ margin: '0 10px 5% 0' }}>
                                <StyledChip
                                    key={index}
                                    label={data}
                                    style={{ color: '#828282' }}
                                />
                            </Grid>
                        )
                    })}

                    {/* Story Actions and Links */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '5%' }}>
                        <Box className={classes.box}>
                            {/* Actions */}
                            <Box className={classes.innerBox}>
                                <Box className={classes.actionBox} style={{ marginRight: '10px' }}>
                                    {
                                        user ?
                                            <IconButton onClick={upVote === true
                                                ?
                                                () => {
                                                    downVoteStory(user._id, storyDetails._id);
                                                    handleStateDownvoteCount(storyDetails._id);
                                                    removeUsersUpVoteStory(storyDetails._id);
                                                    setUpVote(false);
                                                }
                                                :
                                                () => {
                                                    upVoteStory(user._id, storyDetails._id);
                                                    handleStateUpvoteCount(storyDetails._id);
                                                    addUsersUpVoteStory(storyDetails._id);
                                                    setUpVote(true);
                                                }
                                            }>
                                                {
                                                    upVote === true
                                                        ?
                                                        <StarIcon className={classes.icon} />
                                                        :
                                                        <StarOutlineIcon className={classes.icon} />
                                                }
                                            </IconButton>
                                            :
                                            <IconButton onClick={handleClickOpen}>
                                                <StarOutlineIcon className={classes.icon} />
                                            </IconButton>
                                    }
                                    <Typography color='textPrimary' className={classes.actionText}>
                                        {`${storyDetails.upvoteCount !== undefined ? storyDetails.upvoteCount : 0} Upvotes`}
                                    </Typography>
                                </Box>
                                {/* <Box className={classes.actionBox}>
                                    <IconButton>
                                        <MessageIcon className={classes.icon} />
                                    </IconButton>
                                    <Typography color='textPrimary' className={classes.actionText}>
                                        {`${0} Responses`}
                                    </Typography>
                                </Box> */}
                            </Box>

                            {/* Links */}
                            <Box className={classes.innerBox}>
                                {storyDetails.facebookLink !== "" ?
                                    <IconButton>
                                        <FacebookIcon className={classes.icon} />
                                    </IconButton>
                                    : null
                                }
                                {storyDetails.twitterLink !== "" ?
                                    <IconButton>
                                        <TwitterIcon className={classes.icon} />
                                    </IconButton>
                                    : null
                                }
                                {storyDetails.instagramLink !== "" ?
                                    <IconButton>
                                        <InstagramIcon className={classes.icon} />
                                    </IconButton>
                                    : null
                                }
                                {
                                    user ?
                                        <IconButton onClick={bookmarked === true
                                            ?
                                            () => {
                                                removeBookmarkedStory(user._id, storyDetails._id)
                                                if (localStorage.getItem('from') === 'bookmarked') removeBookMarkedStoryFromRedux(storyDetails._id)
                                                removeUsersBookmarkedStory(storyDetails._id)
                                                setBookMarked(false)
                                            }
                                            :
                                            () => {
                                                bookMarkStory(user._id, storyDetails._id)
                                                addUsersBookmarkedStory(storyDetails._id)
                                                setBookMarked(true)
                                            }
                                        }>
                                            {
                                                bookmarked === true
                                                    ?
                                                    <BookmarkIcon className={classes.icon} />
                                                    :
                                                    <BookmarkBorderIcon className={classes.icon} />
                                            }
                                        </IconButton>
                                        :
                                        <IconButton onClick={handleClickOpen}>
                                            <BookmarkBorderIcon className={classes.icon} />
                                        </IconButton>
                                }
                                <IconButton onClick={handleShareLinkClick}>
                                    <MoreHorizIcon className={classes.icon} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>

                    <Divider style={{ width: '100%', marginBottom: '5%' }} />

                    {/* Profile Section */}
                    <Grid item xl={7} lg={7} md={8} sm={12} xs={12} style={{ marginBottom: '5%' }}>
                        {storyDetails.userEmail !== "" ?
                            <RouterLink to={`/aboutUser`} state={storyDetails}>
                                <Box className={classes.innerBox}>
                                    <Avatar style={{ width: '68px', height: '68px', marginRight: '10px' }} alt={storyDetails.userName} src={storyDetails.profilePicRef} />
                                    <Box>
                                        <Typography color='textSecondary' style={{ fontSize: '0.8rem' }}>
                                            WRITTEN BY
                                        </Typography>

                                        {/* Writter's Name */}
                                        <Typography color='textPrimary' style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                            {storyDetails.userName}
                                        </Typography>

                                        {/* Writter's Details */}
                                        <Typography color='textSecondary' style={{ fontSize: '0.8rem' }}>
                                            {storyDetails.userDetails}
                                        </Typography>
                                    </Box>
                                </Box>
                            </RouterLink>
                            :
                            <Box className={classes.innerBox}>
                                <Avatar style={{ width: '68px', height: '68px', marginRight: '10px' }} alt={storyDetails.userName} src={storyDetails.profilePicRef} />
                                <Box>
                                    <Typography color='textSecondary' style={{ fontSize: '0.8rem' }}>
                                        WRITTEN BY
                                    </Typography>

                                    {/* Writter's Name */}
                                    <Typography color='textPrimary' style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                        {storyDetails.userName}
                                    </Typography>

                                    {/* Writter's Details */}
                                    <Typography color='textSecondary' style={{ fontSize: '0.8rem' }}>
                                        {storyDetails.userDetails}
                                    </Typography>
                                </Box>
                            </Box>
                        }
                    </Grid>
                </Grid>
                : null}
            {open ? <LoginDialog open={open} onClose={handleClose} /> : null}
            {shareLinkOpen ?
                <PopOverShareLinks
                    open={shareLinkOpen}
                    anchorEl={anchorEl}
                    onClose={handleShareLinkClose}
                    storyId={storyDetails.storyId}
                    storyTitle={storyDetails.storyTitle}
                    storyBody={storyDetails.storyContent}
                />
                : null}
        </Box >
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.authState.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateMoreStoriesUpvoteCount: (storyId) => dispatch(updateMoreStoriesUpvoteCount(storyId)),
        updateMoreStoriesDownvoteCount: (storyId) => dispatch(updateMoreStoriesDownvoteCount(storyId)),
        updateHandPickedUpvoteCount: (storyId) => dispatch(updateHandPickedUpvoteCount(storyId)),
        updateHandPickedDownvoteCount: (storyId) => dispatch(updateHandPickedDownvoteCount(storyId)),
        updateSearchUpvoteCount: (storyId) => dispatch(updateSearchUpvoteCount(storyId)),
        updateSearchDownvoteCount: (storyId) => dispatch(updateSearchDownvoteCount(storyId)),
        updateBookmarkUpvoteCount: (storyId) => dispatch(updateBookmarkUpvoteCount(storyId)),
        updateBookmarkDownvoteCount: (storyId) => dispatch(updateBookmarkDownvoteCount(storyId)),
        updateUserReadList: (storyId) => dispatch(updateUserReadList(storyId)),
        removeBookMarkedStoryFromRedux: (storyId) => dispatch(removeBookMarkedStoryFromRedux(storyId)),
        addUsersBookmarkedStory: (storyId) => dispatch(addUsersBookmarkedStory(storyId)),
        removeUsersBookmarkedStory: (storyId) => dispatch(removeUsersBookmarkedStory(storyId)),
        addUsersUpVoteStory: (storyId) => dispatch(addUsersUpVoteStory(storyId)),
        removeUsersUpVoteStory: (storyId) => dispatch(removeUsersUpVoteStory(storyId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoryDetail);