import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import {
    makeStyles,
    Container,
    Grid,
    Divider,
    Typography,
    Box
} from '@material-ui/core'
import Page from '../../components/Page'
import StoryTitle from '../../components/StoryTitle'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import Loader from '../../components/Loader'
import { fetchBookMarkedStories } from '../../redux/bookmarked/bookmarkedAction'
import { ReactComponent as EmptyStateImage } from '../../assets/EmptyStateImage.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        ['@media (max-width:600px)']: {
            paddingBottom: theme.spacing(5),
            paddingTop: theme.spacing(5)
        }
    },
    title: {
        fontSize: '1.5rem',
        fontWeight: 'bold'
    },
    divider: {
        width: '100%', margin: '1% 0 2% 0'
    },
    box: {
        display: 'flex', alignItems: 'center'
    },
    subTitle: {
        fontSize: '0.8rem',
        fontWeight: 'bold'
    }
}));

const BookmarkedStories = ({ user, fetchBookMarkedStories, bookmarkedStories }) => {
    const classes = useStyles();

    useEffect(() => {
        localStorage.setItem('from', 'bookmarked')
        if (user && user) {
            let data = { ids: user.bookmarkedStories }
            if (user.bookmarkedStories.length > 0) fetchBookMarkedStories(data)
        }
    }, [user])

    return (
        <Page
            className={classes.root}
            title="Bookmarked Stories | YourStories"
        >
            {bookmarkedStories.loading ? (<Loader />) :
                <Container fixed style={{ padding: '0 0 0 0' }}>
                    <Grid container spacing={4}>
                        <Grid
                            container
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            spacing={2}
                        >
                            {/* Title */}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '1%' }}>
                                <Box className={classes.box}>
                                    <BookmarkBorderIcon fontSize="large" style={{ marginRight: '15px', color: '#263238' }} />
                                    <Typography className={classes.title} color='textPrimary'>Bookmarked Stories</Typography>
                                </Box>
                            </Grid>

                            <Divider orientation='horizontal' className={classes.divider} />

                            {/* Bookmarked Stories Result Section */}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Typography className={classes.subTitle} color='textPrimary'>STORIES YOU HAVE SAVED</Typography>
                            </Grid>
                            {bookmarkedStories.bookmarkedStoriesState && bookmarkedStories.bookmarkedStoriesState.map(data => {
                                return (
                                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                        <RouterLink to={`/read/${data._id}`}>
                                            <StoryTitle storyData={data} user={user} />
                                        </RouterLink>
                                    </Grid>
                                )
                            })}
                            {
                                bookmarkedStories.bookmarkedStoriesState && bookmarkedStories.bookmarkedStoriesState.length === 0
                                    ?
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                        <Box>
                                            <EmptyStateImage />
                                            <Typography color='primary' style={{ fontSize: '1.2rem', fontWeight: 'bold', margin: '1% 0 1% 0' }}>
                                                Add Bookmarks
                                            </Typography>
                                            <Typography color='textSecondary' style={{ fontSize: '0.8rem', margin: '1% 0 1% 0' }}>
                                                Don't forget to bookmark the stories you like the most so that you can find those easily over here.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    :
                                    null
                            }
                        </Grid>
                    </Grid>
                </Container>
            }
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.authState.user,
        bookmarkedStories: state.bookmarkedStories
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchBookMarkedStories: (data) => dispatch(fetchBookMarkedStories(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookmarkedStories);