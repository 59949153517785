import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import {
    makeStyles,
    Container,
    Grid,
    Avatar,
    Typography,
    Box,
    Divider,
    Button
} from '@material-ui/core';
import Page from '../../components/Page';
import ProfileStory from '../../components/ProfileStory'
import Loader from '../../components/Loader'
import { fetchProfileStories } from '../../redux/profile/profileAction'
import { authSignOut } from '../../redux/auth/authAction'
import { ReactComponent as EmptyStateImage } from '../../assets/EmptyStateImage.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        ['@media (max-width:600px)']: {
            paddingBottom: theme.spacing(5),
            paddingTop: theme.spacing(5)
        }
    },
    title: {
        fontSize: '1.5rem',
        fontWeight: 'bold'
    }
}));

const Profile = ({ user, fetchProfileStories, profileStories, authSignOut }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        if (user && user) {
            fetchProfileStories(user.email)
        }
    }, [user])

    return (
        <Page
            className={classes.root}
            title="Profile | YourStories"
        >
            {profileStories.loading ? (<Loader />) :
                <Container fixed style={{ padding: '0 0 0 0' }}>
                    <Grid container spacing={3}>
                        {/* Profile Name */}
                        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '1%' }}>
                            <Box>
                                <Typography className={classes.title} color='textPrimary'>
                                    {user.displayName}
                                </Typography>
                            </Box>
                        </Grid>

                        <Divider style={{ width: '100%', marginBottom: '2%' }} />

                        {/* About Section */}
                        <Grid
                            container
                            item
                            xl={3}
                            lg={3}
                            md={2}
                            sm={12}
                            xs={12}
                            style={{ height: 'fit-content' }}
                        >
                            {/* Profile Image */}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} justify="center" style={{ marginBottom: '5%' }}>
                                <Avatar
                                    variant="square"
                                    style={{ width: '100px', height: '100px' }}
                                    alt={user.displayName}
                                    src={user.photoURL}
                                />
                            </Grid>

                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '1%' }}>
                                <Typography color='textSecondary' style={{ fontSize: '0.8rem' }}>
                                    ABOUT
                                </Typography>
                            </Grid>

                            {/* Profile's Name */}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '1%' }}>
                                <Typography color='textPrimary' style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                    {user.displayName}
                                </Typography>
                            </Grid>

                            {/* Profile's Details */}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '5%' }}>
                                <Typography color='textSecondary' style={{ fontSize: '0.8rem' }}>
                                    {profileStories.profileStoriesState && profileStories.profileStoriesState.length ?
                                        profileStories.profileStoriesState[0].userDetails : ''}
                                </Typography>
                            </Grid>

                            {/* Logout */}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    onClick={() => {
                                        authSignOut()
                                        navigate(`/`, { replace: true })
                                    }}
                                    style={{ textTransform: 'none', borderRadius: '15px' }}
                                >
                                    Logout
                                </Button>
                            </Grid>
                        </Grid>

                        {/* Profile Stories Section */}
                        <Grid
                            container
                            item
                            xl={6}
                            lg={6}
                            md={8}
                            sm={12}
                            xs={12}
                        >
                            {profileStories.profileStoriesState && profileStories.profileStoriesState.map(data => {
                                return (
                                    <ProfileStory storyDetails={data} />
                                )
                            })}
                            {
                                profileStories.profileStoriesState && profileStories.profileStoriesState.length === 0
                                    ?
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                        <Box>
                                            <EmptyStateImage />
                                            <Typography style={{ fontSize: '1rem', margin: '1% 0 1% 0' }}>
                                                We can't find your stories
                                            </Typography>
                                            <Typography color='primary' style={{ fontSize: '1.2rem', fontWeight: 'bold', margin: '1% 0 3% 0' }}>
                                                WANNA WRITE ONE?
                                            </Typography>
                                            <Typography color='primary' style={{ fontSize: '1.2rem', margin: '1% 0 1% 0' }}>
                                                Tap on Write button in your top right corner.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    :
                                    null
                            }
                        </Grid>

                        {/* Empty for Now (Ads) */}
                        <Grid
                            container
                            item
                            xl={3}
                            lg={3}
                            md={2}
                            sm={12}
                            xs={12}
                        >
                        </Grid>
                    </Grid>
                </Container>
            }
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.authState.user,
        profileStories: state.profileStories
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchProfileStories: (userEmail) => dispatch(fetchProfileStories(userEmail)),
        authSignOut: () => dispatch(authSignOut())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);