import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
    Box,
    makeStyles,
    Grid,
    InputBase,
    Typography,
    IconButton,
    Button,
    ClickAwayListener,
    Grow,
    Paper,
    MenuList,
    MenuItem,
    Popper
} from '@material-ui/core';
import StyledChip from './StyledChip'
import { DEFAULT_TAGS, BASE_URL } from '../constants/constant'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import axios from 'axios'
import ImageGalleryDialog from './ImageGalleryDialog';
import Loader from './Loader'
import DraftConfirmationDialog from './DraftConfirmationDialog'
import SaveIcon from '@material-ui/icons/Save';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles((theme) => ({

    root: {
        alignItems: 'center', width: '100%'
    },
    imageUpload: {
        display: 'none',
    },
    editButton: {
        position: 'absolute',
        zIndex: 99,
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
}));

const StoryWriting = ({ user }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    // Options List
    const fieldOptions = ['Your Story', 'Cover Pic', 'Facebook Handle', 'Twitter Handle', 'Instagram Handle']

    // Select DropDown
    const [dropdown, setDropdown] = useState(false)
    const anchorRef = React.useRef(null);
    const handleSelectToggle = () => { setDropdown((prevOpen) => !prevOpen) }
    const handleSelectClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) return;
        setDropdown(false);
    }
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setDropdown(false);
        }
    }
    const prevOpen = React.useRef(dropdown);
    useEffect(() => {
        if (prevOpen.current === true && dropdown === false) anchorRef.current.focus();
        prevOpen.current = dropdown;
    }, [dropdown])


    // Dialog Open and close
    const [open, setOpen] = useState(false)
    const handleClose = () => { setOpen(false) }

    // Draft Confirmation Dialog open and close
    const [draftOpen, setDraftOpen] = useState(false)
    const handleDraftClose = () => { setDraftOpen(false) }


    // Tags Add and Remove
    const [tagList, setTagList] = useState([])
    const removeTag = (tag) => { setTagList(tagList.filter(data => data !== tag)) }


    // For conditional rendering
    const [file, setFile] = useState(null)
    const [body, setBody] = useState(false)
    const [image, setImage] = useState(false)
    const [facebookLink, setFacebookLink] = useState(false)
    const [twitterLink, setTwitterLink] = useState(false)
    const [instagramLink, setInstagramLink] = useState(false)
    const [options, setOptions] = useState(fieldOptions)


    //Handle Option click and it functionality
    const handleOptionClick = (data) => {
        switch (data) {
            case 'Your Story':
                setBody(true)
                break;
            case 'Cover Pic':
                setImage(true)
                break;
            case 'Facebook Handle':
                setFacebookLink(true)
                break;
            case 'Twitter Handle':
                setTwitterLink(true)
                break;
            case 'Instagram Handle':
                setInstagramLink(true)
                break;
            default:
                break;
        }
        setOptions(options.filter(x => x !== data))
        setDropdown(false)
    }


    // Fields State
    const [values, setValues] = useState({
        Title: '',
        Body: '',
        FacebookLink: '',
        TwitterLink: '',
        InstagramLink: ''
    })


    // Handle field value in state
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues((prevValue) => { return { ...prevValue, [name]: value } })
    }

    const handleSubmit = (e) => {
        if (user) {
            e.preventDefault()
            if (values.Title.length < 5 || values.Title.length > 50) return toast.warn('Title should be 5 to 50 char long')
            // if (values.Title.length < 5 || values.Title.length > 50) return toast('Title should be 5 to 50 char long', { style: { backgroundColor: '#2FEDAD' } })
            if (values.Body.length < 500 || values.Body.length > 5000) return toast.warn('Story should be 500 to 5000 char long')
            if (file === null || file === "") return toast.warn('Please select a Image')
            if (tagList.length === 0) return toast.warn('Please choose aleast 1 Tag')

            var tagString = ''
            tagList.forEach(elem => { tagString += `${elem}####` })

            const Obj = {
                id: uuidv4(),
                age: 0,
                country: '',
                coverPicRef: file.urls.regular,
                coverPicOwnerName: file.user.name,
                coverPicOwnerLink: file.user.links.html,
                facebookLink: values.FacebookLink,
                instagramLink: values.InstagramLink,
                loginType: user.providerId,
                profession: '',
                profilePicRef: user.photoURL,
                publishDate: 0,
                readCount: 0,
                status: 0,
                storyContent: values.Body,
                storyTitle: values.Title,
                submissionDate: new Date().getTime(),
                tagList: tagList,
                tags: tagString,
                twitterLink: values.TwitterLink,
                upvoteCount: 0,
                userDetails: '',
                userEmail: user.email,
                userName: user.displayName
            }

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            };

            setLoading(true)
            axios.post(`${BASE_URL}createStory/create`, Obj, config)
                .then(() => {
                    // Set State to empty
                    setTagList([])
                    setFile(null)
                    setOptions(fieldOptions)
                    setValues({ Title: '', Body: '', FacebookLink: '', TwitterLink: '', InstagramLink: '' })
                    setBody(false)
                    setImage(false)
                    setFacebookLink(false)
                    setTwitterLink(false)
                    setInstagramLink(false)

                    // Navigate to Dashboard
                    navigate(`/`, { replace: true })

                    toast.success('Your Story is Submitted');
                    setLoading(false);
                    localStorage.removeItem('query')
                    localStorage.removeItem('imageGallery')
                    localStorage.removeItem('DraftStory')
                })
                .catch((error) => { toast.error(error); setLoading(false); })
        } else {
            toast.error('Please Login')
        }
    }

    // Handle Save to Draft in localstorage
    const handleDraft = () => {
        localStorage.setItem('DraftStory', JSON.stringify({
            Title: values.Title,
            Body: values.Body,
            ImageData: file,
            FacebookLink: values.FacebookLink,
            TwitterLink: values.TwitterLink,
            InstagramLink: values.InstagramLink,
            SelectedOptions: options,
            SelectedTags: tagList
        }))
        toast.success('Story saved as Draft, you can edit later.')
    }

    // Updateing the fields as if story is saved to draft
    useEffect(() => {
        if (localStorage.getItem('DraftStory')) {
            const draftObj = JSON.parse(localStorage.getItem('DraftStory'))
            setValues((prevValue) => {
                return {
                    ...prevValue,
                    Title: draftObj.Title,
                    Body: draftObj.Body,
                    FacebookLink: draftObj.FacebookLink,
                    TwitterLink: draftObj.TwitterLink,
                    InstagramLink: draftObj.InstagramLink
                };
            });
            setFile(draftObj.ImageData)
            setTagList(draftObj.SelectedTags)
            setOptions(draftObj.SelectedOptions)
            var fieldToOpen = fieldOptions.filter(x => !draftObj.SelectedOptions.includes(x))
            fieldToOpen.forEach(element => {
                switch (element) {
                    case 'Your Story':
                        setBody(true)
                        break;
                    case 'Cover Pic':
                        setImage(true)
                        break;
                    case 'Facebook Handle':
                        setFacebookLink(true)
                        break;
                    case 'Twitter Handle':
                        setTwitterLink(true)
                        break;
                    case 'Instagram Handle':
                        setInstagramLink(true)
                        break;
                    default:
                        break;
                }
            })
        }
    }, [])

    return (
        <Box className={classes.root}>
            <Grid
                container
                spacing={3}
            >
                <form onSubmit={handleSubmit}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {/* Title component */}
                        <InputBase
                            autoFocus
                            fullWidth
                            placeholder="Your Title"
                            id="Title"
                            name="Title"
                            value={values.Title}
                            onChange={handleChange}
                            inputProps={{ style: { fontSize: '2rem', fontWeight: 500, color: '#828282' } }}
                            style={{ marginBottom: '5%' }}
                        />

                        {/* Body component */}
                        {
                            body ?
                                <InputBase
                                    fullWidth
                                    multiline
                                    rows={10}
                                    placeholder="Your Story (min 500 words)"
                                    id="Body"
                                    name="Body"
                                    value={values.Body}
                                    onChange={handleChange}
                                    inputProps={{ style: { fontSize: '1rem', fontWeight: 500, color: '#828282' } }}
                                    style={{ marginBottom: '5%' }}
                                />
                                : null
                        }

                        {/* CoverPic component */}
                        {
                            image ?
                                <Box>
                                    {file !== null ?
                                        <Box style={{
                                            position: 'relative',
                                            background: `url(${file.urls.regular}) no-repeat`,
                                            width: '100%',
                                            height: '350px',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            marginBottom: '5%'
                                        }}
                                        >
                                            <IconButton aria-label="edit" className={classes.editButton} onClick={() => { setOpen(true); }}>
                                                <EditIcon style={{ color: 'white' }} />
                                            </IconButton>
                                        </Box>
                                        :
                                        <Box
                                            style={{
                                                textAlign: 'center',
                                                border: '3px dashed #83DA48',
                                                borderRadius: '40px',
                                                width: '100%',
                                                height: '350px',
                                                marginBottom: '5%',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => { setOpen(true); }}
                                        >
                                            <AddPhotoAlternateIcon fontSize="large" style={{ color: '#C4C4C4', margin: '20% 0 3% 0' }} />
                                            <Typography color='textSecondary' style={{ fontSize: '0.8rem', fontWeight: 500 }}>
                                                Select cover pic for your Story
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                                :
                                null
                        }

                        {/* Facebook Handle component */}
                        {
                            facebookLink ?
                                <InputBase
                                    fullWidth
                                    placeholder="Facebook Handle"
                                    id="FacebookLink"
                                    name="FacebookLink"
                                    value={values.FacebookLink}
                                    onChange={handleChange}
                                    inputProps={{ style: { fontSize: '1rem', fontWeight: 500, color: '#828282' } }}
                                    style={{ marginBottom: '5%' }}
                                />
                                : null
                        }

                        {/* Instagram Handle component */}
                        {
                            instagramLink ?
                                <InputBase
                                    fullWidth
                                    placeholder="Instagram Handle"
                                    id="InstagramLink"
                                    name="InstagramLink"
                                    value={values.InstagramLink}
                                    onChange={handleChange}
                                    inputProps={{ style: { fontSize: '1rem', fontWeight: 500, color: '#828282' } }}
                                    style={{ marginBottom: '5%' }}
                                />
                                : null
                        }

                        {/* Twitter Handle component */}
                        {
                            twitterLink ?
                                <InputBase
                                    fullWidth
                                    placeholder="Twitter Handle"
                                    id="TwitterLink"
                                    name="TwitterLink"
                                    value={values.TwitterLink}
                                    onChange={handleChange}
                                    inputProps={{ style: { fontSize: '1rem', fontWeight: 500, color: '#828282' } }}
                                />
                                : null
                        }
                    </Grid>
                    <Box>
                        {/* Add component button */}
                        <IconButton
                            ref={anchorRef}
                            aria-controls={dropdown ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleSelectToggle}
                        >
                            <AddIcon />
                        </IconButton>
                        {/* Dropdown to select which component to make visible */}
                        <Popper open={dropdown} anchorEl={anchorRef.current} role={undefined} transition>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleSelectClose}>
                                            <MenuList autoFocusItem={dropdown} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                {options.map((data) => {
                                                    return (
                                                        <MenuItem onClick={() => handleOptionClick(data)} name={data}>{data}</MenuItem>
                                                    )
                                                })}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Box>

                    {/* Tag Selction to select min of 1 and max of 3 tags */}
                    <Grid item direction="row" xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'left' }}>
                        <Typography color='textSecondary' style={{ fontSize: '0.8rem', fontWeight: 500, marginBottom: '10px' }}>
                            Choose Tags (max 3)
                        </Typography>
                        {DEFAULT_TAGS.map(data => {
                            return (
                                <StyledChip
                                    label={data}
                                    variant={tagList.includes(data) ? 'default' : 'outlined'}
                                    deleteIcon={<CloseIcon />}
                                    onClick={() => {
                                        if (tagList.length !== 3) {
                                            if (!tagList.includes(data)) setTagList(previousTags => ([...previousTags, data]))
                                        } else {
                                            return
                                        }
                                    }}
                                    onDelete={tagList.includes(data) ? () => removeTag(data) : null}
                                    style={{
                                        margin: '0 10px 15px 0',
                                        borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`
                                    }}
                                />
                            )
                        })}
                    </Grid>

                    {/* Publish button */}
                    <Box style={{ textAlign: 'center', margin: '5% 0 0 0' }}>
                        <Button
                            type="submit"
                            color='primary'
                            variant='contained'
                            startIcon={<CloudUploadIcon />}
                            style={{ color: 'white', textTransform: 'none', marginRight: '10px' }}
                        >
                            Publish
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SaveIcon />}
                            onClick={() => setDraftOpen(true)}
                            style={{ color: 'white', textTransform: 'none' }}
                        >
                            Save as Draft
                        </Button>
                    </Box>
                </form>
            </Grid>

            {/* Image Gallery Dialog */}
            {open ? <ImageGalleryDialog open={open} onClose={handleClose} setFile={setFile} /> : null}

            {/* Draft Condfirmation dialog */}
            {draftOpen ? <DraftConfirmationDialog open={draftOpen} onClose={handleDraftClose} handleDraft={handleDraft} /> : null}

            {/* Loader */}
            {loading === true ? (<Loader />) : null}
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.authState.user
    };
};

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(StoryWriting);