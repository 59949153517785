import {
    FETCH_SEARCH_REQUEST,
    FETCH_SEARCHBYTAG_SUCCESS,
    FETCH_SEARCH_FAILURE,
    FETCH_SEARCHBYTAGLIST_SUCCESS,
    FETCH_SEARCHBYTITLE_SUCCESS,
    FETCH_SEARCHBYTITLEANDTAGLIST_SUCCESS,
    UPDATE_SEARCH_UPVOTE_COUNT,
    UPDATE_SEARCH_DOWNVOTE_COUNT
} from '../actionTypes'

const initialState = {
    loading: false,
    searchStoriesState: [],
    error: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SEARCH_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_SEARCHBYTAG_SUCCESS:
            return {
                loading: false,
                searchStoriesState: action.payload,
                error: null
            }
        case FETCH_SEARCH_FAILURE:
            return {
                loading: false,
                searchStoriesState: [],
                error: action.payload
            }
        case FETCH_SEARCHBYTAGLIST_SUCCESS:
            return {
                loading: false,
                searchStoriesState: action.payload,
                error: null
            }
        case FETCH_SEARCHBYTITLE_SUCCESS:
            return {
                loading: false,
                searchStoriesState: action.payload,
                error: null
            }
        case FETCH_SEARCHBYTITLEANDTAGLIST_SUCCESS:
            return {
                loading: false,
                searchStoriesState: action.payload,
                error: null
            }
        case UPDATE_SEARCH_UPVOTE_COUNT:
            return {
                loading: false,
                searchStoriesState: state.searchStoriesState.map(x => x._id === action.payload ? { ...x, upvoteCount: x.upvoteCount + 1 } : x),
                error: null
            }
        case UPDATE_SEARCH_DOWNVOTE_COUNT:
            return {
                loading: false,
                searchStoriesState: state.searchStoriesState.map(x => x._id === action.payload ? { ...x, upvoteCount: x.upvoteCount - 1 } : x),
                error: null
            }
        default: return state
    }
}

export default reducer
