import React from 'react';
import {
    Box,
    IconButton,
    makeStyles,
    withStyles,
    Dialog,
    GridList,
    GridListTile,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment
} from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { createApi } from "unsplash-js";
import { UNSPLASH_ACCESS_KEY } from '../constants/constant'
import Loader from './Loader'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    textField: {
        [`& fieldset`]: {
            borderRadius: 15,
        },
        width: '100%'
    }
}));

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const unsplash = createApi({ accessKey: UNSPLASH_ACCESS_KEY })


const ImageGalleryDialog = ({ open, onClose, setFile }) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        if (localStorage.getItem('query') !== null) setQuery(localStorage.getItem('query'))
        if (localStorage.getItem('imageGallery') !== null) setUnsplashImages(JSON.parse(localStorage.getItem('imageGallery')))
    }, [])

    const [query, setQuery] = React.useState("")
    const [unsplashImages, setUnsplashImages] = React.useState([])

    const handleSearch = () => {
        setLoading(true)
        unsplash
            .search
            .getPhotos({ query: query, orientation: "landscape", perPage: 30 })
            .then(result => { setUnsplashImages(result.response.results); setLoading(false); })
    }

    const handleDialogClose = () => {
        localStorage.setItem('query', query)
        localStorage.setItem('imageGallery', JSON.stringify(unsplashImages))
        onClose()
    }

    return (
        <Dialog
            keepMounted={true}
            PaperProps={{
                style: {
                    background: 'white',
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    color: 'balck',
                    padding: '3% 1% 3% 1%'
                },
            }}
            onClose={handleDialogClose}
            open={open}
        >
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleDialogClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <FormControl className={classes.textField} variant="outlined">
                    <InputLabel htmlFor="search">Search</InputLabel>
                    <OutlinedInput
                        id="search"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        label="Search"
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                handleSearch()
                            }
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleSearch}
                                    edge="end"
                                >
                                    {<SearchIcon color='primary' />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </DialogContent>
            <DialogContent>
                <GridList cellHeight={160} style={{ width: '100%', height: '350px' }} cols={3}>
                    {unsplashImages && unsplashImages.map((data, index) => (
                        <GridListTile key={index} cols={1}>
                            <LazyLoadImage
                                height='100%'
                                width='100%'
                                effect="blur"
                                src={data.urls.regular}
                                placeholderSrc={data.urls.regular}
                                onClick={() => {
                                    setFile(data)
                                    handleDialogClose()
                                }}
                                style={{ cursor: 'pointer' }}
                            />
                        </GridListTile>
                    ))}
                </GridList>
            </DialogContent>
            {loading === true ? (<Loader />) : null}
        </Dialog>
    );
};

export default ImageGalleryDialog;