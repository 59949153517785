import firebase from '../../config/firebaseConfig'
import { PATH_PENDING_STORIES } from '../../constants/constant'
import {
    FETCH_PENDING_STORIES_REQUEST,
    FETCH_PENDING_STORIES_SUCCESS,
    FETCH_PENDING_STORIES_FAILURE,
    STORY_REMOVED_FROM_PENDING,
    UPDATE_IMAGE_FOR_PENDING_STORY,
    REMOVE_MULTIPLE_STORIES_FROM_PENDING
} from '../actionTypes'

export const fetchPendingStories = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_PENDING_STORIES_REQUEST })
        firebase
            .firestore()
            .collection(PATH_PENDING_STORIES)
            .orderBy('submissionDate', 'desc')
            .get()
            .then((collections) => {
                dispatch({ type: FETCH_PENDING_STORIES_SUCCESS, payload: collections.docs.map((doc) => doc.data()) })
            })
            .catch(error => {
                dispatch({ type: FETCH_PENDING_STORIES_FAILURE, payload: error })
            })
    }
}

export const removeStoryFromPending = story => {
    return {
        type: STORY_REMOVED_FROM_PENDING,
        payload: story
    }
}

export const updateImageForPendingStory = data => {
    return {
        type: UPDATE_IMAGE_FOR_PENDING_STORY,
        payload: data
    }
}

export const removeMultipleStoriesFromPending = storyIds => {
    return{
        type: REMOVE_MULTIPLE_STORIES_FROM_PENDING,
        payload: storyIds
    }
}