import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { makeStyles, Box, Typography } from '@material-ui/core';
import AdminStoriesLineGraph from '../components/AdminStoriesLineGraph'
import AdminPopoverDataCount from '../components/AdminPopoverDataCount'
import { ReactComponent as GraphUpArrow } from '../assets/GraphUpArrow.svg'
import { ReactComponent as GraphDownArrow } from '../assets/GraphDownArrow.svg'

const useStyles = makeStyles((theme) => ({
    box: {
        fontFamily: 'Montserrat', fontStyle: 'normal', height: '160px', background: '#FFFFFF', border: '1px solid #E0E0E0',
        boxSizing: 'border-box', borderRadius: '10px', padding: '15px 24px 15px 24px', textAlign: 'left'
    },
    innerBox: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center'
    },
    title: {
        fontWeight: 600, fontSize: '12px', lineHeight: '15px', color: '#78909C'
    },
    period: {
        fontWeight: 'normal', fontSize: '10px', lineHeight: '12px', color: '#78909C'
    },
    percent: {
        fontWeight: 600, fontSize: '12px', lineHeight: '15px', marginRight: '5px'
    }
}));

const AdminLiveStoriesCard = ({ approvedStories }) => {
    const classes = useStyles();

    // Handle Multiple PopOvers
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openedPopoverId, setOpenedPopoverId] = React.useState(null);
    const handlePopoverOpen = (event, id) => {
        setAnchorEl(event.currentTarget);
        setOpenedPopoverId(id)
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
        setOpenedPopoverId(null)
    };

    function getDates(startDate, endDate) {
        const dates = []
        let currentDate = startDate
        const addDays = function (days) {
            const date = new Date(this.valueOf())
            date.setDate(date.getDate() + days)
            return date
        }
        while (currentDate <= endDate) {
            dates.push(currentDate)
            currentDate = addDays.call(currentDate, 1)
        }
        return dates
    }

    const [totalLiveStoriesCount, setTotalLiveStoriesCount] = useState(0)
    const [countInPercent, setCountInPercent] = useState(0)
    const [increased, setIncreased] = useState(false)
    const [storiesCountInLastSeven, setStoriesCountInLastSeven] = useState(0)
    const [dataList, setDataList] = useState([])
    const [xAxisList, setXAxisList] = useState([])

    useEffect(() => {
        if (approvedStories) {
            setTotalLiveStoriesCount(approvedStories.length)

            // Getting last days Records
            let lastSevenDaysRecords = approvedStories.filter(x =>
                x.publishDate >= moment().startOf('day').subtract(7, 'd').valueOf() &&
                x.publishDate <= moment().endOf('day').valueOf()
            )

            // Getting last to last 7 days Records
            let previousLastSevenDaysRecords = approvedStories.filter(x =>
                x.publishDate >= moment().startOf('day').subtract(14, 'd').valueOf() &&
                x.publishDate <= moment().endOf('day').subtract(7, 'd').valueOf()
            )

            // Calculating difference in percent
            let diffPercent = 100 * Math.abs((lastSevenDaysRecords.length - previousLastSevenDaysRecords.length) /
                ((lastSevenDaysRecords.length + previousLastSevenDaysRecords.length) / 2))
            setCountInPercent(Math.round(diffPercent * 10) / 10)

            // Comparing the two and setting the increment flag as true
            if (lastSevenDaysRecords.length > previousLastSevenDaysRecords.length) setIncreased(true)

            setStoriesCountInLastSeven(lastSevenDaysRecords.length)

            // To Get and set XAxis dataList
            let lastSevenDayDates = []
            let dates = getDates(new Date(moment().subtract(7, 'd')), new Date())
            dates.forEach(element => {
                lastSevenDayDates.push(moment(element).format('D/M'))
            })
            setXAxisList(lastSevenDayDates)

            // To create DataList for Graph
            let lastSevenDaysDataCount = []
            lastSevenDayDates.forEach(element => {
                let records = lastSevenDaysRecords.filter(x => moment(x.publishDate).format('D/M') === element)
                lastSevenDaysDataCount.push(records.length)
            })
            setDataList(lastSevenDaysDataCount)
        }
    }, [approvedStories])

    return (
        <Box className={classes.box}>
            {/* The Headings */}
            <Box className={classes.innerBox} style={{ marginBottom: '5px' }}>
                <Typography className={classes.title} >Total Live Stories</Typography>
                <Typography className={classes.period} >in Last 7 days</Typography>
            </Box>

            {/* The Data */}
            <Box className={classes.innerBox} style={{ marginBottom: '5px' }}>
                <Typography style={{ fontWeight: '600', fontSize: '26px', lineHeight: '26px', color: '#546E7A' }}>
                    {totalLiveStoriesCount}
                </Typography>
                <Box className={classes.innerBox}>
                    <Typography className={classes.percent} style={{ color: increased === true ? '#27AE60' : '#EF5350' }}
                        aria-owns={'LiveStoriesCount'}
                        aria-haspopup="true"
                        onMouseEnter={(event) => handlePopoverOpen(event, 'LiveStoriesCount')}
                        onMouseLeave={handlePopoverClose}
                    >
                        {`${countInPercent}%`}
                    </Typography>
                    {
                        increased === true ? <GraphUpArrow /> : <GraphDownArrow />
                    }
                    <AdminPopoverDataCount
                        id="LiveStoriesCount"
                        open={openedPopoverId === 'LiveStoriesCount'}
                        anchorEl={anchorEl}
                        aov="bottom" aoh="center" tov="top" toh="center"
                        onClose={handlePopoverClose}
                        dataCount={`+${storiesCountInLastSeven}`}
                    />
                </Box>
            </Box>

            {/* Graph */}
            <Box>
                <AdminStoriesLineGraph
                    graphType={'line'}
                    height={100}
                    label={'Stories'}
                    dataList={dataList}
                    xaxisList={xAxisList}
                />
            </Box>
        </Box>
    )
}

export default AdminLiveStoriesCard