import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux'
import {
    makeStyles,
    Container,
    Grid,
    Divider,
    Typography,
    Box,
    InputBase
} from '@material-ui/core';
import Page from '../../components/Page';
import StoryTitle from '../../components/StoryTitle'
import StyledChip from '../../components/StyledChip'
import SearchIcon from '@material-ui/icons/Search';
import Loader from '../../components/Loader'
import CloseIcon from '@material-ui/icons/Close';
import { TAGS_WITH_COLOR } from '../../constants/constant'
import {
    fetchStoriesByTag,
    fetchStoriesByTagList,
    fetchStoriesByTitle,
    fetchStoriesByTitleAndTagList
} from '../../redux/search/searchAction'
import { ReactComponent as EmptyStateImage } from '../../assets/EmptyStateImage.svg'


const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        ['@media (max-width:600px)']: {
            paddingBottom: theme.spacing(5),
            paddingTop: theme.spacing(5)
        }
    },
    title: {
        fontSize: '1.5rem',
        fontWeight: 'bold'
    },
    divider: {
        width: '100%', margin: '1% 0 2% 0'
    },
    box: {
        display: 'flex', alignItems: 'center', marginBottom: '2%'
    },
    subTitle: {
        fontSize: '1rem',
        fontWeight: 'bold'
    }
}));

const SearchStories = ({
    fetchStoriesByTag,
    fetchStoriesByTagList,
    fetchStoriesByTitle,
    fetchStoriesByTitleAndTagList,
    searchStories
}) => {
    const classes = useStyles();

    const [searchValue, setSearchValue] = useState('');
    const [tagList, setTagList] = useState([]);

    useEffect(() => {
        localStorage.setItem('from', 'search')
        const url = window.location.href
        if (url.includes('searchBy')) {
            setTagList([window.location.href.split('/searchBy/')[1]])
            fetchStoriesByTag(window.location.href.split('/searchBy/')[1])
        }
    }, [])

    const handleChange = () => {
        if (searchValue !== '') {
            if (tagList.length) {
                let data = { title: searchValue, tags: tagList }
                fetchStoriesByTitleAndTagList(data)
            } else {
                fetchStoriesByTitle(searchValue)
            }
        } else {

        }
    }

    useEffect(() => {
        if (tagList.length) {
            if (searchValue !== '') {
                let data = { title: searchValue, tags: tagList }
                fetchStoriesByTitleAndTagList(data)
            } else {
                let data = { tags: tagList }
                fetchStoriesByTagList(data)
            }
        }
    }, [tagList])

    const removeTag = (tag) => {
        setTagList(tagList.filter(data => data !== tag))
    }

    return (
        <Page
            className={classes.root}
            title="Search Stories | YourStories"
        >
            {searchStories.loading ? (<Loader />) :
                <Container fixed style={{ padding: '0 0 0 0' }}>
                    <Grid container spacing={4}>
                        <Grid
                            container
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            spacing={2}
                        >
                            {/* Title */}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '1%' }}>
                                <Typography className={classes.title} color='textPrimary'>Search Stories</Typography>
                            </Grid>

                            {/* Search Section */}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Box className={classes.box}>
                                    <SearchIcon fontSize="large" style={{ marginRight: '10px' }} />
                                    <InputBase
                                        placeholder='Search Stories'
                                        value={searchValue}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleChange()
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item direction="row" xl={8} lg={8} md={10} sm={12} xs={12}>
                                {TAGS_WITH_COLOR.map(data => {
                                    return (
                                        <StyledChip
                                            label={data.tag}
                                            variant={tagList.includes(data.tag) ? 'default' : 'outlined'}
                                            deleteIcon={<CloseIcon />}
                                            onClick={() => {
                                                if (!tagList.includes(data.tag)) setTagList(previousTags => ([...previousTags, data.tag]))
                                            }}
                                            onDelete={tagList.includes(data.tag) ? () => removeTag(data.tag) : null}
                                            style={{
                                                margin: '0 10px 15px 0',
                                                borderColor: data.color,
                                                background: tagList.includes(data.tag) ? data.color : 'white'
                                            }}
                                        />
                                    )
                                })}
                            </Grid>

                            <Divider orientation='horizontal' className={classes.divider} />

                            {/* Search Stories Result Section */}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Box className={classes.box}>
                                    <SearchIcon fontSize="large" style={{ marginRight: '10px' }} />
                                    <Typography className={classes.subTitle} color='textPrimary'>SEARCH RESULT</Typography>
                                </Box>
                            </Grid>
                            {searchStories.searchStoriesState && searchStories.searchStoriesState.map(data => {
                                return (
                                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                        <RouterLink to={`/read/${data._id}`}>
                                            <StoryTitle storyData={data} />
                                        </RouterLink>
                                    </Grid>
                                )
                            })}
                            {tagList.length !== 0 ?
                                searchStories.searchStoriesState && searchStories.searchStoriesState.length === 0
                                    ?
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                        <Box>
                                            <EmptyStateImage />
                                            <Typography color='primary' style={{ fontSize: '1.2rem', fontWeight: 'bold', margin: '1% 0 1% 0' }}>
                                                No Results
                                            </Typography>
                                            <Typography color='textSecondary' style={{ fontSize: '0.8rem', margin: '1% 0 1% 0' }}>
                                                Sorry, can't find any stories for the selected criteria. Try Again, or Explore more from the handpicked ones.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    :
                                    null
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                </Container>
            }
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        searchStories: state.searchStories
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchStoriesByTag: (tag) => dispatch(fetchStoriesByTag(tag)),
        fetchStoriesByTagList: (data) => dispatch(fetchStoriesByTagList(data)),
        fetchStoriesByTitle: (title) => dispatch(fetchStoriesByTitle(title)),
        fetchStoriesByTitleAndTagList: (data) => dispatch(fetchStoriesByTitleAndTagList(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchStories);