import {
    FETCH_BOOKMARKED_REQUEST,
    FETCH_BOOKMARKED_SUCCESS,
    FETCH_BOOKMARKED_FAILURE,
    REMOVE_BOOKMARKED_STORY,
    UPDATE_BOOKMARKED_UPVOTE_COUNT,
    UPDATE_BOOKMARKED_DOWNVOTE_COUNT
} from '../actionTypes'

const initialState = {
    loading: false,
    bookmarkedStoriesState: [],
    error: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BOOKMARKED_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_BOOKMARKED_SUCCESS:
            return {
                loading: false,
                bookmarkedStoriesState: action.payload,
                error: null
            }
        case FETCH_BOOKMARKED_FAILURE:
            return {
                loading: false,
                bookmarkedStoriesState: [],
                error: action.payload
            }
        case REMOVE_BOOKMARKED_STORY:
            let bookmarkedList = state.bookmarkedStoriesState.filter(x => x._id !== action.payload)
            return {
                ...state,
                bookmarkedStoriesState: bookmarkedList
            }
        case UPDATE_BOOKMARKED_UPVOTE_COUNT:
            return {
                loading: false,
                bookmarkedStoriesState: state.bookmarkedStoriesState.map(x => x._id === action.payload ? { ...x, upvoteCount: x.upvoteCount + 1 } : x),
                error: null
            }
        case UPDATE_BOOKMARKED_DOWNVOTE_COUNT:
            return {
                loading: false,
                bookmarkedStoriesState: state.bookmarkedStoriesState.map(x => x._id === action.payload ? { ...x, upvoteCount: x.upvoteCount - 1 } : x),
                error: null
            }
        default: return state
    }
}

export default reducer
