import firebase from '../../config/firebaseConfig'
import { PATH_USERS } from '../../constants/constant'
import {
    FETCH_REGISTERED_USERS_REQUEST,
    FETCH_REGISTERED_USERS_SUCCESS,
    FETCH_REGISTERED_USERS_FAILURE
} from '../actionTypes'

export const fetchRegisteredUsers = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_REGISTERED_USERS_REQUEST })
        firebase
            .firestore()
            .collection(PATH_USERS)
            .orderBy('registeredAt', 'desc')
            .get()
            .then((collections) => {
                dispatch({ type: FETCH_REGISTERED_USERS_SUCCESS, payload: collections.docs.map((doc) => doc.data()) })
            })
            .catch(error => {
                dispatch({ type: FETCH_REGISTERED_USERS_FAILURE, payload: error })
            })
    }
}