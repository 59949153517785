import React from 'react';
import { connect } from 'react-redux'
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles'
import theme from './theme';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import NotFoundView from './views/errors/NotFoundView'
import Layout from './layouts'
import Dashboard from './views/dashboard'
import SearchStories from './views/searchStories'
import BookmarkedStories from './views/bookmarkedStories'
import ReadStoryPage from './views/readStory'
import LoneReadStoryPage from './views/loneReadStory'
import WriteStoryPage from './views/writeStory'
import Profile from './views/profile'
import AboutUserStories from './views/aboutUserStories'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import AdminSignIn from './views/adminSignIn'
import AdminDashboard from './views/adminDashboard'
import AdminGlobalStyles from './components/AdminGlobalStyles'

function App({ user, admin }) {

  return (
    <ThemeProvider theme={theme}>
      {admin.length || admin.email ? <AdminGlobalStyles /> : <GlobalStyles />}
      <Routes>
        <Route path='/' element={<Layout />} >

          {/* Normal User Routes */}
          <Route path='/' element={<Dashboard />} />
          <Route path='search' element={<SearchStories />} />
          <Route path='searchBy/:tag' element={<SearchStories />} />
          {user.length || user.user ?
            <Route path='bookmarked' element={<BookmarkedStories />} />
            : null}
          <Route path='read/:id' element={<ReadStoryPage />} />
          <Route path='directRead/:id' element={<LoneReadStoryPage />} />
          {user.length || user.user ?
            <Route path='write' element={<WriteStoryPage />} />
            : null}
          {user.length || user.user ?
            <Route path='profile' element={<Profile />} />
            : null}
          <Route path='aboutUser' element={<AboutUserStories />} />
          <Route path='404' element={<NotFoundView />} />
          <Route path='*' element={<Navigate to="/404" />} />

          {/* Admin Routes */}
          <Route path='adminSignIn' element={<AdminSignIn />} />
          {admin.length || admin.email ?
            <Route path='adminDashboard' element={<AdminDashboard />} />
            :
            null}
        </Route>
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        toastId='Session Has Expired'
      />
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.authState,
    admin: state.adminAuth.adminAuthState
  };
};

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
