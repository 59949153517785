import firebase from '../config/firebaseConfig'
import { PATH_PENDING_STORIES, PATH_REJECTED_STORIES, PATH_PUBLISHED_STORIES } from '../constants/constant'
import { toast } from 'react-toastify';

export async function rejectSingleStory(story) {
    story.publishDate = 0
    story.status = -1

    // Add the rejected story to rejected collection
    await firebase
        .firestore()
        .collection(PATH_REJECTED_STORIES)
        .doc(story.id)
        .set(story)
        .then(() => {
            // Now remove story from pending collection
            firebase
                .firestore()
                .collection(PATH_PENDING_STORIES)
                .doc(story.id)
                .delete()
                .then(() => {
                    toast.success('You Rejected a Story');
                }).catch((error) => {
                    toast.error(error);
                })
        }).catch((error) => {
            toast.error(error);
        })
}

export async function rejectSingleStoryFromApproved(story) {
    story.publishDate = 0
    story.status = -1

    // Add the rejected story to rejected collection
    await firebase
        .firestore()
        .collection(PATH_REJECTED_STORIES)
        .doc(story.id)
        .set(story)
        .then(() => {
            // Now remove story from approved collection
            firebase
                .firestore()
                .collection(PATH_PUBLISHED_STORIES)
                .doc(story.id)
                .delete()
                .then(() => {
                    toast.success('You Rejected a Story');
                }).catch((error) => {
                    toast.error(error);
                })
        }).catch((error) => {
            toast.error(error);
        })
}

export async function rejectMultipleStory(stories) {

    for (const story of stories) {
        story.publishDate = 0
        story.status = -1

        // Add the rejected story to rejected collection
        await firebase
            .firestore()
            .collection(PATH_REJECTED_STORIES)
            .doc(story.id)
            .set(story)
            .then(() => {
                // Now remove story from pending collection
                firebase
                    .firestore()
                    .collection(PATH_PENDING_STORIES)
                    .doc(story.id)
                    .delete()
                    .then(() => {
                    }).catch((error) => {
                        toast.error(error);
                    })
            }).catch((error) => {
                toast.error(error);
            })
    }
    toast.success(`You Rejected ${stories.length} Stories`);
}

export async function rejectMultipleStoryFromApproved(stories) {

    for (const story of stories) {
        story.publishDate = 0
        story.status = -1

        // Add the rejected story to rejected collection
        await firebase
            .firestore()
            .collection(PATH_REJECTED_STORIES)
            .doc(story.id)
            .set(story)
            .then(() => {
                // Now remove story from approved collection
                firebase
                    .firestore()
                    .collection(PATH_PUBLISHED_STORIES)
                    .doc(story.id)
                    .delete()
                    .then(() => {
                    }).catch((error) => {
                        toast.error(error);
                    })
            }).catch((error) => {
                toast.error(error);
            })
    }
    toast.success(`You Rejected ${stories.length} Stories`);
}