import React from 'react';
import {
    makeStyles,
    Container,
    Grid
} from '@material-ui/core';
import Page from '../../components/Page';
import StoryWriting from '../../components/StoryWriting'

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
        paddingBottom: theme.spacing(5),
        paddingTop: theme.spacing(5)
    },
    grid: {
        ['@media (max-width:600px)']: {
            display: 'none'
        }
    },
    sideActionGrid: {
        display: 'block',
        ['@media (max-width:720px)']: {
            display: 'flex'
        }
    }
}));

const WriteStoryPage = () => {
    const classes = useStyles();

    return (
        <Page
            className={classes.root}
            title="Write Your Story | YourStories"
        >
            <Container fixed>
                <Grid container spacing={3}>

                    {/* Empty for now (Ads) */}
                    <Grid
                        container
                        item
                        xl={3}
                        lg={3}
                        md={2}
                        sm={1}
                        className={classes.grid}
                    >
                    </Grid>

                    {/* Write Story Section */}
                    <Grid
                        container
                        item
                        xl={6}
                        lg={6}
                        md={8}
                        sm={10}
                        xs={12}
                    >
                        <StoryWriting />
                    </Grid>

                    {/* Empty for now (Ads) */}
                    <Grid
                        className={classes.grid}
                        container
                        item
                        xl={3}
                        lg={3}
                        md={2}
                        sm={1}
                    >
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

export default WriteStoryPage;