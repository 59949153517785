import axios from 'axios'
import { BASE_URL } from '../constants/constant'
import { toast } from 'react-toastify';

export function upVoteStory(userId, storyId) {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    };
    axios.put(`${BASE_URL}user/upVoteStory/${userId}/${storyId}`, {}, config)
    .then(() => {
        toast.success('You upvoted this story')
    })
}

export function downVoteStory(userId, storyId) {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    };
    axios.put(`${BASE_URL}user/downVoteStory/${userId}/${storyId}`, {}, config)
    .then(() => {
        toast.success('Your upvote for this story is removed')
    })
}