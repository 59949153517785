import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { ReactComponent as GraphUpArrow } from '../assets/GraphUpArrow.svg'
import { ReactComponent as GraphDownArrow } from '../assets/GraphDownArrow.svg'

const useStyles = makeStyles((theme) => ({
    box: {
        fontFamily: 'Montserrat', fontStyle: 'normal', height: '160px', background: '#FFFFFF', border: '1px solid #E0E0E0',
        boxSizing: 'border-box', borderRadius: '10px', padding: '15px 24px 15px 24px', textAlign: 'left'
    },
    innerBox: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center'
    },
    title: {
        fontWeight: 600, fontSize: '12px', lineHeight: '15px', color: '#78909C'
    },
    period: {
        fontWeight: 'normal', fontSize: '10px', lineHeight: '12px', color: '#78909C'
    },
    percent: {
        fontWeight: 600, fontSize: '12px', lineHeight: '15px', marginRight: '5px'
    },
    listHead: {
        fontWeight: 'normal', fontSize: '12px', lineHeight: '15px'
    },
    listCount: {
        color: '#546E7A', fontWeight: 600, fontSize: '18px', lineHeight: '22px'
    }
}));

const AdminStoriesCountCard = ({ approvedStories, pendingStories, rejectedStories }) => {
    const classes = useStyles();

    const [received, setReceived] = useState(0)
    const [receivedInPercent, setReceivedInPercent] = useState(0)
    const [receivedIncrement, setReceivedIncrement] = useState(false)
    const [pending, setPending] = useState(0)
    const [pendingInPercent, setPendingInPercent] = useState(0)
    const [pendingIncrement, setPendingIncrement] = useState(false)
    const [approved, setApproved] = useState(0)
    const [approvedInPercent, setApprovedInPercent] = useState(0)
    const [approvedIncrement, setApprovedIncrement] = useState(false)

    useEffect(() => {
        if (approvedStories && pendingStories && rejectedStories) {
            // For Approved Section
            let lastSevenDaysApprovedStories = approvedStories.filter(x =>
                x.publishDate >= moment().startOf('day').subtract(7, 'd').valueOf() &&
                x.publishDate <= moment().endOf('day').valueOf()
            )
            setApproved(lastSevenDaysApprovedStories.length)

            let previousSevenDaysApprovedStories = approvedStories.filter(x =>
                x.publishDate >= moment().startOf('day').subtract(14, 'd').valueOf() &&
                x.publishDate <= moment().endOf('day').subtract(7, 'd').valueOf()
            )

            let approvedDiffPercent = 100 * Math.abs((lastSevenDaysApprovedStories.length - previousSevenDaysApprovedStories.length) /
                ((lastSevenDaysApprovedStories.length + previousSevenDaysApprovedStories.length) / 2))
            setApprovedInPercent(Math.round(approvedDiffPercent * 10) / 10)

            if (lastSevenDaysApprovedStories.length > previousSevenDaysApprovedStories.length) setApprovedIncrement(true)


            // For Pending Section
            let lastSevenDaysPendingStories = pendingStories.filter(x =>
                x.submissionDate >= moment().startOf('day').subtract(7, 'd').valueOf() &&
                x.submissionDate <= moment().endOf('day').valueOf()
            )
            setPending(lastSevenDaysPendingStories.length)

            let previousSevenDaysPendingStories = pendingStories.filter(x =>
                x.submissionDate >= moment().startOf('day').subtract(14, 'd').valueOf() &&
                x.submissionDate <= moment().endOf('day').subtract(7, 'd').valueOf()
            )

            let pendingDiffPercent = 100 * Math.abs((lastSevenDaysPendingStories.length - previousSevenDaysPendingStories.length) /
                ((lastSevenDaysPendingStories.length + previousSevenDaysPendingStories.length) / 2))
            setPendingInPercent(Math.round(pendingDiffPercent * 10) / 10)

            if (lastSevenDaysPendingStories.length > previousSevenDaysPendingStories.length) setPendingIncrement(true)


            // For Received Section
            let lastSevenDaysRejectedStories = rejectedStories.filter(x =>
                x.submissionDate >= moment().startOf('day').subtract(7, 'd').valueOf() &&
                x.submissionDate <= moment().endOf('day').valueOf()
            )

            let previousSevenDaysRejectedStories = rejectedStories.filter(x =>
                x.submissionDate >= moment().startOf('day').subtract(14, 'd').valueOf() &&
                x.submissionDate <= moment().endOf('day').subtract(7, 'd').valueOf()
            )

            setReceived(lastSevenDaysApprovedStories.length + lastSevenDaysPendingStories.length + lastSevenDaysRejectedStories.length)

            let receivedDiffPercent = 100 * Math.abs(
                (lastSevenDaysPendingStories.length - previousSevenDaysPendingStories.length - lastSevenDaysRejectedStories.length) /
                ((lastSevenDaysPendingStories.length + previousSevenDaysPendingStories.length + lastSevenDaysRejectedStories.length) / 3)
            )

            setReceivedInPercent(Math.round(receivedDiffPercent * 10) / 10)

            if (
                (lastSevenDaysApprovedStories.length + lastSevenDaysPendingStories.length + lastSevenDaysRejectedStories.length) >
                (previousSevenDaysApprovedStories.length + previousSevenDaysPendingStories.length + previousSevenDaysRejectedStories.length)
            ) setReceivedIncrement(true)

        }
    }, [approvedStories, pendingStories, rejectedStories])

    return (
        <Box className={classes.box}>
            {/* The Headings */}
            <Box className={classes.innerBox} style={{ marginBottom: '18px' }}>
                <Typography className={classes.title} >Stories</Typography>
                <Typography className={classes.period} >in Last 7 days</Typography>
            </Box>

            {/* Received */}
            <Box className={classes.innerBox} style={{ marginBottom: '18px' }}>
                <Box style={{ width: '33.33%' }}>
                    <Typography className={classes.listHead} style={{ color: '#42A5F5' }}>Received</Typography>
                </Box>
                <Box style={{ width: '33.33%' }}>
                    <Typography className={classes.listCount}>{received}</Typography>
                </Box>
                <Box className={classes.innerBox}>
                    <Typography
                        className={classes.percent}
                        style={{ color: receivedIncrement === true ? '#27AE60' : '#EF5350' }}
                    >
                        {`${receivedInPercent}%`}
                    </Typography>
                    {
                        receivedIncrement === true ? <GraphUpArrow /> : <GraphDownArrow />
                    }
                </Box>
            </Box>

            {/* Pending */}
            <Box className={classes.innerBox} style={{ marginBottom: '18px' }}>
                <Box style={{ width: '33.33%' }}>
                    <Typography className={classes.listHead} style={{ color: '#FFA726' }}>Pending</Typography>
                </Box>
                <Box style={{ width: '33.33%' }}>
                    <Typography className={classes.listCount}>{pending}</Typography>
                </Box>
                <Box className={classes.innerBox}>
                    <Typography
                        className={classes.percent}
                        style={{ color: pendingIncrement === true ? '#27AE60' : '#EF5350' }}
                    >
                        {`${pendingInPercent}%`}
                    </Typography>
                    {
                        pendingIncrement === true ? <GraphUpArrow /> : <GraphDownArrow />
                    }
                </Box>
            </Box>

            {/* Approved */}
            <Box className={classes.innerBox}>
                <Box style={{ width: '33.33%' }}>
                    <Typography className={classes.listHead} style={{ color: '#66BB6A' }}>Approved</Typography>
                </Box>
                <Box style={{ width: '33.33%' }}>
                    <Typography className={classes.listCount}>{approved}</Typography>
                </Box>
                <Box className={classes.innerBox}>
                    <Typography
                        className={classes.percent}
                        style={{ color: approvedIncrement === true ? '#27AE60' : '#EF5350' }}
                    >
                        {`${approvedInPercent}%`}
                    </Typography>
                    {
                        approvedIncrement === true ? <GraphUpArrow /> : <GraphDownArrow />
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default AdminStoriesCountCard