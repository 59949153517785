import axios from 'axios'
import { BASE_URL } from '../../../constants/constant'
import {
    FETCH_HANDPICKED_REQUEST,
    FETCH_HANDPICKED_SUCCESS,
    FETCH_HANDPICKED_FAILURE,
    UPDATE_HANDPICKED_UPVOTE_COUNT,
    UPDATE_HANDPICKED_DOWNVOTE_COUNT
} from '../../actionTypes'

export const fetchHandPickedStories = () => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    return (dispatch) => {
        dispatch({ type: FETCH_HANDPICKED_REQUEST })
        axios.get(`${BASE_URL}handPickedStories/get`, config)
            .then(response => {
                dispatch({ type: FETCH_HANDPICKED_SUCCESS, payload: response.data.Stories })
            })
            .catch(error => {
                dispatch({ type: FETCH_HANDPICKED_FAILURE, payload: error })
            })
    }
}

export const updateHandPickedUpvoteCount = (storyId) => {
    return { type: UPDATE_HANDPICKED_UPVOTE_COUNT, payload: storyId }
}

export const updateHandPickedDownvoteCount = (storyId) => {
    return { type: UPDATE_HANDPICKED_DOWNVOTE_COUNT, payload: storyId }
}