import firebase from '../../config/firebaseConfig'
import {
    ADMIN_SIGNIN_REQUEST,
    ADMIN_SIGNIN_SUCCESS,
    ADMIN_SIGNIN_FAILURE,
    ADMIN_SIGNOUT_REQUEST,
    ADMIN_SIGNOUT_SUCCESS,
    ADMIN_SIGNOUT_FAILURE
} from '../actionTypes'
import { toast } from 'react-toastify';

export const adminSignIn = (email, password, navigate) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_SIGNIN_REQUEST })
        firebase.auth()
            .signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                var user = userCredential.user;
                dispatch({ type: ADMIN_SIGNIN_SUCCESS, payload: user })
                navigate('/adminDashboard', { replace: true })
            })
            .catch((error) => {
                var errorObj = {
                    // Handle Errors here.
                    errorCode: error.code,
                    // The email of the user's account used.
                    errorMessage: error.message,
                    email: error.email,
                    // The firebase.auth.AuthCredential type that was used.
                    credential: error.credential
                }
                dispatch({ type: ADMIN_SIGNIN_FAILURE, payload: errorObj })
                toast.error(errorObj.errorMessage)
            })
    }
}


export const adminAuthSignOut = (navigate) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_SIGNOUT_REQUEST })
        firebase.auth().signOut().then((result) => {
            dispatch({ type: ADMIN_SIGNOUT_SUCCESS })
            navigate('/adminSignIn', { replace: true })
        }).catch((error) => {
            dispatch({ type: ADMIN_SIGNOUT_FAILURE, payload: error })
        })
    }
}