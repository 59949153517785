import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import {
    makeStyles,
    Container,
    Grid,
    Typography,
    Box,
    IconButton
} from '@material-ui/core';
import Page from '../../components/Page';
import StoryDetail from '../../components/StoryDetail'
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import { ReactComponent as MessageIcon } from '../../assets/messageIcon.svg'
import LoginDialog from '../../components/LoginDialog';
import firebase from '../../config/firebaseConfig'
import { PATH_PUBLISHED_STORIES, PATH_STORIES_ACTION } from '../../constants/constant'
import { bookMarkStory, removeBookmarkedStory } from '../../utils/bookMark'
import { upVoteStory, downVoteStory } from '../../utils/upVote'
import PopOverShareLinks from '../../components/PopOverShareLinks';
import Loader from '../../components/Loader';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        ['@media (max-width:600px)']: {
            paddingBottom: theme.spacing(5),
            paddingTop: theme.spacing(5)
        }
    },
    icons: {
        color: 'black'
    },
    grid: {
        ['@media (max-width:600px)']: {
            display: 'none'
        }
    },
    sideActionGrid: {
        display: 'block',
        ['@media (max-width:720px)']: {
            display: 'flex'
        }
    }
}));

const LoneReadStoryPage = ({ user }) => {
    const classes = useStyles();

    // Login Dialog Open/Close Handling
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // Local state for loading data and using further
    const [storyDetails, setStoryDetails] = useState();
    const [loading, setLoading] = useState(false);
    const [bookmarked, setBookmarked] = useState(false)
    const [upVote, setUpVote] = useState(false)
    const storyId = window.location.href.split('/directRead/')[1]

    // Fetch Story Obj from db
    useEffect(() => {
        setLoading(true)
        firebase
            .firestore()
            .collection(PATH_PUBLISHED_STORIES)
            .doc(storyId)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    setStoryDetails(doc.data())
                    if (user && user) {
                        firebase
                            .firestore()
                            .collection(PATH_STORIES_ACTION)
                            .doc(user.email)
                            .get()
                            .then((doc) => {
                                if (doc.exists) {
                                    let data = doc.data()
                                    if (data.bookmarkedStories) setBookmarked(data.bookmarkedStories.includes(storyId))
                                    if (data.upVoteStories) setUpVote(data.upVoteStories.includes(storyId))
                                }
                            })
                    }
                    setLoading(false)
                } else {
                    setStoryDetails({ "message": "No Data Found" })
                    setLoading(false)
                }
            })
    }, [user])

    // Handle Scroll, its forced to top on load
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Update UpVote in local useState
    const updateUpvote = () => {
        setStoryDetails({ ...storyDetails, upvoteCount: storyDetails.upvoteCount + 1 })
    }

    // Update DownVote in local useState
    const updateDownvote = () => {
        setStoryDetails({ ...storyDetails, upvoteCount: storyDetails.upvoteCount - 1 })
    }

    // Handle UpVote Count
    const handleStateUpvoteCount = (storyId) => {
        updateUpvote()
    }

    // Handle DownVote Count
    const handleStateDownvoteCount = (storyId) => {
        updateDownvote()
    }

    // Share Links Open/Close Handling
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleShareLinkClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleShareLinkClose = () => {
        setAnchorEl(null);
    };
    const shareLinkOpen = Boolean(anchorEl)

    return (
        <Page
            className={classes.root}
            title="Read Story | YourStories"
        >
            {loading ? (<Loader />) :
                <Container fixed style={{ padding: '0 0 0 0' }}>
                    <Grid container spacing={3}>
                        {/* Left Action Section */}
                        <Grid container item xl={3} lg={3} md={2} sm={1} className={classes.grid} style={{ height: 'fit-content' }}>
                            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.sideActionGrid}>
                                <Box style={{ float: 'right', paddingTop: '45%' }}>
                                    <Box style={{ display: 'flex', alignItems: 'center', margin: '0 20% 0 0' }}>
                                        {
                                            user ?
                                                <IconButton onClick={upVote === true
                                                    ?
                                                    () => {
                                                        downVoteStory(storyDetails.id, user.email)
                                                        handleStateDownvoteCount(storyDetails.id)
                                                        setUpVote(false)
                                                    }
                                                    :
                                                    () => {
                                                        upVoteStory(storyDetails.id, user.email)
                                                        handleStateUpvoteCount(storyDetails.id)
                                                        setUpVote(true)
                                                    }
                                                }>
                                                    {
                                                        upVote === true
                                                            ?
                                                            <StarIcon className={classes.icons} />
                                                            :
                                                            <StarOutlineIcon className={classes.icons} />
                                                    }
                                                </IconButton>
                                                :
                                                <IconButton onClick={handleClickOpen}>
                                                    <StarOutlineIcon className={classes.icons} />
                                                </IconButton>
                                        }
                                        <Typography color='textPrimary' style={{ fontSize: '0.7rem', fontWeight: 'bold' }}>
                                            {`${storyDetails && storyDetails.upvoteCount !== undefined ? storyDetails.upvoteCount : 0}`}
                                        </Typography>
                                    </Box>

                                    {/* <Box style={{ display: 'flex', alignItems: 'center', margin: '0 20% 0 0' }}>
                                        <IconButton>
                                            <MessageIcon className={classes.icons} />
                                        </IconButton>
                                        <Typography color='textPrimary' style={{ fontSize: '0.7rem', fontWeight: 'bold' }}>
                                            {0}
                                        </Typography>
                                    </Box> */}

                                    <Box style={{ margin: '0 20% 0 0' }}>
                                        {
                                            user ?
                                                <IconButton onClick={bookmarked === true
                                                    ?
                                                    () => {
                                                        removeBookmarkedStory(storyDetails.id, user.email)
                                                        setBookmarked(false)
                                                    }
                                                    :
                                                    () => {
                                                        bookMarkStory(storyDetails.id, user.email)
                                                        setBookmarked(true)
                                                    }
                                                }>
                                                    {
                                                        bookmarked === true
                                                            ?
                                                            <BookmarkIcon className={classes.icons} />
                                                            :
                                                            <BookmarkBorderIcon className={classes.icons} />
                                                    }
                                                </IconButton>
                                                :
                                                <IconButton onClick={handleClickOpen}>
                                                    <BookmarkBorderIcon className={classes.icons} />
                                                </IconButton>
                                        }
                                    </Box>

                                    <Box style={{ margin: '0 20% 0 0' }}>
                                        <IconButton onClick={handleShareLinkClick}>
                                            <ShareOutlinedIcon className={classes.icons} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                        {/* Story Section */}
                        <Grid container item xl={6} lg={6} md={8} sm={10} xs={12}>
                            <StoryDetail
                                storyDetails={storyDetails}
                                bookmarked={bookmarked}
                                setBookMarked={setBookmarked}
                                upVote={upVote}
                                setUpVote={setUpVote}
                                updateStateUpvote={updateUpvote}
                                updateStateDownvote={updateDownvote}
                            />
                        </Grid>

                        {/* Empty for now (Ads) */}
                        <Grid className={classes.grid} container item xl={3} lg={3} md={2} sm={1}></Grid>
                    </Grid>
                </Container>
            }
            {open ? <LoginDialog open={open} onClose={handleClose} /> : null}
            {shareLinkOpen ?
                <PopOverShareLinks
                    open={shareLinkOpen}
                    anchorEl={anchorEl}
                    onClose={handleShareLinkClose}
                    storyId={storyId}
                    storyTitle={storyDetails.storyTitle}
                    storyBody={storyDetails.storyContent}
                />
                : null}
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.authState.user
    };
};

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LoneReadStoryPage);