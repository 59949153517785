export const BASE_URL = process.env.REACT_APP_BASEURL

// Unsplash Cred
export const UNSPLASH_ACCESS_KEY = process.env.REACT_APP_UNSPLASH_ACCESS_KEY
export const UNSPLASH_SECRET_KEY = process.env.REACT_APP_UNSPLASH_SECRET_KEY

// Collection Path
export const PATH_PENDING_STORIES = process.env.REACT_APP_PATH_PENDING_STORIES
export const PATH_SHORTLISTED_STORIES = process.env.REACT_APP_PATH_SHORTLISTED_STORIES
export const PATH_PUBLISHED_STORIES = process.env.REACT_APP_PATH_PUBLISHED_STORIES
export const PATH_BOOKMARKED_STORIES = process.env.REACT_APP_PATH_BOOKMARKED_STORIES
export const PATH_STORIES_ACTION = process.env.REACT_APP_PATH_STORIES_ACTION
export const PATH_REJECTED_STORIES = process.env.REACT_APP_PATH_REJECTED_STORIES
export const PATH_USERS = process.env.REACT_APP_PATH_USERS

// Color List
export const COLOR_LIST = ['#E9C5FB', '#A6D6FF', '#FFEFC9', '#F2994A', '#96FFE1', '#CDD2FD', '#FFCBD3', '#FBDDC3', '#bcaaa4', '#9fa8da']

// Tags List
export const DEFAULT_AGE_GROUPS = ["Below 18", "18-24", "25-34", "35-44", "45-54", "55-64", "Above 65"]
export const DEFAULT_TAGS = [
    "Student", "Transformation", "Motivation", "Mental Health", "Productivity", "Parenting", "Lifestyle",
    "Relationships", "Social Media", "Mindfulness"
]
export const PROFESSION_TAGS = ["Student", "Professional", "Self Employed", "Businessman", "Homemaker", "Retired", "Others"]
export const TAGS_WITH_COLOR = [
    { tag: 'Student', color: '#E9C5FB' },
    { tag: 'Transformation', color: '#A6D6FF' },
    { tag: 'Motivation', color: '#FFEFC9' },
    { tag: 'Mental Health', color: '#F2994A' },
    { tag: 'Productivity', color: '#96FFE1' },
    { tag: 'Parenting', color: '#CDD2FD' },
    { tag: 'Lifestyle', color: '#FFCBD3' },
    { tag: 'Relationships', color: '#FBDDC3' },
    { tag: 'Social Media', color: '#9fa8da' },
    { tag: 'Mindfulness', color: '#bcaaa4' }
]

// Country List
export const COUNTRYLIST = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia",
    "Australia", "Austria", "Austrian Empire", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
    "Belarus", "Belgium", "Belize", "Benin (Dahomey)", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil",
    "Brunei", "Brunswick and Lüneburg", "Bulgaria", "Burkina Faso (Upper Volta)", "Burma", "Burundi", "Cabo Verde",
    "Cambodia", "Cameroon", "Canada", "Cayman Islands", "Central African Republic", "Chad", "Chile", "China", "Colombia",
    "Comoros", "Costa Rica", "Cote d’Ivoire (Ivory Coast)", "Croatia", "Cuba", "Cyprus", "Czechia", "Czechoslovakia",
    "Democratic Republic of the Congo", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt",
    "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France", "Gabon",
    "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti",
    "Holy See", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy",
    "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea", "Kosovo", "Kuwait", "Kyrgyzstan", "Laos",
    "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi",
    "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia",
    "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Namibia", "Nauru", "Nepal", "Netherlands",
    "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau", "Panama",
    "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar", "Republic of Korea (South Korea)",
    "Republic of the Congo", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia",
    "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal",
    "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia",
    "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Tajikistan",
    "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan",
    "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela",
    "Vietnam", "Yemen", "Zambia", "Zimbabwe"
]

//Image Gallery URLs List
export const IMAGE_GALLERY_URLS = [
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FHope.jpg?alt=media&token=7fd8f9b8-76a1-4e3a-99df-82d3c4d0b934',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FLifestyle.jpg?alt=media&token=60a586bf-4336-4c4e-91b7-278595870863',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FMentalHealth.jpg?alt=media&token=295e17ce-ecd9-4ba1-94ff-e8db1d9cc2be',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FMindfulness.jpg?alt=media&token=c10713ab-66ef-4a7f-9250-38933d4363f4',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FMindfulnessWords.jpg?alt=media&token=60356310-2004-448f-a167-780bb44ecff9',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FMotivation.jpg?alt=media&token=ccc10ae7-4d56-4a54-9846-caa699cac486',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FParenting.jpg?alt=media&token=7964849e-3ed8-4802-a67e-3938ed21532c',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FPeace.jpg?alt=media&token=c919edf8-91bd-4a2c-ad5c-a7ac447a06c3',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FProductivity.jpg?alt=media&token=a12a83e1-7bcb-44f3-85db-e079cd3597a1',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FRelationship.jpg?alt=media&token=cbc17ce7-502c-47bc-94f8-9c74e0402640',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FSocialMedia.jpg?alt=media&token=90ee14ec-2caf-4dc2-9d1b-352f7edc2c61',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FStudent.jpg?alt=media&token=6fa1325c-c429-4574-944e-5d2deea887f4',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FTransformation.jpg?alt=media&token=e748340d-34e3-4399-b61f-3bc882c9f588',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FAddiction.jpg?alt=media&token=47e26944-2cb8-49da-b7b5-0ef93d87e6de',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FBegin.jpg?alt=media&token=cdce5993-970f-4467-890e-af5f0e2d4ab0',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FBored.jpg?alt=media&token=aec1e9f0-59ec-4355-a2a5-ba6e495b567f',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FChange.jpg?alt=media&token=a040deab-f864-4d6d-a250-5698df902f20',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FDoSomethingGreate.jpg?alt=media&token=93ae8b9e-b2a2-4479-9356-14b4cd0e6a70',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FFood.jpg?alt=media&token=9d48b5fe-95f1-448e-9fa7-659c7c99b7ec',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FFriends.jpg?alt=media&token=2542f6da-2652-4127-bfd3-d9c5aff0cafe',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FLifeStyleBook.jpg?alt=media&token=2539d020-7dec-4508-a134-2fcb915dd722',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FMobileUsage.jpg?alt=media&token=a9e578ba-1232-4fab-af6e-d76e3ecd6ee4',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FParentWalk.jpg?alt=media&token=6a3cc4f8-b694-474d-8471-180cafedcbc9',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FTree.jpg?alt=media&token=a0413468-e92d-4127-b7c0-150f724554e4',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FWorkFlow.jpg?alt=media&token=144f7afa-25f0-4c1f-8f3e-b3d44384eae5',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FMentalHealthText.jpg?alt=media&token=39942425-a496-4541-a4e7-383ec5b91dc6',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FProductiveWork.jpg?alt=media&token=50dd5bb8-54c0-47d5-8867-d894727ec32c',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FLibrary.jpg?alt=media&token=b6e6d010-f608-4025-a2f0-bcdaa364bc83',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FForever.jpg?alt=media&token=accd5fae-9f1c-4589-97d8-41e9f563c0dd',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FBooks.jpg?alt=media&token=b9cbc49a-a7c5-4543-8804-904292b14d45',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FParentingBook.jpg?alt=media&token=133e79ad-7b02-4a0e-89f6-079cb0975559',
    'https://firebasestorage.googleapis.com/v0/b/yourhour-mindefy.appspot.com/o/coverPicGalleryWeb%2FDreamBig.jpg?alt=media&token=18695d5f-24b2-4e75-b076-bf95ba120f50'
]