import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { makeStyles, Box, Typography } from '@material-ui/core';
import AdminPopoverDataCount from '../components/AdminPopoverDataCount'
import { ReactComponent as GraphUpArrow } from '../assets/GraphUpArrow.svg'
import { ReactComponent as GraphDownArrow } from '../assets/GraphDownArrow.svg'
import { element } from 'prop-types';
import { DEFAULT_TAGS } from '../constants/constant';

const useStyles = makeStyles((theme) => ({
    box: {
        fontFamily: 'Montserrat', fontStyle: 'normal', height: '160px', background: '#FFFFFF', border: '1px solid #E0E0E0',
        boxSizing: 'border-box', borderRadius: '10px', padding: '15px 24px 15px 24px', textAlign: 'left'
    },
    innerBox: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center'
    },
    title: {
        fontWeight: 600, fontSize: '12px', lineHeight: '15px', color: '#78909C'
    },
    period: {
        fontWeight: 'normal', fontSize: '10px', lineHeight: '12px', color: '#78909C'
    },
    percent: {
        fontWeight: 600, fontSize: '12px', lineHeight: '15px', marginRight: '5px'
    },
    listHead: {
        fontWeight: 'normal', fontSize: '12px', lineHeight: '15px'
    },
    bulletStyle: {
        width: '27px', height: '27px', border: '1px solid #E0E0E0', boxSizing: 'border-box', borderRadius: '2px',
        marginRight: '15px', textAlign: 'center', paddingTop: '7px'
    }
}));

const AdminPopularTagsCard = ({ approvedStories }) => {
    const classes = useStyles();

    // Handle Multiple PopOvers
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openedPopoverId, setOpenedPopoverId] = React.useState(null);
    const handlePopoverOpen = (event, id) => {
        setAnchorEl(event.currentTarget);
        setOpenedPopoverId(id)
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
        setOpenedPopoverId(null)
    };

    const [firstTag, setFirstTag] = useState('')
    const [firstTagCount, setFirstTagCount] = useState(0)
    const [firstTagCountInPercent, setFirstTagCountInPercent] = useState(0)
    const [firstTagIncrement, setFirstTagIncrement] = useState(false)
    const [secondTag, setSecondTag] = useState('')
    const [secondTagCount, setSecondTagCount] = useState(0)
    const [secondTagCountInPercent, setSecondTagCountInPercent] = useState(0)
    const [secondTagIncrement, setSecondTagIncrement] = useState(false)
    const [thirdTag, setThirdTag] = useState('')
    const [thirdTagCount, setThirdTagCount] = useState(0)
    const [thirdTagCountInPercent, setThirdTagCountInPercent] = useState(0)
    const [thirdTagIncrement, setThirdTagIncrement] = useState(false)

    useEffect(() => {
        if (approvedStories) {
            // Getting last days Stories
            let lastSevenDaysStories = approvedStories.filter(x =>
                x.publishDate >= moment().startOf('day').subtract(7, 'd').valueOf() &&
                x.publishDate <= moment().endOf('day').valueOf()
            )

            let tags = []
            lastSevenDaysStories.forEach(element => { tags.push(...element.tagList) });

            let tagsWithCount = DEFAULT_TAGS.map(value => [tags.filter(str => str === value).length, value])

            let TopThreeTagsWithCount = tagsWithCount.sort(function (a, b) { return b[0] - a[0]; }).slice(0, 3)

            let TopTagsList = [TopThreeTagsWithCount[0][1], TopThreeTagsWithCount[1][1], TopThreeTagsWithCount[2][1]]


            // Getting last to last 7 days Stories
            let previousLastSevenDaysStories = approvedStories.filter(x =>
                x.publishDate >= moment().startOf('day').subtract(14, 'd').valueOf() &&
                x.publishDate <= moment().endOf('day').subtract(7, 'd').valueOf()
            )

            tags = []
            previousLastSevenDaysStories.forEach(element => { tags.push(...element.tagList) });

            let previousTagsWithCount = TopTagsList.map(value => [tags.filter(str => str === value).length, value])

            let TopThreeTagsPreviousCount = previousTagsWithCount.sort(function (a, b) { return b[0] - a[0]; }).slice(0, 3)


            // First tag
            setFirstTag(TopThreeTagsWithCount[0][1])
            setFirstTagCount(TopThreeTagsWithCount[0][0])
            let firstTagDiffPercent = 100 * Math.abs((TopThreeTagsWithCount[0][0] - TopThreeTagsPreviousCount[0][0]) /
                ((TopThreeTagsWithCount[0][0] + TopThreeTagsPreviousCount[0][0]) / 2))
            setFirstTagCountInPercent(Math.round(firstTagDiffPercent * 10) / 10)
            if (TopThreeTagsWithCount[0][0] > TopThreeTagsPreviousCount[0][0]) setFirstTagIncrement(true)


            // Second tag
            setSecondTag(TopThreeTagsWithCount[1][1])
            setSecondTagCount(TopThreeTagsWithCount[1][0])
            let SecondTagDiffPercent = 100 * Math.abs((TopThreeTagsWithCount[1][0] - TopThreeTagsPreviousCount[1][0]) /
                ((TopThreeTagsWithCount[1][0] + TopThreeTagsPreviousCount[1][0]) / 2))
            setSecondTagCountInPercent(Math.round(SecondTagDiffPercent * 10) / 10)
            if (TopThreeTagsWithCount[1][0] > TopThreeTagsPreviousCount[1][0]) setSecondTagIncrement(true)


            // Third tag
            setThirdTag(TopThreeTagsWithCount[2][1])
            setThirdTagCount(TopThreeTagsWithCount[2][0])
            let ThirdTagDiffPercent = 100 * Math.abs((TopThreeTagsWithCount[2][0] - TopThreeTagsPreviousCount[2][0]) /
                ((TopThreeTagsWithCount[2][0] + TopThreeTagsPreviousCount[2][0]) / 2))
            setThirdTagCountInPercent(Math.round(ThirdTagDiffPercent * 10) / 10)
            if (TopThreeTagsWithCount[2][0] > TopThreeTagsPreviousCount[2][0]) setThirdTagIncrement(true)

        }
    }, [approvedStories])

    return (
        <Box className={classes.box}>
            {/* The Headings */}
            <Box className={classes.innerBox} style={{ marginBottom: '18px' }}>
                <Typography className={classes.title} >Popular Tags</Typography>
                <Typography className={classes.period} >in Last 7 days</Typography>
            </Box>

            {/* 1st Tag */}
            <Box className={classes.innerBox} style={{ marginBottom: '7px' }}>
                <Box style={{ width: '80%', display: 'flex', alignItems: 'center' }}>
                    <Box className={classes.bulletStyle}>
                        <Typography style={{ fontWeight: 600, fontSize: '10px', lineHeight: '12px' }} >1</Typography>
                    </Box>
                    <Typography className={classes.listHead} style={{ color: '#42A5F5' }}>{firstTag}</Typography>
                </Box>
                <Box className={classes.innerBox}>
                    <Typography className={classes.percent} style={{ color: firstTagIncrement === true ? '#27AE60' : '#EF5350' }}
                        aria-owns={'FirstTagCount'}
                        aria-haspopup="true"
                        onMouseEnter={(event) => handlePopoverOpen(event, 'FirstTagCount')}
                        onMouseLeave={handlePopoverClose}
                    >
                        {`${firstTagCountInPercent}%`}
                    </Typography>
                    {
                        firstTagIncrement === true ? <GraphUpArrow /> : <GraphDownArrow />
                    }
                    <AdminPopoverDataCount
                        id="FirstTagCount"
                        open={openedPopoverId === 'FirstTagCount'}
                        anchorEl={anchorEl}
                        aov="center" aoh="left" tov="center" toh="right"
                        onClose={handlePopoverClose}
                        dataCount={`+${firstTagCount}`}
                    />
                </Box>
            </Box>

            {/* 2nd Tag */}
            <Box className={classes.innerBox} style={{ marginBottom: '7px' }}>
                <Box style={{ width: '80%', display: 'flex', alignItems: 'center' }}>
                    <Box className={classes.bulletStyle}>
                        <Typography style={{ fontWeight: 600, fontSize: '10px', lineHeight: '12px' }} >2</Typography>
                    </Box>
                    <Typography className={classes.listHead} style={{ color: '#FFA726' }}>{secondTag}</Typography>
                </Box>
                <Box className={classes.innerBox}>
                    <Typography className={classes.percent} style={{ color: secondTagIncrement === true ? '#27AE60' : '#EF5350' }}
                        aria-owns={'SecondTagCount'}
                        aria-haspopup="true"
                        onMouseEnter={(event) => handlePopoverOpen(event, 'SecondTagCount')}
                        onMouseLeave={handlePopoverClose}
                    >
                        {`${secondTagCountInPercent}%`}
                    </Typography>
                    {
                        secondTagIncrement === true ? <GraphUpArrow /> : <GraphDownArrow />
                    }
                    <AdminPopoverDataCount
                        id="SecondTagCount"
                        open={openedPopoverId === 'SecondTagCount'}
                        anchorEl={anchorEl}
                        aov="center" aoh="left" tov="center" toh="right"
                        onClose={handlePopoverClose}
                        dataCount={`+${secondTagCount}`}
                    />
                </Box>
            </Box>

            {/* 3rd Tag */}
            <Box className={classes.innerBox} style={{ marginBottom: '7px' }}>
                <Box style={{ width: '80%', display: 'flex', alignItems: 'center' }}>
                    <Box className={classes.bulletStyle}>
                        <Typography style={{ fontWeight: 600, fontSize: '10px', lineHeight: '12px' }} >3</Typography>
                    </Box>
                    <Typography className={classes.listHead} style={{ color: '#66BB6A' }}>{thirdTag}</Typography>
                </Box>
                <Box className={classes.innerBox}>
                    <Typography className={classes.percent} style={{ color: thirdTagIncrement === true ? '#27AE60' : '#EF5350' }}
                        aria-owns={'ThirdTagCount'}
                        aria-haspopup="true"
                        onMouseEnter={(event) => handlePopoverOpen(event, 'ThirdTagCount')}
                        onMouseLeave={handlePopoverClose}
                    >
                        {`${thirdTagCountInPercent}%`}
                    </Typography>
                    {
                        thirdTagIncrement === true ? <GraphUpArrow /> : <GraphDownArrow />
                    }
                    <AdminPopoverDataCount
                        id="ThirdTagCount"
                        open={openedPopoverId === 'ThirdTagCount'}
                        anchorEl={anchorEl}
                        aov="center" aoh="left" tov="center" toh="right"
                        onClose={handlePopoverClose}
                        dataCount={`+${thirdTagCount}`}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default AdminPopularTagsCard