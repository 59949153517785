import React from 'react';
import { connect } from 'react-redux'
import {
    Box,
    makeStyles,
    withStyles,
    Dialog,
    Button,
    Typography
} from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as GoogleLogo } from '../assets/GoogleLogo.svg';
import { ReactComponent as FacebookLogo } from '../assets/FacebookLogo.svg'
import { ReactComponent as JoinWhiteText } from '../assets/JoinWhiteText.svg';
import { ReactComponent as YourHourWhiteText } from '../assets/YourHourWhiteText.svg'
import { googleProvider, facebookProvider } from '../config/authProviders'
import { signIn } from '../redux/auth/authAction'

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    loginButton: {
        marginBottom: '2%',
        background: '#FFFFFF',
        boxShadow: '0px 0px 2.18261px rgba(0, 0, 0, 0.084), 0px 1.45507px 2.18261px rgba(0, 0, 0, 0.168)',
        borderRadius: '8px',
        textTransform: 'none',
        color: '#666666'
    }
}));

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


const LoginDialog = ({ open, onClose, signIn, authentication }) => {
    const classes = useStyles();

    // Hnadle Login
    const handleOnClick = (provider) => {
        signIn(provider)
        onClose()
    }

    return (
        <Dialog
            PaperProps={{
                style: {
                    background: 'linear-gradient(225deg, #A6D6FF 0%, #0084F4 100%)',
                    backgroundColor: "transparent",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    color: '#FFFFFF',
                    padding: '5% 6% 5% 6%'
                },
            }}
            onClose={onClose}
            open={open}
        >
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon style={{ color: '#E4E4E4' }} />
            </IconButton>

            <DialogContent>
                <Box style={{ margin: "5% 0 5% 0" }}>
                    <JoinWhiteText style={{ marginRight: '5px' }} />
                    <YourHourWhiteText />
                </Box>

                <Typography style={{ margin: "5% 0 10% 0", fontSize: '0.8rem', fontWeight: 500 }}>
                    Create an account to read the inspirational stories of how Yourhour
                    helped 2+ Million users to minimize there phone addiction
                </Typography>

                <Box style={{ margin: "5% 0 10% 0" }}>
                    <Button
                        variant="contained"
                        className={classes.loginButton}
                        startIcon={<GoogleLogo style={{ width: '20px', height: '20px' }} />}
                        style={{ width: '60%', marginBottom: '5%' }}
                        onClick={() => handleOnClick(googleProvider)}
                    >
                        Login with Google
                    </Button>
                    <br />
                    <Button
                        variant="contained"
                        className={classes.loginButton}
                        startIcon={<FacebookLogo style={{ width: '20px', height: '20px' }} />}
                        style={{ width: '60%' }}
                        onClick={() => handleOnClick(facebookProvider)}
                    >
                        Login with Facebook
                    </Button>
                </Box>

                <Typography style={{ margin: "5% 0 0 0", fontSize: '0.6rem', fontWeight: 400 }}>
                    Click “Log In” to agree to Yourhour’s Terms of Services and
                    acknowledge that Yourhour’s Privacy Policy. applies to you.
                </Typography>

            </DialogContent>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        authentication: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        signIn: (data) => dispatch(signIn(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginDialog);