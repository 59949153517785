import React, { useEffect, useState } from 'react';
import {
    Box,
    makeStyles,
    Grid,
    Typography,
    Divider,
    IconButton
} from '@material-ui/core';
import moment from 'moment'
import StyledChip from './StyledChip'
import ReadMoreOrLess from './ReadMoreOrLess'
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import { ReactComponent as MessageIcon } from '../assets/messageIcon.svg'
import { ReactComponent as FacebookIcon } from '../assets/FacebookIcon.svg'
import TestImage from '../assets/TestImage2.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ShimmerEffect from '../assets/ShimmerEffect.gif'

const useStyles = makeStyles((theme) => ({

    root: {
        alignItems: 'center', width: '100%'
    },
    title: {
        textAlign: 'left', fontSize: '2rem', fontWeight: 'bold'
    },
    box: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center',
        ['@media (max-width:600px)']: {
            display: 'block',
            alignItems: 'center'
        }
    },
    innerBox: {
        display: 'flex', alignItems: 'center',
        ['@media (max-width:600px)']: {
            marginBottom: '10px'
        }
    },
    icon: {
        color: '#1E2833'
    },
    actionBox: {
        display: 'flex', alignItems: 'center'
    },
    actionText: {
        fontWeight: 500, fontSize: '0.8rem'
    }
}));

const ProfileStory = ({ storyDetails }) => {
    const classes = useStyles();
    const [tags, setTags] = useState([])

    useEffect(() => {
        if (storyDetails) {
            setTags(storyDetails.tags.split('####').filter(n => n))
        }
    }, [storyDetails])

    return (
        <Box className={classes.root}>
            {storyDetails ?
                <Grid
                    container
                >
                    {/* Story DateTime and Status */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '2%' }}>
                        <Box className={classes.box}>
                            <Typography color='textSecondary' style={{ fontSize: '0.8rem' }}>
                                {moment(storyDetails.publishDate).format('MMM DD, YYYY')}
                            </Typography>
                            <Typography color='textSecondary' style={{ fontSize: '0.8rem' }}>
                                Approved
                            </Typography>
                        </Box>
                    </Grid>

                    {/* Story Title */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '5%' }}>
                        <Typography color='textPrimary' className={classes.title}>
                            {storyDetails.storyTitle}
                        </Typography>
                    </Grid>

                    {/* Story Image */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '5%' }}>
                        <LazyLoadImage
                            height='350px'
                            width='100%'
                            // effect="blur"
                            src={storyDetails.coverPicRef !== "" ? storyDetails.coverPicRef : TestImage}
                            // placeholderSrc={storyDetails.coverPicRef !== "" ? storyDetails.coverPicRef : TestImage}
                            placeholderSrc={ShimmerEffect}
                        />
                    </Grid>

                    {/* Cover Pic Credits */}
                    {
                        storyDetails.coverPicOwnerLink !== "" &&
                            storyDetails.coverPicOwnerLink !== undefined &&
                            storyDetails.coverPicOwnerName !== "" &&
                            storyDetails.coverPicOwnerName !== undefined ?
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '5%' }}>
                                <Typography color='textSecondary' style={{ textAlign: 'center', fontSize: '0.8rem' }}>
                                    Photo by <a href={storyDetails.coverPicOwnerLink} target="_blank" rel="noopener noreferrer">{storyDetails.coverPicOwnerName}</a> on <a href='https://unsplash.com/' target="_blank" rel="noopener noreferrer">Unsplash</a>
                                </Typography>
                            </Grid>
                            :
                            null
                    }

                    {/* Story Body */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '5%' }}>
                        <ReadMoreOrLess>
                            {storyDetails.storyContent}
                        </ReadMoreOrLess>
                    </Grid>

                    {/* Tags Section */}
                    {tags.map((data, index) => {
                        return (
                            <Grid item direction="row" style={{ margin: '0 10px 5% 0' }}>
                                <StyledChip
                                    key={index}
                                    label={data}
                                    style={{ color: '#828282' }}
                                />
                            </Grid>
                        )
                    })}

                    {/* Story Actions and Links */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '5%' }}>
                        <Box className={classes.box}>
                            {/* Actions */}
                            <Box className={classes.innerBox}>
                                <Box className={classes.actionBox} style={{ marginRight: '10px' }}>
                                    <StarOutlineIcon className={classes.icon} style={{ margin: '0 10px 0 0' }} />
                                    <Typography color='textPrimary' className={classes.actionText}>
                                        {`${storyDetails.upvoteCount !== undefined ? storyDetails.upvoteCount : 0} Upvotes`}
                                    </Typography>
                                </Box>
                                {/* <Box className={classes.actionBox}>
                                    <IconButton>
                                        <MessageIcon className={classes.icon} />
                                    </IconButton>
                                    <Typography color='textPrimary' className={classes.actionText}>
                                        {`${0} Responses`}
                                    </Typography>
                                </Box> */}
                            </Box>

                            {/* Links */}
                            <Box className={classes.innerBox}>
                                {storyDetails.facebookLink !== "" ?
                                    <IconButton>
                                        <FacebookIcon className={classes.icon} />
                                    </IconButton>
                                    : null
                                }
                                {storyDetails.twitterLink !== "" ?
                                    <IconButton>
                                        <TwitterIcon className={classes.icon} />
                                    </IconButton>
                                    : null
                                }
                                {storyDetails.instagramLink !== "" ?
                                    <IconButton>
                                        <InstagramIcon className={classes.icon} />
                                    </IconButton>
                                    : null
                                }
                                <IconButton>
                                    <MoreHorizIcon className={classes.icon} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>
                    <Divider style={{ width: '100%', marginBottom: '5%' }} />
                </Grid>
                : null}
        </Box>
    );
};

export default ProfileStory;