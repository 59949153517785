import axios from 'axios'
import { BASE_URL } from '../constants/constant'
import { toast } from 'react-toastify';

export function bookMarkStory(userId, storyId) {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    };
    axios.put(`${BASE_URL}user/bookmarkStory/${userId}/${storyId}`, {}, config)
        .then(() => {
            toast.success('You have bookmarked this story')
        })
}

export function removeBookmarkedStory(userId, storyId) {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    };
    axios.put(`${BASE_URL}user/unBookmarkStory/${userId}/${storyId}`, {}, config)
        .then(() => {
            toast.success('You have removed this story from your Bookmark.')
        })
}