import firebase from '../../config/firebaseConfig'
import { PATH_REJECTED_STORIES } from '../../constants/constant'
import {
    FETCH_REJECTED_STORIES_REQUEST,
    FETCH_REJECTED_STORIES_SUCCESS,
    FETCH_REJECTED_STORIES_FAILURE,
    ADD_REJECTED_STORY,
    ADD_MULTIPLE_REJECTED_STORIES,
    UPDATE_IMAGE_FOR_REJECTED_STORY,
    REMOVE_SINGLE_STORY_FROM_REJECTED,
    REMOVE_MULTIPLE_STORY_FROM_REJECTED
} from '../actionTypes'

export const fetchRejectedStories = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_REJECTED_STORIES_REQUEST })
        firebase
            .firestore()
            .collection(PATH_REJECTED_STORIES)
            .get()
            .then((collections) => {
                dispatch({ type: FETCH_REJECTED_STORIES_SUCCESS, payload: collections.docs.map((doc) => doc.data()) })
            })
            .catch(error => {
                dispatch({ type: FETCH_REJECTED_STORIES_FAILURE, payload: error })
            })
    }
}

export const addRejectedStory = story => {
    return {
        type: ADD_REJECTED_STORY,
        payload: story
    }
}

export const updateImageForRejectedStory = data => {
    return {
        type: UPDATE_IMAGE_FOR_REJECTED_STORY,
        payload: data
    }
}

export const addMultipleRejectedStories = stories => {
    return {
        type: ADD_MULTIPLE_REJECTED_STORIES,
        payload: stories
    }
}

export const removeStoryFromRejected = story => {
    return {
        type: REMOVE_SINGLE_STORY_FROM_REJECTED,
        payload: story
    }
}

export const removeMultipleStoriesFromRejected = storyIds => {
    return{
        type: REMOVE_MULTIPLE_STORY_FROM_REJECTED,
        payload: storyIds
    }
}