import axios from 'axios'
import { BASE_URL } from '../../constants/constant'
import {
    FETCH_SEARCH_REQUEST,
    FETCH_SEARCHBYTAG_SUCCESS,
    FETCH_SEARCH_FAILURE,
    FETCH_SEARCHBYTAGLIST_SUCCESS,
    FETCH_SEARCHBYTITLE_SUCCESS,
    FETCH_SEARCHBYTITLEANDTAGLIST_SUCCESS,
    UPDATE_SEARCH_UPVOTE_COUNT,
    UPDATE_SEARCH_DOWNVOTE_COUNT
} from '../actionTypes'

export const fetchStoriesByTag = (tag) => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    return (dispatch) => {
        dispatch({ type: FETCH_SEARCH_REQUEST })
        axios.get(`${BASE_URL}searchStories/byTag/${tag}`, config)
            .then(response => {
                dispatch({ type: FETCH_SEARCHBYTAG_SUCCESS, payload: response.data.Stories })
            })
            .catch(error => {
                dispatch({ type: FETCH_SEARCH_FAILURE, payload: error })
            })
    }
}

export const fetchStoriesByTagList = (data) => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    return (dispatch) => {
        dispatch({ type: FETCH_SEARCH_REQUEST })
        axios.post(`${BASE_URL}searchStories/byTagList`, data, config)
            .then(response => {
                dispatch({ type: FETCH_SEARCHBYTAGLIST_SUCCESS, payload: response.data.Stories })
            })
            .catch(error => {
                dispatch({ type: FETCH_SEARCH_FAILURE, payload: error })
            })
    }
}

export const fetchStoriesByTitle = (title) => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    return (dispatch) => {
        dispatch({ type: FETCH_SEARCH_REQUEST })
        axios.get(`${BASE_URL}searchStories/byTitle/${title}`, config)
            .then(response => {
                dispatch({ type: FETCH_SEARCHBYTITLE_SUCCESS, payload: response.data.Stories })
            })
            .catch(error => {
                dispatch({ type: FETCH_SEARCH_FAILURE, payload: error })
            })
    }
}

export const fetchStoriesByTitleAndTagList = (data) => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    return (dispatch) => {
        dispatch({ type: FETCH_SEARCH_REQUEST })
        axios.post(`${BASE_URL}searchStories/byTitleAndTagList`, data, config)
            .then(response => {
                dispatch({ type: FETCH_SEARCHBYTITLEANDTAGLIST_SUCCESS, payload: response.data.Stories })
            })
            .catch(error => {
                dispatch({ type: FETCH_SEARCH_FAILURE, payload: error })
            })
    }
}

export const updateSearchUpvoteCount = (storyId) => {
    return { type: UPDATE_SEARCH_UPVOTE_COUNT, payload: storyId }
}

export const updateSearchDownvoteCount = (storyId) => {
    return { type: UPDATE_SEARCH_DOWNVOTE_COUNT, payload: storyId }
}