import {
    withStyles,
    Chip
} from '@material-ui/core';

const StyledChip = withStyles({
    root: {
        borderRadius: '5px',
    }
})((props) => <Chip {...props} />);

export default StyledChip