import firebase from '../../config/firebaseConfig'
import { PATH_PUBLISHED_STORIES } from '../../constants/constant'
import {
    FETCH_APPROVED_STORIES_REQUEST,
    FETCH_APPROVED_STORIES_SUCCESS,
    FETCH_APPROVED_STORIES_FAILURE,
    ADD_APPROVED_STORY,
    UPDATE_IMAGE_FOR_APPROVED_STORY,
    ADD_MULTIPLE_APPROVED_STORIES,
    REMOVE_SINGLE_STORY_FROM_APPROVED,
    REMOVE_MULTIPLE_STORY_FROM_APPROVED
} from '../actionTypes'

export const fetchApprovedStories = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_APPROVED_STORIES_REQUEST })
        firebase
            .firestore()
            .collection(PATH_PUBLISHED_STORIES)
            .orderBy('publishDate', 'desc')
            .get()
            .then((collections) => {
                dispatch({ type: FETCH_APPROVED_STORIES_SUCCESS, payload: collections.docs.map((doc) => doc.data()) })
            })
            .catch(error => {
                dispatch({ type: FETCH_APPROVED_STORIES_FAILURE, payload: error })
            })
    }
}

export const addApprovedStory = story => {
    return {
        type: ADD_APPROVED_STORY,
        payload: story
    }
}

export const updateImageForApprovedStory = data => {
    return {
        type: UPDATE_IMAGE_FOR_APPROVED_STORY,
        payload: data
    }
}

export const addMultipleApprovedStories = stories => {
    return {
        type: ADD_MULTIPLE_APPROVED_STORIES,
        payload: stories
    }
}

export const removeStoryFromApproved = story => {
    return {
        type: REMOVE_SINGLE_STORY_FROM_APPROVED,
        payload: story
    }
}

export const removeMultipleStoriesFromApproved = storyIds => {
    return{
        type: REMOVE_MULTIPLE_STORY_FROM_APPROVED,
        payload: storyIds
    }
}