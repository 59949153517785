import {
    FETCH_PROFILE_STORIES_REQUEST,
    FETCH_PROFILE_STORIES_SUCCESS,
    FETCH_PROFILE_STORIES_FAILURE
} from '../actionTypes'

const initialState = {
    loading: false,
    profileStoriesState: [],
    error: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROFILE_STORIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_PROFILE_STORIES_SUCCESS:
            return {
                loading: false,
                profileStoriesState: action.payload,
                error: null
            }
        case FETCH_PROFILE_STORIES_FAILURE:
            return {
                loading: false,
                profileStoriesState: [],
                error: action.payload
            }
        default: return state
    }
}

export default reducer
