import React, { useState } from "react";
import {
    makeStyles,
    Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    storyBody: {
        textAlign: 'left', fontSize: '1rem'
    },
    readHide: {
        cursor: 'pointer',
        color: '#21ABE1'
    }
}));

const ReadMoreOrLess = ({ children }) => {
    const classes = useStyles();

    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <Typography color='textPrimary' className={classes.storyBody}>
            {isReadMore ? text.slice(0, 150) : text}
            <span onClick={toggleReadMore} className={classes.readHide}>
                {isReadMore ? " ...read more" : " show less"}
            </span>
        </Typography>
    );
};

export default ReadMoreOrLess;