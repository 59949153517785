import { combineReducers } from 'redux'
import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"
import AuthReducer from './auth/authReducer'
import HandPickedReducer from './dashboard/handPicked/handPickedReducer'
import MoreStoriesReducer from './dashboard/moreStories/moreStoriesReducer'
import SearchStoriesReducer from './search/searchReducer'
import BookMarkedStoriesReducer from './bookmarked/bookmarkedReducer'
import ProfileStoriesReducer from './profile/profileReducer'
import AdminAuthReducer from './adminAuth/adminAuthReducer'
import PendingStoriesReducer from './pendingStories/pendingStoriesReducer'
import ApprovedStoriesReducer from './approvedStories/approvedStoriesReducer'
import RejectedStoriesReducer from './rejectedStories/rejectedStoriesReducer'
import RegisteredUsersReducer from './registeredUser/registeredUserReducer'

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "auth", "adminAuth", "handPicked", "moreStories", "searchStories", "bookmarkedStories", "pendingStories", "approvedStories",
    "rejectedStories", "registeredUsers"
  ],
  blacklist: [],
};


const rootReducer = combineReducers({
  auth: AuthReducer,
  handPicked: HandPickedReducer,
  moreStories: MoreStoriesReducer,
  searchStories: SearchStoriesReducer,
  bookmarkedStories: BookMarkedStoriesReducer,
  profileStories: ProfileStoriesReducer,
  adminAuth: AdminAuthReducer,
  pendingStories: PendingStoriesReducer,
  approvedStories: ApprovedStoriesReducer,
  rejectedStories: RejectedStoriesReducer,
  registeredUsers: RegisteredUsersReducer
})

export default persistReducer(persistConfig, rootReducer)