import axios from 'axios'
import { BASE_URL } from '../constants/constant'

export function readStory(userId, storyId) {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    };

    axios.put(`${BASE_URL}user/updateReadList/${userId}/${storyId}`, {}, config)
}