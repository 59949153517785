import axios from 'axios'
import { BASE_URL } from '../../../constants/constant'
import {
    FETCH_MORE_STORIES_REQUEST,
    FETCH_MORE_STORIES_SUCCESS,
    FETCH_MORE_STORIES_FAILURE,
    UPDATE_MORE_STORIES_SUCCESS,
    UPDATE_MORESTORIES_DOWNVOTE_COUNT,
    UPDATE_MORESTORIES_UPVOTE_COUNT
} from '../../actionTypes'

export const fetchFirstBatchStories = (data) => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    return (dispatch) => {
        dispatch({ type: FETCH_MORE_STORIES_REQUEST })
        axios.post(`${BASE_URL}liveStories/get?limit=${data.limit}&skip=${data.skip}`, data, config)
            .then(response => {
                dispatch({ type: FETCH_MORE_STORIES_SUCCESS, payload: response.data.Stories })
            })
            .catch(error => {
                dispatch({ type: FETCH_MORE_STORIES_FAILURE, payload: error })
            })
    }
}

export const fetchNextBatchStories = (data) => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    return (dispatch) => {
        dispatch({ type: FETCH_MORE_STORIES_REQUEST })
        axios.post(`${BASE_URL}liveStories/get?limit=${data.limit}&skip=${data.skip}`, data, config)
            .then(response => {
                dispatch({ type: UPDATE_MORE_STORIES_SUCCESS, payload: response.data.Stories })
            })
            .catch(error => {
                dispatch({ type: FETCH_MORE_STORIES_FAILURE, payload: error })
            })
    }
}

export const updateMoreStoriesUpvoteCount = (storyId) => {
    return { type: UPDATE_MORESTORIES_UPVOTE_COUNT, payload: storyId }
}

export const updateMoreStoriesDownvoteCount = (storyId) => {
    return { type: UPDATE_MORESTORIES_DOWNVOTE_COUNT, payload: storyId }
}