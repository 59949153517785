import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
    '@global': {
        '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
        },
        html: {
            fontFamily: 'Roboto',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            height: '100%',
            width: '100%'
        },
        body: {
            backgroundColor: '#FFFFFF',
            textAlign: 'center',
            height: '100%',
            width: '100%',
            padding: '0 10% 0 10%'
        },
        a: {
            textDecoration: 'none'
        },
        '#root': {
            height: '100%',
            width: '100%'
        },
        '*::-webkit-scrollbar': {
            width: '10px'
        },
        // Track
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            '-webkit-border-radius': '10px',
            borderRadius: '10px'
        },
        // Handle
        '*::-webkit-scrollbar-thumb': {
            '-webkit-border-radius': '10px',
            borderRadius: '10px',
            background: 'rgba(189, 189, 189, 1)',
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.5)'
        },
        '*::-webkit-scrollbar-thumb:window-inactive': {
            background: 'rgba(189, 189, 189, 1)'
        }
    }
}));

const GlobalStyles = () => {
    useStyles();

    return null;
};

export default GlobalStyles;
