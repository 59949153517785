import React from 'react';
import {
    Box,
    makeStyles,
    Popover
} from '@material-ui/core';
import { ReactComponent as FacebookLogo } from '../assets/FacebookLogo.svg'
import { ReactComponent as WhatsAppLogo } from '../assets/whatsappLogo.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center'
    }
}));

const PopOverShareLinks = ({ open, onClose, anchorEl, storyId, storyTitle, storyBody }) => {
    const classes = useStyles();
    const Url = `https://stories.yourhour.app/directRead/${storyId}`
    const siteUrl = `https://stories.yourhour.app/`

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Box className={classes.root}>
                <a
                    href={`https://wa.me/?text=%2A${encodeURIComponent(storyTitle)}%2A%0A%0A${encodeURIComponent(storyBody)}%0A%0A${encodeURIComponent('To Read More Such Stories & Experiences About Screen Time - Checkout Out YourStories')}:%20${siteUrl}`}
                    style={{ margin: '0 8px 0 0' }}
                >
                    <WhatsAppLogo width='25px' height='25px' />
                </a>
                <a href={`https://www.facebook.com/sharer.php?u=${Url}`}>
                    <FacebookLogo width='25px' height='25px' />
                </a>
            </Box>
        </Popover>
    )
}

export default PopOverShareLinks;