import axios from 'axios'
import { BASE_URL } from '../../constants/constant'
import {
    FETCH_PROFILE_STORIES_REQUEST,
    FETCH_PROFILE_STORIES_SUCCESS,
    FETCH_PROFILE_STORIES_FAILURE
} from '../actionTypes'

export const fetchProfileStories = (userEmail) => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    return (dispatch) => {
        dispatch({ type: FETCH_PROFILE_STORIES_REQUEST })
        axios.get(`${BASE_URL}profileStories/get/${userEmail}`, config)
            .then(response => {
                dispatch({ type: FETCH_PROFILE_STORIES_SUCCESS, payload: response.data.Stories })
            })
            .catch(error => {
                dispatch({ type: FETCH_PROFILE_STORIES_FAILURE, payload: error })
            })
    }
}