import React from 'react';
import {
    withStyles, makeStyles, Box, Dialog, DialogContent, TextField, Typography, IconButton, Button, DialogActions,
    Avatar
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { approveSingleStory } from '../utils/approveStories';
import { rejectSingleStory } from '../utils/rejectStories';
import { toast } from 'react-toastify';

const styles = (theme) => ({
    root: {
        margin: 0, padding: theme.spacing(3),
    },
    closeButton: {
        position: 'absolute', right: theme.spacing(1), top: theme.spacing(1), color: theme.palette.grey[500]
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { classes, onClose, userProfile, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Box>
                <Box style={{ display: 'flex', alignItems: 'center', marginRight: '10%' }}>
                    <Avatar alt={userProfile.userName} src={userProfile.profilePicRef} style={{ marginRight: '10px' }} />
                    <Typography >{userProfile.userName}</Typography>
                </Box>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </Box>
        </MuiDialogTitle>
    );
});

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left'
    },
    textField: {
        marginBottom: '15px'
    },
    approveButton: {
        color: 'white', background: '#4CAF50', boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14)', borderRadius: '5px',
        fontSize: '14px', lineHeight: '24px', "&:hover": { background: '#66BB6A' }
    },
    rejectButton: {
        color: 'white', background: '#E53935', boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14)', borderRadius: '5px',
        fontSize: '14px', lineHeight: '24px', "&:hover": { background: '#EF5350' }
    }
}));

const EditStoryDialog = ({ open, onClose, storyToEdit, removeSingleStory }) => {
    const classes = useStyles();

    const [storyTitle, setStoryTitle] = React.useState()
    const [storyContent, setStoryContent] = React.useState()

    React.useEffect(() => {
        setStoryTitle(storyToEdit.storyTitle)
        setStoryContent(storyToEdit.storyContent)
    }, [storyToEdit])

    const handleApproveStory = () => {
        if (storyToEdit.coverPicRef !== "") {
            storyToEdit.storyTitle = storyTitle
            storyToEdit.storyContent = storyContent
            approveSingleStory(storyToEdit)
            removeSingleStory(storyToEdit, 'Approved')
            onClose()
        } else {
            toast.warn('Please choose a Image for the Story.')
        }
    }

    const handleRejectStory = () => {
        storyToEdit.storyTitle = storyTitle
        storyToEdit.storyContent = storyContent
        rejectSingleStory(storyToEdit)
        removeSingleStory(storyToEdit, 'Rejected')
        onClose()
    }

    return (
        <React.Fragment>
            <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={onClose} className={classes.root}>
                <DialogTitle onClose={onClose} userProfile={storyToEdit} />
                <DialogContent>
                    <TextField
                        className={classes.textField}
                        label="Story Title"
                        variant="outlined"
                        fullWidth
                        value={storyTitle}
                        onChange={(e) => setStoryTitle(e.target.value)}
                    />
                    <TextField
                        className={classes.textField}
                        label="Story Content"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={10}
                        value={storyContent}
                        onChange={(e) => setStoryContent(e.target.value)}
                    />
                </DialogContent>

                <DialogActions style={{ display: 'block', textAlign: 'center' }}>
                    <Button
                        className={classes.approveButton}
                        onClick={handleApproveStory}
                    >
                        Approve
                    </Button>
                    <Button
                        className={classes.rejectButton}
                        onClick={handleRejectStory}
                    >
                        Reject
                    </Button>
                </DialogActions>
                <br />
            </Dialog>
        </React.Fragment>
    );
}

export default EditStoryDialog