import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux'
import { Box, Avatar, Button, TextField, Typography, Container, makeStyles, CssBaseline } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { adminSignIn } from '../../redux/adminAuth/adminAuthAction'
import Loader from '../../components/Loader'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8), display: 'flex', flexDirection: 'column', alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1), backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const AdminSignIn = ({ adminSignIn, adminAuth }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleSubmit = () => {
        if (email && password !== '') adminSignIn(email, password, navigate)
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box className={classes.paper}>

                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>

                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>

                <form className={classes.form} noValidate>

                    <TextField variant="outlined" margin="normal" required fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <TextField variant="outlined" margin="normal" required fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <Button fullWidth variant="contained" color="primary"
                        className={classes.submit} onClick={handleSubmit}
                    >
                        Sign In
                    </Button>
                </form>
            </Box>
            {adminAuth.loading ? (<Loader />) : null}
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        adminAuth: state.adminAuth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        adminSignIn: (email, password, navigate) => dispatch(adminSignIn(email, password, navigate))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSignIn)