import {
    FETCH_PENDING_STORIES_REQUEST,
    FETCH_PENDING_STORIES_SUCCESS,
    FETCH_PENDING_STORIES_FAILURE,
    STORY_REMOVED_FROM_PENDING,
    UPDATE_IMAGE_FOR_PENDING_STORY,
    REMOVE_MULTIPLE_STORIES_FROM_PENDING
} from '../actionTypes'

const initialState = {
    loading: false,
    pendingStoriesState: [],
    error: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PENDING_STORIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_PENDING_STORIES_SUCCESS:
            return {
                loading: false,
                pendingStoriesState: action.payload,
                error: null
            }
        case FETCH_PENDING_STORIES_FAILURE:
            return {
                loading: false,
                pendingStoriesState: [],
                error: action.payload
            }
        case STORY_REMOVED_FROM_PENDING:
            let pendingStories = state.pendingStoriesState.filter(x => x.id !== action.payload.id)
            return {
                ...state,
                pendingStoriesState: pendingStories
            }
        case UPDATE_IMAGE_FOR_PENDING_STORY:
            let updatedList =
                state.pendingStoriesState.map(x => (x.id === action.payload.storyId) ? {
                    ...x,
                    coverPicRef: action.payload.coverPicRef,
                    coverPicOwnerName: action.payload.coverPicOwnerName,
                    coverPicOwnerLink: action.payload.coverPicOwnerLink
                } : x)
            return {
                ...state,
                pendingStoriesState: updatedList
            }
        case REMOVE_MULTIPLE_STORIES_FROM_PENDING:
            let newPendingStories = state.pendingStoriesState.filter(({id}) => !action.payload.includes(id))
            return {
                ...state,
                pendingStoriesState: newPendingStories
            }
        default: return state
    }
}

export default reducer