import {
    withStyles,
    Avatar
} from '@material-ui/core';

const StyledAvatar = withStyles({
    root: {
        backgroundColor: '#666666'
    }
})((props) => <Avatar variant='rounded' {...props} style={{ color: '#fff' }}/>);

export default StyledAvatar