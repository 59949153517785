import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#21ABE1' //YourHour Blue
        },
        text: {
            primary: '#4F4F4F', //StoryDetails Grey 2
            secondary: '#828282', //OtherDetails Grey 3
        }
    }
});

export default theme;
