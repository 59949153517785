import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux'
import {
    makeStyles,
    Container,
    Grid,
    Divider,
    Typography,
    Box
} from '@material-ui/core';
import Page from '../../components/Page';
import StoryTitle from '../../components/StoryTitle';
import StyledChip from '../../components/StyledChip'
import StyledAvatar from '../../components/StyledAvatar'
import { ReactComponent as Star } from '../../assets/Star.svg'
import { ReactComponent as ListIcon } from '../../assets/ListIcon.svg'
import CloseIcon from '@material-ui/icons/Close';
import { TAGS_WITH_COLOR } from '../../constants/constant'
import { fetchHandPickedStories } from '../../redux/dashboard/handPicked/handPickedAction'
import { fetchFirstBatchStories, fetchNextBatchStories } from '../../redux/dashboard/moreStories/moreStoriesAction'
import Loader from '../../components/Loader'
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        gridTemplateColumns: "max-content max-content",
        gridAutoFlow: "row dense",
        ['@media (max-width:600px)']: {
            paddingBottom: theme.spacing(5),
            paddingTop: theme.spacing(5)
        }
    },
    box: {
        display: 'flex', alignItems: 'center'
    },
    sectionTitle: {
        fontWeight: 'bold', fontSize: '0.7rem'
    }
}));

const Dashboard = ({ user, fetchHandPickedStories, handPicked, fetchFirstBatchStories, moreStories, fetchNextBatchStories }) => {
    const classes = useStyles();

    useEffect(() => {
        localStorage.setItem('from', 'dashboard')
        var isback = localStorage.getItem('isBackPress');
        if (isback == "false") {
            fetchHandPickedStories()
            let data = { limit: 31, skip: 0 }
            if (user && user !== undefined) {
                data.ids = user.readStories
                fetchFirstBatchStories(data)
            } else {
                fetchFirstBatchStories(data)
            }
        }
        window.scrollTo(0, localStorage.getItem('yaxis'))
        return () => {
            localStorage.setItem('isBackPress', true)
        }
    }, [])

    console.log(user)

    // Handle YAxis position of scroll bar and saving it to localstorage
    const handleScroll = () => {
        const position = window.pageYOffset;
        if (position) localStorage.setItem('yaxis', position)
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Page
            className={classes.root}
            title="YourStories - Where Stories Find You!"
        >
            <Container fixed style={{}}>
                <Grid container spacing={4}>
                    {/* Hand Picked Section */}
                    <Grid
                        container
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        spacing={2}
                    >
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Box className={classes.box}>
                                <Star style={{ marginRight: '10px' }} />
                                <Typography className={classes.sectionTitle} style={{ color: '#F2C94C' }}>HAND PICKED</Typography>
                            </Box>
                        </Grid>
                        {handPicked.handPickedState && handPicked.handPickedState.map(data => {
                            return (
                                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                    <RouterLink to={`/read/${data._id}`}>
                                        <StoryTitle storyData={data} />
                                    </RouterLink>
                                </Grid>
                            )
                        })}
                    </Grid>

                    <Divider orientation='horizontal' style={{ width: '100%', marginBottom: '2%' }} />

                    <Grid
                        container
                        direction="row"
                        item
                        xl={8}
                        lg={8}
                        md={8}
                        sm={8}
                        xs={12}
                        spacing={2}
                        style={{ height: 'fit-content' }}
                    >
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '2%' }}>
                            <Box className={classes.box}>
                                <ListIcon style={{ marginRight: '10px' }} />
                                <Typography
                                    className={classes.sectionTitle}
                                    style={{ color: '#6FCF97' }}
                                >
                                    MORE STORIES
                                </Typography>
                            </Box>
                        </Grid>
                        {moreStories.moreStoriesState && moreStories.moreStoriesState.slice(0, 4).map(data => {
                            return (
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <RouterLink to={`/read/${data._id}`}>
                                        <StoryTitle storyData={data} />
                                    </RouterLink>
                                </Grid>
                            )
                        })}
                    </Grid>


                    <Divider orientation="vertical" flexItem style={{ marginBottom: '2%' }} />


                    {/* Filters Section */}
                    <Grid
                        container
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={12}
                        spacing={2}
                        style={{ height: 'fit-content' }}
                    >
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Box className={classes.box} style={{ justifyContent: 'space-between' }}>
                                <Typography color='textPrimary' style={{ fontWeight: 'bold', fontSize: '1rem' }}>Filters</Typography>
                                {/* <StyledChip
                                    avatar={<StyledAvatar>4</StyledAvatar>}
                                    label='Clear all Filters'
                                    variant='outlined'
                                    deleteIcon={<CloseIcon />}
                                    onDelete
                                /> */}
                            </Box>
                        </Grid>

                        {/* READ BY TAGS Section */}
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ margin: '15px 0 5px 0' }}>
                            <Typography
                                color='textPrimary'
                                className={classes.sectionTitle}
                                style={{ textAlign: 'left' }}
                            >
                                READ BY TAGS
                            </Typography>
                        </Grid>
                        <Grid item direction="row" xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'left' }}>
                            {TAGS_WITH_COLOR.map(data => {
                                return (
                                    <RouterLink to={`/searchBy/${data.tag}`}>
                                        <StyledChip
                                            label={data.tag}
                                            variant='outlined'
                                            style={{
                                                margin: '0 10px 15px 0',
                                                // borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                                                borderColor: data.color,
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </RouterLink>
                                )
                            })}
                        </Grid>
                    </Grid>


                    {/* More Stories Section */}
                    <Grid
                        container
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        spacing={4}
                    >
                        <InfiniteScroll
                            dataLength={moreStories.moreStoriesState}
                            next={() => {
                                let data = { limit: 18, skip: moreStories.moreStoriesState.length }
                                if (user && user !== undefined) {
                                    data.ids = user.readStories
                                    fetchNextBatchStories(data)
                                } else {
                                    fetchNextBatchStories(data)
                                }
                            }}
                            hasMore={true}
                            style={{ overflow: 'hidden' }}
                        >
                            <Grid
                                container
                                item
                                direction='row'
                                spacing={2}
                            >
                                {moreStories.moreStoriesState && moreStories.moreStoriesState.slice(4).map(data => {
                                    return (
                                        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                            <RouterLink to={`/read/${data._id}`}>
                                                <StoryTitle storyData={data} />
                                            </RouterLink>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </InfiniteScroll>
                    </Grid>
                </Grid>
            </Container>
            {handPicked.loading ? (<Loader />) : null}
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.authState.user,
        handPicked: state.handPicked,
        moreStories: state.moreStories
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchHandPickedStories: () => dispatch(fetchHandPickedStories()),
        fetchFirstBatchStories: (data) => dispatch(fetchFirstBatchStories(data)),
        fetchNextBatchStories: (data) => dispatch(fetchNextBatchStories(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
