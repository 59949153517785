import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    makeStyles, Typography
} from '@material-ui/core';
import Page from '../../components/Page';
import { ReactComponent as ErrorImage } from '../../assets/NotFoundImage.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        ['@media (max-width:600px)']: {
            paddingBottom: theme.spacing(5),
            paddingTop: theme.spacing(5)
        }
    },
    text: {
        color: '#333333',
        fontFamily: 'Lobster',
        fontSize: '1.8rem',
        margin: '2% 0 0 0'
    },
    error: {
        fontSize: '2.5rem',
        fontWeight: 'bold'
    }
}));

const NotFoundView = () => {
    const classes = useStyles();

    return (
        <Page
            className={classes.root}
            title="404"
        >
            <Typography className={classes.text}>
                ohh!! We landed on an
            </Typography>
            <Typography color='primary' className={classes.error}>ERROR</Typography>
            <ErrorImage width="100%" style={{ margin: '2% 0 3% 0' }} />
            <RouterLink to='/' >
                <Typography color='primary' style={{ textDecoration: 'underline' }} >
                    Continue Reading
                </Typography>
            </RouterLink>
        </Page>
    );
};

export default NotFoundView;
