import {
    FETCH_REJECTED_STORIES_REQUEST,
    FETCH_REJECTED_STORIES_SUCCESS,
    FETCH_REJECTED_STORIES_FAILURE,
    ADD_REJECTED_STORY,
    UPDATE_IMAGE_FOR_REJECTED_STORY,
    ADD_MULTIPLE_REJECTED_STORIES,
    REMOVE_SINGLE_STORY_FROM_REJECTED,
    REMOVE_MULTIPLE_STORY_FROM_REJECTED
} from '../actionTypes'

const initialState = {
    loading: false,
    rejectedStoriesState: [],
    error: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REJECTED_STORIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_REJECTED_STORIES_SUCCESS:
            return {
                loading: false,
                rejectedStoriesState: action.payload,
                error: null
            }
        case FETCH_REJECTED_STORIES_FAILURE:
            return {
                loading: false,
                rejectedStoriesState: [],
                error: action.payload
            }
        case ADD_REJECTED_STORY:
            let rejectedStoriesList = []
            rejectedStoriesList.push(action.payload)
            return {
                loading: false,
                rejectedStoriesState: [...state.rejectedStoriesState, ...rejectedStoriesList],
                error: null
            }
        case UPDATE_IMAGE_FOR_REJECTED_STORY:
            let updatedList =
                state.rejectedStoriesState.map(x => (x.id === action.payload.storyId) ? {
                    ...x,
                    coverPicRef: action.payload.coverPicRef,
                    coverPicOwnerName: action.payload.coverPicOwnerName,
                    coverPicOwnerLink: action.payload.coverPicOwnerLink
                } : x)
            return {
                ...state,
                rejectedStoriesState: updatedList
            }
        case ADD_MULTIPLE_REJECTED_STORIES:
            return {
                loading: false,
                rejectedStoriesState: [...state.rejectedStoriesState, ...action.payload],
                error: null
            }
        case REMOVE_SINGLE_STORY_FROM_REJECTED:
            let removedRejectedStoryList = state.rejectedStoriesState.filter(x => x.id !== action.payload.id)
            return {
                ...state,
                rejectedStoriesState: removedRejectedStoryList
            }
        case REMOVE_MULTIPLE_STORY_FROM_REJECTED:
            let newRejectedStoriesList = state.rejectedStoriesState.filter(({ id }) => !action.payload.includes(id))
            return {
                ...state,
                rejectedStoriesState: newRejectedStoriesList
            }
        default: return state
    }
}

export default reducer